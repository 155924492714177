import React, { useState } from "react";
import "./Signup.css";
import "../../Components.css";
import UserSignUp from "./Subcomponents/UserSignUp";
import { NavLink, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import HelmetLayout from "../../Utils/HelmetLayout/Helmet";
import RealtorSignUp from "./Subcomponents/RealtorSignUp";

export default function Signup() {
  const jwtCookie = document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith("jwt_authorization="));
  const isLogedIn = localStorage.getItem("anonymous");
  const isUserLoggedIn = jwtCookie && isLogedIn === "1";

  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate("/");
    }
  }, [isUserLoggedIn, navigate]);

  const [userType, setUserType] = useState("Buyer");

  const handleRoleStatus = (role) => {
    setUserType(role);
  };

  return (
    <div>
      <HelmetLayout pageName={"signup"} canonicalLink={"Signup"} />
      <div className="row mx-0 py-3">
        <div className="col-lg-6 col-12">
          <div className="container centered signup_topPadding  aligning">
            <div className="imagecentered">
              <NavLink to="/">
                <img
                  className="imgclass1"
                  src={require("../../images/New__LOGO.png")}
                  alt="AssignmentSold logo"
                  loading="lazy"
                />
              </NavLink>
            </div>
            <p className="font contactheading cacc1">
              Register to Assignment<span className="textgreen">Sold</span>{" "}
            </p>
            <p className="font contactheading cacc ">Create Account</p>

            <div className="userRole-BtnContainer">
              {" "}
              <button
                className={`userRole_button ${
                  userType === "Buyer" ? " activeRole_button" : ""
                }`}
                onClick={() => {
                  handleRoleStatus("Buyer");
                }}
              >
                Buyer
              </button>{" "}
              <button
                className={`userRole_button ${
                  userType === "Seller" ? " activeRole_button" : ""
                }`}
                onClick={() => {
                  handleRoleStatus("Seller");
                }}
              >
                Seller
              </button>{" "}
              <button
                className={`userRole_button ${
                  userType === "Realtor" ? " activeRole_button" : ""
                }`}
                onClick={() => {
                  handleRoleStatus("Realtor");
                }}
              >
                Realtor
              </button>
            </div>

            {userType !== "Realtor" && (
              <div>
                <UserSignUp userType={userType} />
              </div>
            )}

            {userType === "Realtor" && (
              <div>
                <RealtorSignUp userType={"Realtor"} />
              </div>
            )}
          </div>
        </div>

        <div className=" LoginImage SignUp_img col-lg-6 col-12 ">
          <img
            className="Contactimage"
            src={require("../../images/home1.jpeg")}
            alt="Login Cover"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
}
