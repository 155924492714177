import React, { useRef } from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";
import "./CategoryWiseAssignment.css";
import "../../App.css";
import baseURL from "../../config";
import { Divider, Drawer, IconButton, Skeleton } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CardMain from "../../HomePage/SubComponents/MapandListing/CardMain";
import CategoriesTab from "../../HomePage/SubComponents/CatgoriesTab/CategoriesTab";
import Marquees from "../../HomePage/SubComponents/Marquee/Marquees";
import Footer from "../Footer/Footer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import { BiFilter } from "react-icons/bi";
import { FaSort } from "react-icons/fa";
import ListIcon from "@mui/icons-material/List";
import MapIcon from "@mui/icons-material/Map";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel } from "@mui/material";
import ButtonList from "../../HomePage/SubComponents/DrawerButtonList/ButtonList";
import RangeSlider from "../../HomePage/SubComponents/RangeSlider/RangeSlider";
import SQFTRangeSlider from "../../HomePage/SubComponents/RangeSlider/SQFTRangeSlider";
import BedButtonList from "../../HomePage/SubComponents/DrawerButtonList/BedButtonList";
import ParkingButtons from "../../HomePage/SubComponents/HeaderSection/SubComponents/ParkingButtons";
import AccordionPanel from "../../HomePage/SubComponents/Accordion/AccordionPanel";
import BathroomButtons from "../../HomePage/SubComponents/HeaderSection/SubComponents/BathroomButtons";
import { Helmet } from "react-helmet";
import CardMainSkeleton from "../../HomePage/SubComponents/MapandListing/CardMainSkeleton";
import { useDispatch, useSelector } from "react-redux";
import Mapbox from "../../HomePage/SubComponents/GoogleMaps/MapBox";
import {
  addMapData,
  removeMapData,
} from "../../ReduxStore/Slices/MapBoxDataSlice";
import ResultNotFound from "../../HomePage/SubComponents/ResultNotFound/ResulutNotFound";
import Cookies from "universal-cookie";
import PropertyFaqs from "../PropertyDetails/SubComponents/PropertyFaqs";

const CategoryWiseAssignment = () => {
  const { id } = useParams();
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const mapRef = useRef(null);
  const cardRef = useRef(null);
  const adminData = useSelector((state) => state.adminSiteDataSlice);
  const userData = useSelector((state) => state.userProfileDataSlice);
  const webHighlight = adminData[0]?.highlight;
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cityFaqs, setCityFaqs] = useState("");
  const [metaSeoTags, setMetaSeoTags] = useState("");
  const [descriptionLoading, setDescriptionLoading] = useState(true);
  const [isListingView, setIsListingView] = useState(true);
  const [isMapView, setIsMapView] = useState(false);
  const [showMblMapBtn, setShowMblMapBtn] = useState(true);
  const [showMblListBtn, setShowMblListBtn] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [sortingOption, setSortingOption] = useState("NE");
  const [hasMoreContent, setHasMoreContent] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState("");
  const [showTotalRecordCount, setShowTotalRecordCount] = useState("");
  const [onlyRealtorListings, setOnlyRealtorListings] = useState(false);
  const [contentPage, setContentPage] = useState(1);
  const [resetTrigger, setResetTrigger] = useState(false);
  const [state, setState] = React.useState({
    top: false,
    bottom: false,
    right: false,
  });
  const [selectedStatusValues, setSelectedStatusValues] =
    React.useState("Available");
  const [activeButtons, setActiveButtons] = useState([]);
  const [selectedBathButtons, setSelectedBathButtons] = useState([]);
  const [activeBathButtons, setActiveBathButtons] = useState([]);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const [selectedBedValues, setSelectedBedValues] = React.useState([]);
  const [selectedParkingButtons, setSelectedParkingButtons] = useState([]);
  const [insidePolygon, setInsidePolygon] = useState("");
  const [rangeSliderValues, setRangeSliderValues] = useState({
    value1: 0,
    value2: 4600000,
  });
  const [rangeSQFTSliderValues, setSQFTRangeRadSliderValues] = useState({
    value1: 0,
    value2: 5000,
  });

  //Update the Mobile View State
  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //get the params and update the state
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const building = params.get("building");
    const bed = params.get("bed");
    const bath = params.get("bath");
    const parking = params.get("parking");
    const sort = params.get("sort");
    const status = params.get("status");
    const price = params.get("price");
    const sqft = params.get("sqft");

    if (building) {
      setSelectedValues(building.split(","));
    }
    if (bed) {
      setSelectedBedValues(bed.split(","));
    }
    if (bath) {
      setSelectedBathButtons(bath.split(","));
      setActiveBathButtons(bath.split(","));
    }
    if (parking) {
      setSelectedParkingButtons(parking.split(","));
      setActiveButtons(parking.split(","));
    }
    if (sort) {
      setSortingOption(sort);
    }
    if (status) {
      setSelectedStatusValues(status);
    }
    if (price) {
      const [value1, value2] = price.split("-");
      setRangeSliderValues({ value1: Number(value1), value2: Number(value2) });
    }
    if (sqft) {
      const [value1, value2] = sqft.split("-");
      setSQFTRangeRadSliderValues({
        value1: Number(value1),
        value2: Number(value2),
      });
    }
    // eslint-disable-next-line
  }, []);

  // For Fetching the data From the api
  const fetchData = async (page) => {
    const BuildingTypesParams =
      selectedValues?.length > 0
        ? selectedValues?.map((value) => ({ parameter: value }))
        : [];

    const BathParams =
      selectedBathButtons?.length > 0
        ? selectedBathButtons?.map((value) => ({ parameter: value }))
        : [];

    const ParkingParams =
      selectedParkingButtons?.length > 0
        ? selectedParkingButtons?.map((value) => ({ parameter: value }))
        : [];

    const BedParams = Array.from(selectedBedValues, (value) => {
      let parameter;

      if (value === "All") {
        parameter = "All";
      } else if (value === "Studio") {
        parameter = "0B";
      } else if (value === "1 Bed") {
        parameter = "1B";
      } else if (value === "1 Bed + Den") {
        parameter = "1B+D";
      } else if (value === "2 Bed") {
        parameter = "2B";
      } else if (value === "2 Bed + Den") {
        parameter = "2B+D";
      } else if (value === "3 Bed") {
        parameter = "3B";
      } else if (value === "3 Bed + Den") {
        parameter = "3B+D";
      } else if (value === "4 Bed") {
        parameter = "4B";
      } else if (value === "4 Bed + Den") {
        parameter = "4B+D";
      } else if (value === "5 Bed") {
        parameter = "5B";
      } else if (value === "5 Bed + Den") {
        parameter = "5B+D";
      } else if (value === "6 Bed+") {
        parameter = "6B+";
      }
      return { parameter };
    });

    const data = {
      MinPrice: rangeSliderValues.value1,
      MaxPrice:
        rangeSliderValues.value2 > 4500000
          ? 1000000000
          : rangeSliderValues.value2,
      MinSqft: rangeSQFTSliderValues.value1.toString(),
      MaxSqft:
        rangeSQFTSliderValues.value2 > 4500
          ? 1000000
          : rangeSQFTSliderValues.value2.toString(),
      Radius: 0,
      SortBy: sortingOption,
      Title: id,
      TitleType: "Location",
      Status: selectedStatusValues,
      Parking: "false",
      page: page,
      RestrictWithInCity: 1,
      Searchparameters_Types: BuildingTypesParams,
      Searchparameters_Beds: BedParams,
      Searchparameters_Baths: BathParams,
      Searchparameters_Parkings: ParkingParams,
      Searchparameters_Citys: [],
      Insidepolygon: insidePolygon,
      ShowTo: onlyRealtorListings ? "Realtor" : null,
    };
    const formData = new FormData();
    formData.append("FilterDataRequest", JSON.stringify(data));
    const headers = {
      Authorization: `Bearer ${jwt_token}`,
    };
    try {
      const response = await axios.post(
        `${baseURL}/api/Assignment/GetFilteredData`,
        formData,
        { headers }
      );
      if (response?.data?.result === 1) {
        setTotalPageCount(Math.ceil(response?.data?.data?.count / 20));
        setShowTotalRecordCount(response?.data?.data?.count);
        setIsLoading(false);
        setLoadMore(false);
        if (page === 1) {
          dispatch(removeMapData());
          dispatch(addMapData(response?.data?.data?.filteredGeolocationDTO));
          const newData = response?.data?.data?.assignments || [];
          setData(newData);
          setResetTrigger(false);
        } else {
          const newData = response?.data?.data?.assignments || [];
          setData((prevData) => [...prevData, ...newData]);
        }
      } else {
        console.error("There is problem in fetching data");
        setIsLoading(false);
        setLoadMore(false);
      }
    } catch (error) {
      console.error("There is problem in fetching data");
      setIsLoading(false);
      setLoadMore(false);
    }
  };

  useEffect(() => {
    if (!id) {
      navigate(`/`);
    }
    // eslint-disable-next-line
  }, []);

  const setUrlParams = () => {
    let query = "";

    if (selectedValues && selectedValues.length > 0) {
      query += `building=${selectedValues.join(",")}&`;
    }
    if (selectedBedValues && selectedBedValues.length > 0) {
      query += `bed=${selectedBedValues.join(",")}&`;
    }
    if (selectedBathButtons && selectedBathButtons.length > 0) {
      query += `bath=${selectedBathButtons.join(",")}&`;
    }
    if (selectedParkingButtons && selectedParkingButtons.length > 0) {
      query += `parking=${selectedParkingButtons.join(",")}&`;
    }

    if (sortingOption && sortingOption !== "NE") {
      query += `sort=${sortingOption}&`;
    }
    if (selectedStatusValues && selectedStatusValues !== "Available") {
      query += `status=${selectedStatusValues}&`;
    }

    if (rangeSliderValues.value1 > 0 || rangeSliderValues.value2 < 4600000) {
      const rangeString = `${rangeSliderValues.value1}-${rangeSliderValues.value2}`;
      query += `price=${rangeString}&`;
    }

    if (
      rangeSQFTSliderValues.value1 > 0 ||
      rangeSQFTSliderValues.value2 < 5000
    ) {
      const sqftRangeString = `${rangeSQFTSliderValues.value1}-${rangeSQFTSliderValues.value2}`;
      query += `sqft=${sqftRangeString}&`;
    }

    query = query.slice(0, -1);

    navigate(`?${query}`, { replace: true });
  };

  useEffect(() => {
    setUrlParams();
    setIsLoading(true);
    setData([]);
    setContentPage(1);
    setTotalPageCount("");
    fetchData(1);
    // eslint-disable-next-line
  }, [
    id,
    sortingOption,
    selectedParkingButtons,
    selectedBathButtons,
    selectedBedValues,
    selectedValues,
    selectedStatusValues,
    rangeSliderValues,
    rangeSQFTSliderValues,
    insidePolygon,
    onlyRealtorListings,
  ]);

  // When the map becomes visible, trigger a resize
  useEffect(() => {
    if (!isListingView) {
      mapRef.current?.resize();
    }
  }, [isListingView]);

  const HandleMoreContent = () => {
    setLoadMore(true);
    setContentPage((prevPage) => prevPage + 1);
    fetchData(contentPage + 1);
  };

  useEffect(() => {
    if (contentPage === totalPageCount || totalPageCount === 0) {
      setHasMoreContent(false);
    } else {
      setHasMoreContent(true);
    }
  }, [contentPage, totalPageCount]);

  const handleResetFilters = async () => {
    setSQFTRangeRadSliderValues({
      value1: 0,
      value2: 5000,
    });
    setRangeSliderValues({
      value1: 0,
      value2: 4600000,
    });
    setSelectedValues([]);
    setSelectedBedValues([]);
    setSelectedParkingButtons([]);
    setSelectedBathButtons([]);
    setActiveButtons([]);
    setActiveBathButtons([]);
    setSelectedStatusValues("Available");
    setInsidePolygon("");
    setContentPage(1);
    setResetTrigger(true);
    setOnlyRealtorListings(false);
  };

  //Get Cities filter Work
  const [citiesFilterData, setCitiesFilterData] = useState([]);

  const fetchCitiesFilterData = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/data/GetCity`);
      if (response?.data?.result === 1) {
        let dataArray = response?.data?.data;
        if (dataArray) {
          let AllCityObject = { cityName: "All" };
          let newDataArray = [AllCityObject, ...dataArray];
          setCitiesFilterData(newDataArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCitiesFilterData();
  }, []);

  //Get Cities Description Work

  const fetchCityDescription = async (id) => {
    try {
      const response = await axios.get(
        `${baseURL}/api/data/GetCityDetail?term=${id}`
      );
      if (response?.data?.result === 1) {
        setCityFaqs(response?.data?.data?.cityFaqs);
        setMetaSeoTags(response?.data?.data?.citySeo[0]?.metaSeos);
        setDescriptionLoading(false);
      } else {
        setDescriptionLoading(false);
      }
    } catch (error) {
      console.error(error);
      setDescriptionLoading(false);
    }
  };

  useEffect(() => {
    fetchCityDescription(id);
    setResetTrigger(true);
    // eslint-disable-next-line
  }, [id]);

  //Filter button drawer
  const toggleFilterDrawer = (anchor, open) => (event) => {
    if (
      event.type === "Keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleSelectedBathButtons = (buttons) => {
    setSelectedBathButtons(buttons);
  };

  const handleSelectedParkingButtons = (button) => {
    setSelectedParkingButtons(button);
  };

  const handleSelectionChange = (selectedValues) => {
    setSelectedValues(selectedValues);
  };
  const handleBedSelectionChange = (selectedBedValues) => {
    setSelectedBedValues(selectedBedValues);
  };

  const handleRangeSliderChange = (value1, value2) => {
    setRangeSliderValues({ value1, value2 });
  };

  const handleSQFTRangeSliderChange = (value1, value2) => {
    setSQFTRangeRadSliderValues({ value1, value2 });
  };

  const handleListClick = () => {
    setIsMapView(false);
    setIsListingView(true);
  };

  const handleMapClick = () => {
    setIsListingView(false);
    setIsMapView(true);
    if (isMobileView) {
      window.scrollTo(0, 0);
    }
  };

  //to show list button on mobile View
  useEffect(() => {
    const handleScroll = () => {
      // eslint-disable-next-line
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;
      const maxScrollHeight = 250;
      if (scrollPosition <= maxScrollHeight) {
        setShowMblListBtn(true);
      } else {
        setShowMblListBtn(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //To show map button according to cards
  const handleResize = () => {
    if (cardRef.current) {
      const cardHeight = cardRef.current.clientHeight;
      setShowMblMapBtn(window.innerHeight + window.scrollY - 400 < cardHeight);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const list = (anchor, selectedValues) => (
    <Box className={"drawerwidth"} role="presentation">
      <div className={" greencolor textwhite drawerdisp pt-3 pb-1 px-1  font"}>
        <h4 className="filtermargin">Filters</h4>
        <button onClick={toggleFilterDrawer(anchor, false)} className="btn">
          <CloseIcon className="closee" />
        </button>
      </div>
      <div className="container">
        <div className="prop_hide">
          <p className="m-0 m-v-Property__Heading">Find Property</p>
        </div>
        <Divider />
        <List className="m-0 p-0">
          <div className="p-2">
            <p className="font pt-1 m-1 ">
              <strong className="filter_heading ">Building Type:</strong>
            </p>
            <ButtonList
              selectedValues={selectedValues}
              handleSelectionChange={handleSelectionChange}
            />
          </div>
        </List>
        <List>
          <div className="px-3 pt-1 pb-2">
            <p className="font filter_para">
              <strong className="filter_heading">Price Range:</strong>
            </p>
            <RangeSlider
              value1={rangeSliderValues.value1}
              value2={rangeSliderValues.value2}
              onChange={handleRangeSliderChange}
            />
          </div>
          <Divider />
          <div className="px-3 py-2">
            <p className="filter_para font">
              <strong className="filter_heading">Size Sqft:</strong>
            </p>
            <SQFTRangeSlider
              value1={rangeSQFTSliderValues.value1}
              value2={rangeSQFTSliderValues.value2}
              onChange={handleSQFTRangeSliderChange}
            />
          </div>
          <Divider />
          <div className="bookingbuttons">
            <Divider />
            <p className="font filter_buttonPara pt-1  m-0">
              <strong className="filter_heading">Bedrooms </strong>
            </p>
            <div className="btndiv">
              <BedButtonList
                selectedBedValues={selectedBedValues}
                handleBedSelectionChange={handleBedSelectionChange}
              />
            </div>
          </div>
          <div className="bookingbuttons">
            <Divider />
            <p className="font filter_buttonPara pt-1 m-0">
              <strong className="filter_heading">Bathrooms</strong>
            </p>
            <div className="bathbtns">
              <BathroomButtons
                selectedBathButtons={handleSelectedBathButtons}
                activeBathButtons={activeBathButtons}
                setActiveBathButtons={setActiveBathButtons}
              />
            </div>
          </div>
          <div className="bookingbuttons">
            <Divider />
            <p className="font filter_buttonPara pt-1 m-0">
              <strong className="filter_heading">Parking</strong>
            </p>
            <div className="parkingbtns">
              <ParkingButtons
                selectedParkingButtons={handleSelectedParkingButtons}
                activeButtons={activeButtons}
                setActiveButtons={setActiveButtons}
              />
            </div>
          </div>
        </List>
        <Divider />
      </div>
      <div>
        <AccordionPanel
          selectedStatusValues={selectedStatusValues}
          setSelectedStatusValues={setSelectedStatusValues}
        />
      </div>
      {userData[0]?.roles[0] === "Realtor" && (
        <div className="mt-3 ps-4">
          <FormControlLabel
            control={
              <Checkbox
                style={{ padding: "0px !important" }}
                checked={onlyRealtorListings}
                onChange={() => setOnlyRealtorListings(!onlyRealtorListings)}
              />
            }
            label={<span className="font fw-bold">Only Realtor Lisitngs</span>}
          />
        </div>
      )}
      <div>
        <button
          className="btn font btngreencolor sizings"
          onClick={toggleFilterDrawer(anchor, false)}
        >
          View {showTotalRecordCount?.toLocaleString("en-Us")} Lisitngs
        </button>
        <button
          onClick={handleResetFilters}
          className="btn font btngreycolor sizings"
        >
          Reset
        </button>
      </div>
    </Box>
  );

  //meta seo work

  const [mainTitle, setMainTitle] = useState("AssignmentSold.ca");
  const [title, setTitle] = useState("AssignmentSold.ca");
  const [description, setDescription] = useState(
    "AssignmentSold.ca is aiming to build a realtor community for assignment transactions, i.e. condos, townhomes, detached  in Toronto, GTA and Ontario. Hundreds of very attractive deals and thousands of professional agents are in this community right now. We are looking to help more agents to achieve more sales."
  );
  const [keywords, setKeywords] = useState(
    "assignment sold, assignment sale, condo, townhouse"
  );

  useEffect(() => {
    if (data.length !== 0) {
      const mainTitleObject = metaSeoTags?.find(
        (metaSeo) => metaSeo?.seoTag?.typevalue === "main_Title"
      );
      setMainTitle(
        mainTitleObject ? mainTitleObject?.content : "AssignmentSold.ca"
      );
      const titleObject = metaSeoTags?.find(
        (metaSeo) => metaSeo?.seoTag?.typevalue === "title"
      );
      setTitle(titleObject ? titleObject?.content : "AssignmentSold.ca");
      const descriptionObject = metaSeoTags?.find(
        (metaSeo) => metaSeo?.seoTag?.typevalue === "description"
      );
      setDescription(
        descriptionObject
          ? descriptionObject?.content
          : "AssignmentSold.ca is aiming to build a realtor community for assignment transactions, i.e. condos, townhomes, detached  in Toronto, GTA and Ontario. Hundreds of very attractive deals and thousands of professional agents are in this community right now. We are looking to help more agents to achieve more sales."
      );
      const KeywordObject = metaSeoTags?.find(
        (metaSeo) => metaSeo?.seoTag?.typevalue === "keywords"
      );
      setKeywords(
        KeywordObject
          ? KeywordObject?.content
          : "assignment sold, assignment sale, condo, townhouse"
      );
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <div>
      <Helmet>
        <title>{mainTitle}</title>
        <meta name="title" content={title} />
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={
            "https://assignmentsold.ca/static/media/no-image.67f41c49aba3e3ff671c.png"
          }
        />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={
            "https://assignmentsold.ca/static/media/no-image.67f41c49aba3e3ff671c.png"
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:url" content={window.location.href} />
      </Helmet>

      {webHighlight && <Marquees webHighlight={webHighlight} />}

      <div className="container">
        <div className="text-center assignment-salesText">
          <h1>{`Assignments for sale in ${id}`} </h1>
        </div>

        {citiesFilterData && (
          <div className="my-3">
            <CategoriesTab
              citiesFilterData={citiesFilterData}
              loading={isLoading}
              loadMore={loadMore}
            />
          </div>
        )}

        <div className="d-flex justify-content-between align-items-center category_resultAndBtn">
          <p className="results cityCategory_results textgreen mb-0">
            {`Results ${data?.length}  of
                  ${showTotalRecordCount} `}
          </p>
          <div className="city_categoryyBtn_container">
            {!isMobileView && (
              <>
                <IconButton
                  className="p-0"
                  color="secondary"
                  aria-label="List"
                  onClick={handleListClick}
                >
                  <div
                    className={isListingView ? "hearingIcon" : "duphearingIcon"}
                  >
                    <ListIcon
                      className={
                        isListingView ? "hearingIcon1" : "duphearingIcon1"
                      }
                    />
                    <p
                      className={
                        isListingView ? "hearingIcon2" : "duphearingIcon2"
                      }
                    >
                      List
                    </p>
                  </div>
                </IconButton>
                <div className="">
                  <IconButton
                    className="p-0"
                    color="secondary"
                    aria-label="Map"
                    onClick={handleMapClick}
                  >
                    <div
                      className={isMapView ? "hearingIcon" : "duphearingIcon"}
                    >
                      <MapIcon
                        className={
                          isMapView ? "hearingIcon1" : "duphearingIcon1"
                        }
                      />
                      <p
                        className={
                          isMapView ? "hearingIcon2" : "duphearingIcon2"
                        }
                      >
                        Map
                      </p>
                    </div>
                  </IconButton>
                </div>
              </>
            )}
            <button
              className="cityCategory_Btn"
              type="button"
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <FaSort
                style={{
                  color: "#02549e",
                  width: "1rem",
                  height: "1rem",
                }}
              />{" "}
              Sort
            </button>

            <ul
              className="dropdown-menu menuwidth1 p-2"
              aria-labelledby="dropdownMenuButton1"
            >
              <li className="">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sortingOption === "PLH"}
                      onChange={() => setSortingOption("PLH")}
                    />
                  }
                  label={<span className="font">Price (Low to High)</span>}
                />
              </li>
              <li className="">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sortingOption === "PHL"}
                      onChange={() => setSortingOption("PHL")}
                    />
                  }
                  label={<span className="font">Price (High to Low)</span>}
                />
              </li>
              <li className="">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sortingOption === "SLH"}
                      onChange={() => setSortingOption("SLH")}
                    />
                  }
                  label={
                    <span className="font">Square Feet (Low to High)</span>
                  }
                />
              </li>
              <li className="">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sortingOption === "SHL"}
                      onChange={() => setSortingOption("SHL")}
                    />
                  }
                  label={
                    <span className="font">Square Feet (High to Low)</span>
                  }
                />
              </li>
              <li className="">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sortingOption === "CLF"}
                      onChange={() => setSortingOption("CLF")}
                    />
                  }
                  label={<span className="font">Closest First</span>}
                />
              </li>
              <li className="">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sortingOption === "NE"}
                      onChange={() => setSortingOption("NE")}
                    />
                  }
                  label={<span className="font">Newest First</span>}
                />
              </li>
            </ul>

            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Button
                  onClick={toggleFilterDrawer(anchor, true)}
                  variant="outlined"
                  className="cityCategory_Btn cityCategoryFilter_Btn"
                >
                  <BiFilter
                    style={{
                      color: "#fff",
                      width: "1.5rem",
                      height: "1.5rem",
                    }}
                  />{" "}
                  <span>Filters</span>
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleFilterDrawer(anchor, false)}
                >
                  {list(anchor, selectedValues)}
                </Drawer>
              </React.Fragment>
            ))}
          </div>
        </div>

        {isListingView && (
          <>
            {isLoading ? (
              <div className="d-flex flex-wrap justify-content-center ActiveUserListing_card mt-4 mb-4">
                {Array.from(new Array(8))?.map((_, index) => (
                  <CardMainSkeleton key={index} />
                ))}
              </div>
            ) : data?.length === 0 ? (
              <div className="" ref={cardRef}>
                <ResultNotFound />
              </div>
            ) : (
              <>
                <div
                  className="d-flex flex-wrap justify-content-center ActiveUserListing_card mt-4 mb-4"
                  ref={cardRef}
                >
                  {data?.map((item, index) => {
                    return <CardMain key={index} item={item} />;
                  })}
                </div>
                {hasMoreContent ? (
                  <div className="justifycenter aligncenter mb-4">
                    {loadMore ? (
                      <RotatingLines width={50} strokeColor="grey" />
                    ) : (
                      <button
                        className="btn btn-primary font"
                        onClick={HandleMoreContent}
                        disabled={loadMore}
                      >
                        Load more
                      </button>
                    )}
                  </div>
                ) : null}
              </>
            )}
            {isMobileView && showMblMapBtn && (
              <div className="list_btnscroll" onClick={handleMapClick}>
                <button className="d-flex">
                  <MapIcon style={{ color: "#fff" }} />
                  <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                    Map
                  </span>
                </button>
              </div>
            )}
          </>
        )}

        <div
          className={`${
            !isListingView ? "onlymap_show w-100 mt-4" : "d-none"
          } `}
        >
          <Mapbox
            centerPopUP={true}
            insidePolygon={insidePolygon}
            setInsidePolygon={setInsidePolygon}
            mapRef={mapRef}
            isLoading={isLoading}
            isMobileView={isMobileView}
            resetTrigger={resetTrigger}
            toggleIcon={true}
          />
          {isMobileView && showMblListBtn && (
            <div className="list_btnscroll" onClick={handleListClick}>
              <button className="d-flex">
                <MapIcon style={{ color: "#fff" }} />
                <span style={{ fontWeight: "600", paddingLeft: "5px" }}>
                  List
                </span>
              </button>
            </div>
          )}
        </div>

        {descriptionLoading ? (
          <>
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: "1.5rem" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: "0.6rem" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: "1.5rem" }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ fontSize: "0.6rem" }}
            />
          </>
        ) : (
          <>
            {cityFaqs && (
              <div className="mt-5">
                <PropertyFaqs data={cityFaqs} isShowHeading={true} />
              </div>
            )}
          </>
        )}
      </div>

      <div style={{ marginTop: "6rem" }}>
        <Footer />
      </div>

      <ToastContainer />
    </div>
  );
};

export default CategoryWiseAssignment;
