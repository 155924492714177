import React, { useState } from 'react';
import { useEffect } from 'react';
import './quotes.css'
import { useLocation, useNavigate } from 'react-router-dom';
import starterbadge from '../../images/StarterBadge.png'
import elitebadge from '../../images/ElitteBadge.svg'
import premiumbadge from '../../images/PremiumBadge.png'
import UserDetails from './UserDetails';
import Confirmation from './ConfirmPayment';
import ChooseYourPkg from './ChooseYourPkg';
import { Step, StepLabel, Stepper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { addUserProfileData } from '../../ReduxStore/Slices/UserProfileData';
import axios from 'axios';
import baseURL from '../../config';
import BillingAddress from './BillingAddress';



export default function GetQuotes() {
    const navigate = useNavigate()
    const location = useLocation();
    const NavigationData = location.state; // The data you passed
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
    const userDataSlice = useSelector((state) => state.userProfileDataSlice[0]);
    const dispatch = useDispatch()
    const cookies = new Cookies();
    const jwt_token = cookies.get("jwt_authorization");
    useEffect(() => {
        function handleResize() {
            setIsMobileView(window.innerWidth <= 800);
        }

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const fetchCurrentUserData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${jwt_token}`,
            };
            const response = await axios.get(
                `${baseURL}/api/User-Data/GetCurrentUser`,
                { headers }
            );
            const data = response?.data?.data;
            setFormDetails((prevFormDetails) => ({
                ...prevFormDetails,
                userDetails: {
                    firstName: data?.firstName ? data?.firstName : "",
                    lastName: data?.lastName ? data?.lastName : "",
                    phone: data?.phoneNumber ? data?.phoneNumber : "",
                    email: data?.email ? data?.email : "",
                    province: data?.province ? data?.province : "",
                },
                paymentDetails: {
                    civicNumber: data?.civicNumber ? data?.civicNumber : "",
                    streetName: data?.street ? data?.street : "",
                    cityRegion: data?.city ? data?.city : "",
                    postalCode: data?.postal ? data?.postal : "",
                    apartment: data?.apartment ? data?.apartment : "",
                }

            }));
            dispatch(addUserProfileData(data));
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!userDataSlice) {
            fetchCurrentUserData();
        }
        // eslint-disable-next-line
    }, [dispatch]);



    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        'User Details',
        "Billing Address",
        "Choose Your Pkg",
        'Payment'
    ];

    const [formDetails, setFormDetails] = useState({
        userDetails: {
            firstName: userDataSlice?.firstName ? userDataSlice?.firstName : "",
            lastName: userDataSlice?.lastName ? userDataSlice?.lastName : "",
            phone: userDataSlice?.phoneNumber ? userDataSlice?.phoneNumber : "",
            email: userDataSlice?.email ? userDataSlice?.email : "",
            province: userDataSlice?.province ? userDataSlice?.province : "",
            checkbox_1: false,
            checkbox_2: false,
            checkbox_3: false,


        },
        paymentDetails: {
            civicNumber: userDataSlice?.civicNumber ? userDataSlice?.civicNumber : "",
            streetName: userDataSlice?.street ? userDataSlice?.street : "",
            cityRegion: userDataSlice?.city ? userDataSlice?.city : "",
            postalCode: userDataSlice?.postal ? userDataSlice?.postal : "",
            apartment: userDataSlice?.apartment ? userDataSlice?.apartment : "",
            checkbox_4: false,
            checkbox_5: false,
        },
        pkgDetails: {
            pkgType: '',
            pkgPrice: ''
        },
        confirmationDetails: {
            cardNumber: '',
            cardfirstName: '',
            cardlastName: '',
            cardexpiry: '',
            cvcCode: ''
        },
    });

    const onNext = (data) => {
        setFormDetails((prevFormDetails) => {
            return {
                ...prevFormDetails,
                [getFormKey(activeStep)]: data,
            };
        });
        setActiveStep(activeStep + 1);
    };

    const onPrevious = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };

    const updateFormData = (newData) => {
        setFormDetails((prevFormDetails) => {
            return {
                ...prevFormDetails,
                [getFormKey(activeStep)]: newData,
            };
        });
    };

    const getFormKey = (step) => {
        switch (step) {
            case 0:
                return 'userDetails';
            case 1:
                return 'paymentDetails';
            case 2:
                return 'pkgDetails';
            case 3:
                return 'confirmationDetails';
            default:
                return '';
        }
    };

    return (
        <div style={{ background: "#fafafa" }} className='d-flex'>
            <div className='container '>
                <div className={isMobileView ? "flex-column" : "d-flex pb-5"}>
                    <div className={isMobileView ? 'w-100 mt-3' : "d-none"}>
                        <h5 className='fw-bold primary-color font m-0 text-center'>{NavigationData?.name}</h5>
                        <h6 className='fw-normal text-center my-0'> Total: $10949.95</h6>
                    </div>
                    <div className={isMobileView ? "d-none" : 'col-md-4 px-5 d-flex justify-content-center mt-5'}>
                        <div className={`${NavigationData?.border} bg-white p-3 lh-1`} style={{ borderRadius: "18px" }}>
                            <div className='d-flex justify-content-center'>
                                <img style={{ width: "85px" }} src={NavigationData?.name === "Starter" ? starterbadge : NavigationData?.name === "Premium" ? premiumbadge : NavigationData?.name === "Elite" ? elitebadge : null} alt={NavigationData?.name === "Starter" ? "Starter Logo" : NavigationData?.name === "Premium" ? "Premium Logo" : NavigationData?.name === "Elite" ? "Elite Logo" : 'No Logo'} loading="lazy"/>
                            </div>
                            <h3 className={`${NavigationData?.color} text-center mt-1 fw-bolder`}>{NavigationData?.name}</h3>
                            <p className='fw-normal font  my-2 text-shadow-black'>Residential</p>
                            <p className='fw-light dynmicFont14 font my-2'>In-home appointment1</p>
                            <p className='fw-light dynmicFont14 font my-2'> Online listing for 4 months</p>
                            <p className=' fw-normal font text-shadow-black'>8 HDR Photos1</p>
                            <hr className='my-2' />
                            <span className='d-flex justify-content-between font text-shadow-black'>
                                <p className='fw-normal my-1'> Sub-total:</p>
                                <p className='fw-normal my-1'> ${NavigationData?.price}</p>
                            </span>
                            <span className='d-flex justify-content-between font text-shadow-black'>
                                <p className='fw-normal my-2'> GST:</p>
                                <p className='fw-normal my-2'> $949.95</p>
                            </span>

                            <span className='d-flex justify-content-between font text-shadow-black'>
                                <p className='fw-normal my-1'> QST:</p>
                                <p className='fw-normal my-1'> $949.95</p>
                            </span>
                            <span className='d-flex justify-content-between font text-shadow-black'>
                                <h6 className='fw-normal my-2'> Total:</h6>
                                <p className='fw-normal my-2'> $10949.95</p>
                            </span>
                            <hr className='my-2' />
                            <h6 className='font fw-normal text-shadow-black'> A flexible choice</h6>
                            <p className='fw-light lineHight20px font mt-2 dynmicFont14'> After completing your purchase, you will still be able to change your package during your meeting with our representative.</p>
                            <ol className='fw-light '>
                                <li className='lh-1 font font13'>
                                    Not available in Abitibi-Témiscamingue, North Shore, Gaspé, Northern Quebec, and some Mauricie, Laurentides and Charlevoix locations. In these regions, photos are supplied by the client, up to the maximum number designated in each package.
                                </li>
                                <li className='lh-1 font font13 mt-2'>
                                    Only available for residential properties. This tool may not be available for certain types of properties or in certain areas.
                                </li>
                            </ol>
                            <div className='d-flex justify-content-center '>
                                <button className='btn btn-outline-secondary modifyBtn' onClick={() => navigate('/sell')}>
                                    Modify
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* ----------------------- Steeper1  section  ----------------------------- */}

                    <div className={isMobileView ? "pe-0  position-relative margincard" : 'col-md-8 pe-4 d-flex flex-column position-relative margincard'}>

                        <div>

                            <h4 className='fw-bolder fontmobile20 font'>
                                Register in 4 easy steps
                                {/* ({activeStep + 1}/{steps.length}) */}
                            </h4>
                            <div className={isMobileView ? 'mt-3 d-flex ' : "d-none"}>
                                <Stepper activeStep={activeStep} alternativeLabel >
                                    {steps.map((label) => (
                                        <Step key={label} >
                                            <StepLabel className='steeperWidth'> </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </div>

                            {activeStep === 0 && <UserDetails onNext={onNext} formData={formDetails.userDetails} updateFormData={updateFormData} />}
                            {activeStep === 1 && <BillingAddress onNext={onNext} onPrevious={onPrevious} formData={formDetails.paymentDetails} updateFormData={updateFormData} />}
                            {activeStep === 2 && <ChooseYourPkg onPrevious={onPrevious} onNext={onNext} formData={formDetails.pkgDetails} updateFormData={updateFormData} />}
                            {activeStep === 3 && <Confirmation onPrevious={onPrevious} formData={formDetails.confirmationDetails} updateFormData={updateFormData} />}

                        </div>
                    </div>
                    {/* ----------------------- Steeper2  section  ----------------------------- */}


                </div>
            </div >
            <div className={isMobileView ? "d-none" : 'col-md-1 d-flex flex-column position-relative  steeper2-section'}>
                <div className='steeper-line'></div>
                <div className='steeper-icons'>

                    {activeStep === 0 && <div className='pollygon-steeper1'></div>}
                    <div className='steeper-icon'>
                        <div className={activeStep === 0 ? "steeper-icon-circle steeper-icon-active" : "steeper-icon-circle"}></div>
                        <div className={activeStep === 0 ? "steeper-icon-label steeper-icon-label-active" : "steeper-icon-label"}>1</div>
                    </div>

                    {activeStep === 1 && <div className='pollygon-steeper2'></div>}
                    <div className='steeper-icon'>
                        <div className={activeStep === 1 ? "steeper-icon-circle steeper-icon-active" : "steeper-icon-circle"}></div>
                        <div className={activeStep === 1 ? "steeper-icon-label steeper-icon-label-active" : "steeper-icon-label"}>2</div>
                    </div>

                    {activeStep === 2 && <div className='pollygon-steeper3'></div>}
                    <div className='steeper-icon'>
                        <div className={activeStep === 2 ? "steeper-icon-circle steeper-icon-active" : "steeper-icon-circle"}></div>
                        <div className={activeStep === 2 ? "steeper-icon-label steeper-icon-label-active" : "steeper-icon-label"}>3</div>
                    </div>

                    {activeStep === 3 && <div className='pollygon-steeper4'></div>}
                    <div className='steeper-icon'>
                        <div className={activeStep === 3 ? "steeper-icon-circle steeper-icon-active" : "steeper-icon-circle"}></div>
                        <div className={activeStep === 3 ? "steeper-icon-label steeper-icon-label-active" : "steeper-icon-label"}>4</div>
                    </div>
                </div>
            </div>
        </div >
    );
}

