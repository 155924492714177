import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { IoIosArrowDown } from "react-icons/io";
import { TiTick } from "react-icons/ti";
const ChooseYourPkg = ({ onPrevious, onNext, formData, updateFormData }) => {
    const [showModal, setShowModal] = useState(false)
    const [highlighedBumpUp, sethilightedBumpUp] = useState(false);
    const [highlighedTopAdd, sethilightedTopAdd] = useState(false);
    const [highlighedFeatured, sethilightedFeatured] = useState(false);
    const [selectedpkg, setselectedpkg] = useState()
    const [disableClicked, setdisableClicked] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
    useEffect(() => {
        function handleResize() {
            setIsMobileView(window.innerWidth <= 800);
        }

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleChoosePkg = (selectedvalue) => {
        setselectedpkg(selectedvalue)
        setShowModal(true);
    }
    const handleCloseModal = () => {
        setShowModal(false)
    }
    const handlePkgSelection = () => {

        if (selectedpkg === "BumpUp") {
            sethilightedBumpUp(true);
            setdisableClicked(true);

        }
        else if (selectedpkg === "TopAdd") {
            sethilightedTopAdd(true);
            setdisableClicked(true);
        }
        else if (selectedpkg === "Featured") {
            sethilightedFeatured(true);
            setdisableClicked(true);
        }
        const updatedFormData = {
            ...formData,
            pkgType: selectedpkg
        };
        updateFormData(updatedFormData); // Update the parent state
        setShowModal(false);


    }




    return (
        <>

            <Dialog
                open={showModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogTitle id="alert-dialog-title" className='px-3 pt-5'>
                    <div className='d-flex justify-content-center'>
                        <img className='imghw' src={require("../Images/ConfirmPkg.png")} alt="Confirm Package" loading="lazy"/>
                    </div>
                </DialogTitle>
                <DialogContent className='pb-0' >
                    <DialogContentText className='text-center'
                        id="alert-dialog-description">
                        <p className='text-shadow-black mt-2 mb-0 font'>    Are your sure want to choose </p>
                        <p className='text-shadow-black font'>this package</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='pb-4 d-flex justify-content-evenly'>
                    <button onClick={handleCloseModal} className='btn border fw-bold primary-color px-4 font'>
                        <span> <IoIosClose /> No</span>
                    </button>

                    <button onClick={handlePkgSelection} className='btn btn-primary border primary-bg text-white font px-4'>
                        <span> <TiTick /> Yes</span>
                    </button>
                </DialogActions>
            </Dialog>
            <div className='bg-white getquote_card_padding' style={{ borderRadius: "30px" }}>
                <div className='margin_bottom'>
                    <h4 >
                        3. Choose your package
                    </h4>
                </div>
                <div className="mt-4">
                    <hr className={isMobileView ? "d-none" : "my-2"} />
                    <span className={isMobileView ? "d-none" : "d-flex"} > <p className="col-md-3 m-0 text-center font">Catagory</p> <p className="col-md-4 m-0 text-center font">Price</p> <p className="col-md-3 font m-0 text-center">Days</p>               </span>
                    <hr className={isMobileView ? "d-none" : "my-2"} />
                    {/* highlightPkg */}
                    <div className={`${isMobileView ? "flex-column" : "d-flex"}  mt-4 HighlightselectedPkg ${highlighedBumpUp || formData?.pkgType === "BumpUp" ? "highlightPkg" : ""} `}>
                        <div className={` ${isMobileView ? "w-100 justify-content-between align-center" : "w-50 d-flex justify-content-between align-center"}  `}>
                            <div className='d-flex  '>
                                {/* <img src={require('../Images/BumpUpIcon.png')} alt="" style={{ height: "35px" }} /> */}
                                <p className='font ms-3 mb-0 align-center fontdesign-pkg'>Top Home Page Gallery</p>
                            </div>
                            <div className='d-flex align-center'>
                                <p className='font ms-3 mb-0 fontdesign-pkg'>From <span className='fw-bold' style={{ color: "#4FB020" }}>$15.77</span></p>
                            </div>
                        </div>
                        <div className={` ${isMobileView ? "w-100 mt-3 justify-content-between align-center" : "w-50 d-flex justify-content-around"}   `}>
                            <div className=' d-flex align-center'>
                                <p className='font ms-3 mb-0 fontdesign-pkg'>7 days <IoIosArrowDown /></p>
                            </div>
                            <div onClick={() => disableClicked ? null : handleChoosePkg("BumpUp")}>
                                <img src={highlighedBumpUp || formData?.pkgType === "BumpUp" ? require("../Images/MinusIcon.png") : require("../Images/AddIcon.png")} alt="Add package" style={{ height: "35px" }} loading="lazy"/>
                            </div>
                        </div>
                    </div>

                    <div className={`${isMobileView ? "flex-column " : "d-flex"}  mt-4 HighlightselectedPkg ${highlighedTopAdd || formData?.pkgType === "TopAdd" ? "highlightPkg" : ""} `}>
                        <div className={` ${isMobileView ? "w-100 justify-content-between align-center" : "w-50 d-flex justify-content-between align-center"}  `}>
                            <div className='d-flex '>
                                {/* <img src={require('../Images/TopAddIcon.png')} alt="" style={{ height: "35px" }} /> */}
                                <p className='font ms-3 mb-0 align-center fontdesign-pkg'>Home Page Ticker</p>
                            </div>
                            <div className=' d-flex align-center'>
                                <p className='font ms-3 mb-0 fontdesign-pkg'>From <span className='fw-bold' style={{ color: "#4FB020" }}>$5.75</span></p>
                            </div>
                        </div>

                        <div className={` ${isMobileView ? "w-100 mt-3 justify-content-between align-center" : "w-50 d-flex justify-content-around"}   `}>
                            <div className=' d-flex align-center'>
                                <p className='font ms-3 mb-0 fontdesign-pkg'>30 days <IoIosArrowDown /> </p>
                            </div>
                            <div onClick={() => disableClicked ? null : handleChoosePkg("TopAdd")}>
                                <img src={highlighedTopAdd || formData?.pkgType === "TopAdd" ? require("../Images/MinusIcon.png") : require("../Images/AddIcon.png")} alt="Add package" style={{ height: "35px" }} loading="lazy"/>
                            </div>
                        </div>
                    </div>
                    <div className={`${isMobileView ? "flex-column" : "d-flex"}    mt-4 HighlightselectedPkg ${highlighedFeatured || formData?.pkgType === "Featured" ? "highlightPkg" : ""} `}>
                        <div className={` ${isMobileView ? "w-100 justify-content-between align-center" : "w-50 d-flex justify-content-between align-center"}  `}>
                            <div className='d-flex '>
                                {/* <img src={require('../Images/featuredIcon.png')} alt="" style={{ height: "35px" }} /> */}
                                <p className='font ms-3 mb-0 align-center fontdesign-pkg'>Highlight Add</p>
                            </div>
                            <div className=' d-flex align-center'>
                                <p className='font ms-3 mb-0 fontdesign-pkg'>From <span className='fw-bold' style={{ color: "#4FB020" }}>$7.00</span></p>
                            </div>
                        </div>
                        <div className={` ${isMobileView ? "w-100 mt-3 justify-content-between align-center" : "w-50 d-flex justify-content-around"}   `}>
                            <div className=' d-flex align-center'>
                                <p className='font ms-3 mb-0 fontdesign-pkg'>14 days <IoIosArrowDown /></p>
                            </div>
                            <div onClick={() => disableClicked ? null : handleChoosePkg("Featured")}>
                                <img src={highlighedFeatured || formData?.pkgType === "Featured" ? require("../Images/MinusIcon.png") : require("../Images/AddIcon.png")} alt="Add package" style={{ height: "35px" }} loading="lazy"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex' >
                    <button
                        className='font btn btn-outline-primary fs-5 text-shadow-black me-2 bottom fontmobile16 primary-color bg-white'
                        onClick={onPrevious}
                        style={{ position: 'absolute', bottom: '80px', left: isMobileView ? '0' : '20px', border: "1px solid", padding: isMobileView ? "8px 12px" : "10px 25px" }}
                    >
                        &lt; Previous
                    </button>
                    {formData.pkgType ? <button
                        style={{ position: 'absolute', bottom: '80px', right: isMobileView ? "0" : '20px', padding: isMobileView ? "8px 12px" : "10px 25px" }}
                        className='btn font btn-primary fs-5 bottom text-shadow-black fontmobile16 primary-bg'
                        onClick={() => onNext(formData)}
                    // disabled={!isFormValid}
                    >
                        Next &gt;
                    </button>
                        :
                        <button
                            className='font btn btn-outline-primary text-shadow-black fs-5 me-2 bottom fontmobile16 primary-color bg-white'
                            onClick={() => onNext(formData)}
                            style={{ position: 'absolute', bottom: '80px', right: isMobileView ? "0" : '20px', border: "1px solid", padding: isMobileView ? "8px 12px" : "10px 25px" }}
                        >
                            List without Premium
                        </button>}
                </div>



            </div>
        </>
    )
}

export default ChooseYourPkg;





// import { useCallback } from "react";

// const Frame = () => {
//   const onFrameContainerClick = useCallback(() => {
//     // Please sync "assignment sold" to the project
//   }, []);

//   const onFrameContainer1Click = useCallback(() => {
//     // Please sync "assignment sold" to the project
//   }, []);

//   return (

//   );
// };

// export default Frame;
