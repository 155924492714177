import "./savedsearch.css";
import React, { useState, useRef } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Modal, Button } from 'react-bootstrap'; // Import the Bootstrap Dropdown and Modal components
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import axios from "axios";
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import NotFoundImge from '../../../images/SavedSearchDemo.png'


import { RotatingLines } from 'react-loader-spinner';
import { toast } from "react-toastify";
import baseURL from "../../../config";
import Cookies from "universal-cookie";
import { useNavigate } from 'react-router-dom';

const SavedSearchTable = ({ data, loading, setloading, setData }) => {
    const tableRef = useRef();
    const [showModal, setShowModal] = useState(false);
    const [modalData, setmodalData] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const cookies = new Cookies();
    const jwt_token = cookies.get('jwt_authorization');
    const formatter = new Intl.NumberFormat();
    const navigate = useNavigate();

    const columns = [
        {
            dataField: 'assignment',
            text: 'Search Name',
            formatter: assignmentFormatter,
            headerFormatter: HeaderAlignCenter,
            headerStyle: { backgroundColor: '#EAECF0', color: '#0253A0' },
        },
        {
            dataField: 'searchparameters.maxPrice',
            text: 'Price Range',
            formatter: Priceformatter,
            headerFormatter: HeaderAlignCenter,
            headerStyle: { backgroundColor: '#EAECF0', color: '#0253A0' },
        },

        {
            dataField: 'searchparameters.maxSqft',
            text: 'Sqft',
            headerFormatter: HeaderAlignCenter,
            headerStyle: { backgroundColor: '#EAECF0', color: '#0253A0' },
            formatter: SqftFormater
        },

        {
            dataField: 'created',
            text: 'Created Date',
            headerFormatter: HeaderAlignCenter,
            headerStyle: { backgroundColor: '#EAECF0', color: '#0253A0' },
            formatter: Dateformatter,
        },
        {
            dataField: 'actions',
            text: 'View Details',
            headerStyle: { backgroundColor: '#EAECF0', color: '#0253A0' },
            formatter: viewDetailsFormatter, // Add the formatter function for the button
        },
        {
            dataField: 'searchparameters.parking',
            text: 'Action',
            headerStyle: { backgroundColor: '#EAECF0', color: '#0253A0' },
            formatter: ActionFormate,
        },
    ];




    function viewDetailsFormatter(cell, row) {
        return (
            <Button size="sm" style={{ opacity: "0.7" }} variant="outline-primary" onClick={() => handleViewDetails(row)}>
                View Details
            </Button>
        );
    };

    function ActionFormate(cell, row) {
        return (
            <Button size="sm" style={{ opacity: "0.7" }} variant="outline-primary" className="ps-4 pe-4" onClick={() => handleSearch(row)} >
                {" "}  Search{" "}
            </Button>

        );
    };

    function assignmentFormatter(cell, row) {
        return (
            <div className="text-start">
                {row.searchName}
            </div>
        );
    }

    function HeaderAlignCenter(column, colIndex, { sort, sortField, sortOrder }) {
        const isSorting = sortField === column.dataField;
        const sortCaret = (
            <UnfoldLessIcon className={`sort-caret ${isSorting ? sortOrder : ''}`} />
        );
        return (
            <div className='text-start' >
                {column.text} {sortCaret}

            </div>
        );
    }
    function Priceformatter(cell, row) {
        return (
            <div className="text-start">
                ${formatter.format(row?.searchparameters?.minPrice)} - ${formatter.format(row?.searchparameters?.maxPrice)}
            </div>
        )
    }

    function SqftFormater(cell, row) {
        return (
            <div className="text-start">
                {formatter.format(row.searchparameters.minSqft)}-{formatter.format(row.searchparameters.maxSqft)} Sqft
            </div>

        )
    }

    function Dateformatter(cell, row) {
        const dateStr = row?.created;
        const date = new Date(dateStr);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString(undefined, options);
        return (
            <div className="text-start">
                {formattedDate}
            </div>
        );
    }

    const handledeleteSearch = () => {
        const headers = {
            Authorization: `Bearer ${jwt_token}`,
        };
        setloading(true);
        handleCloseModal();
        const apiEndpoint = `${baseURL}/api/User-Data/DeleteSearch/${modalData?.uId}`;
        axios
            .delete(apiEndpoint, { headers })
            .then(response => {
                setloading(false);
                // toast.success(response?.data?.message);
                setData(prevData => prevData.filter(item => item.uId !== modalData?.uId));
            })
            .catch(error => {
                console.error('Error deleting:', error);
                setloading(false);
                // toast.error(error);
            });
    };

    const handleSearch = (row) => {
        const searchData = row.searchparameters;
        // navigate(`?${row.uId}`, { state: { searchData } });
        navigate('/', { state: { searchData } });
    };


    const handleViewDetails = (row) => {
        setmodalData(row)
        handleShowModal();
    };

    if (loading === false && data.length === 0) {
        return (
            <>
                <div className="no__FoundMain font align-items-start py-0">
                    <div className="">
                        <p>Get notified automatically when your filtered search gets new property matches. To add a new Saved Search, apply your chosen filters, and then
                        select the 'Save' button located below the filter button. Now regularly check your back here or in your inbox for new Properties matching your search filters and property requirements. </p>
                    </div>
                    <div className=" ">
                        <img className="w-75" src={NotFoundImge} alt="Result not found" loading="lazy"/>
                    </div>
                </div>
            </>
        )
    }
    return (
        <div>

            {loading === true ?
                <center className='mt-5 text-center'>
                    < RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="70"
                        visible={loading}
                    />
                </center>
                :
                <BootstrapTable
                    ref={tableRef}
                    keyField="id"
                    data={data}
                    columns={columns}
                    selectRow={{ mode: 'checkbox', clickToSelect: false }}
                    wrapperClasses="custom-table overflow-auto mt-3"
                />
            }
            {/* Modal for displaying card details */}
            <Modal aria-labelledby="contained-modal-title-vcenter"
                centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton className='brodernone ps-5 pe-5 pb-0 pt-4' >
                    <Modal.Title className='d-flex justify-content-between w-100'>
                        {modalData?.searchName}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-0 flex-column ps-5 pe-5'>
                    {
                        modalData?.searchparameters?.searchparameters_Citys && modalData.searchparameters.searchparameters_Citys.length > 0 && (
                            <div className="mt-2">
                                <div className="text-center">
                                    <span className="text-center fw-bold">Address :</span>
                                </div>
                                <div className="mt-0">
                                    <p className="text-center m-0">
                                        {modalData.searchparameters.searchparameters_Citys
                                            .filter((city) => city.parameter) // Filter out empty values
                                            .map((city) => city.parameter) // Extract parameter values
                                            .join(", ")} {/* Join the non-empty values with a comma */}
                                    </p>
                                </div>
                            </div>
                        )
                    }


                    {
                        modalData?.searchparameters?.searchparameters_Types && modalData.searchparameters.searchparameters_Types.length > 0 && (
                            <div className="mt-2">
                                <div className="text-center">
                                    <span className="text-center fw-bold">Property Type</span>
                                </div>
                                <div className="mt-0">
                                    <p className="text-center m-0">
                                        {modalData.searchparameters.searchparameters_Types
                                            .filter((type) => type.parameter) // Filter out empty values
                                            .map((type) => type.parameter) // Extract parameter values
                                            .join(", ")} {/* Join the non-empty values with a comma and space */}
                                    </p>
                                </div>
                            </div>
                        )
                    }

                    {
                        modalData?.searchparameters?.maxPrice &&
                        <div className=" mt-2" >
                            <div className="text-center">
                                <span className="text-center fw-bold">
                                    Price Range
                                </span>
                            </div>
                            <div className="mt-0">
                                <p className="text-center m-0 ">
                                    ${formatter.format(modalData?.searchparameters?.minPrice)} -${formatter.format(modalData?.searchparameters?.maxPrice)}
                                </p>
                            </div>
                        </div>
                    }
                    {
                        modalData?.searchparameters?.maxSqft &&
                        <div className="mt-2" >
                            <div className="text-center ">
                                <span className="text-center fw-bold">
                                    Size Sqft
                                </span>
                            </div>
                            <div className="mt-0">
                                <p className="text-center m-0 ">
                                    {formatter.format(modalData?.searchparameters?.minSqft)}-{formatter.format(modalData?.searchparameters?.maxSqft)} Sqft
                                </p>
                            </div>
                        </div>
                    }
                    {
                        modalData?.searchparameters?.maxRadius &&
                        <div className="mt-2" >
                            <div className="text-center fw-bold">
                                <span>
                                    Radius
                                </span>
                            </div>
                            <div className="mt-0">
                                <p className="text-center m-0 ">
                                    {formatter.format(modalData?.searchparameters?.minRadius)}-{formatter.format(modalData?.searchparameters?.maxRadius)} Km
                                </p>
                            </div>
                        </div>
                    }
                    {
                        modalData?.searchparameters?.searchparameters_Beds && modalData.searchparameters.searchparameters_Beds.length > 0 && (
                            <div className=" mt-2">
                                <div className="text-center">
                                    <span className="text-center fw-bold">Bedrooms</span>
                                </div>
                                <div className="mt-0">
                                    <p className="text-center m-0">
                                        {modalData.searchparameters.searchparameters_Beds
                                            .filter((bed) => bed.parameter) // Filter out empty values
                                            .map((bed) => bed.parameter) // Extract parameter values
                                            .join(", ")} {/* Join the non-empty values with a comma */}
                                    </p>
                                </div>
                            </div>
                        )
                    }

                    {
                        modalData?.searchparameters?.searchparameters_Baths && modalData.searchparameters.searchparameters_Baths.length > 0 && (
                            <div className="mt-2">
                                <div className="text-center fw-bold">
                                    <span>Bathrooms</span>
                                </div>
                                <div className="mt-0">
                                    <p className="text-center m-0">
                                        {modalData.searchparameters.searchparameters_Baths
                                            .filter((bath) => bath.parameter) // Filter out empty values
                                            .map((bath) => bath.parameter) // Extract parameter values
                                            .join(", ")} {/* Join the non-empty values with a comma */}
                                    </p>
                                </div>
                            </div>
                        )
                    }

                    {
                        modalData?.searchparameters?.searchparameters_Parkings && modalData.searchparameters.searchparameters_Parkings.length > 0 && (
                            <div className="mt-2">
                                <div className="text-center fw-bold">
                                    <span>Parking</span>
                                </div>
                                <div className="mt-0">
                                    <p className="text-center m-0">
                                        {modalData.searchparameters.searchparameters_Parkings
                                            .filter((parking) => parking.parameter) // Filter out empty values
                                            .map((parking) => parking.parameter) // Extract parameter values
                                            .join(", ")} {/* Join the non-empty values with a comma */}
                                    </p>
                                </div>
                            </div>
                        )
                    }



                </Modal.Body>
                <Modal.Footer className='brodernone d-flex justify-content-center mt-3 mb-3'>
                    <Button variant="outline-primary" onClick={handledeleteSearch} style={{ width: "42%" }}>
                        Remove
                    </Button>
                    <Button variant="primary" onClick={() => handleSearch(modalData)} style={{ width: "42%" }}>
                        Search
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
};

export default SavedSearchTable;
