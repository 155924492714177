import HelmetLayout from "../../Utils/HelmetLayout/Helmet";
import "../FinanceOption/FinanceOption.css";
import Footer from "../Footer/Footer";
import "./Press.css";
const Press = () => {
  const data = [
    {
      date: "April 27, 2023",
      description: "Canal Vie and AssignmentSold partner on a new TV program",
    },
    {
      date: "April 18, 2023",
      description:
        "AssignmentSold Mobile Application Nominated for a Prestigious Award",
    },
    {
      date: "January 17, 2023",
      description: "AssignmentSold: The Mobile App’s Big Comeback",
    },
    {
      date: "April 7, 2023",
      description: "New Logo, Same Promises",
    },
    {
      date: "April 18 2023",
      description:
        "AssignmentSold Mobile Application Nominated for a Prestigious Award",
    },
    {
      date: "February 1, 2022",
      description:
        "AssignmmentSold is proud to celebrate its 25th anniversary in 2022",
    },
    {
      date: "April 18, 2023",
      description:
        "Assignment Mobile Application Nominated for a Prestigious Award",
    },
    {
      date: "April 18, 2023",
      description:
        "Assignment Mobile Application Nominated for a Prestigious Award",
    },
    {
      date: "April 18, 2023",
      description:
        "Assignment Mobile Application Nominated for a Prestigious Award",
    },
    {
      date: "April 18, 2023",
      description:
        "Assignment Mobile Application Nominated for a Prestigious Award",
    },
    {
      date: "April 18, 2023",
      description:
        "Assignment Mobile Application Nominated for a Prestigious Award",
    },
  ];

  return (
    <>

      <HelmetLayout pageName={'press'} canonicalLink={'press'} />

      <div className="finace_font press_main">
        {/* ----------------- Press Main Headings Section--------------------- */}
        <div className="d-flex text-white w-100 p-4 finance_container">
          <div className="container finance_container">
            <h2 className="Finance_Heading">Press</h2>

            <div>
              <p className="Press_para mt-3 mb-5">
                Discover more about AssignmentSold and stay updated with our
                latest press releases. If you can't find what you're looking for,
                feel free to reach out to us. For any inquiries, please email us
                at media@assignmentsold.com. We're here to assist you!
              </p>
            </div>
          </div>
        </div>

        {/* ----------------- Press Card Section--------------------- */}

        <div className="container">
          <div className="row  px-3">
            {data.map((item, index) => (
              <div className="col-12 mt-5 pressCard" key={index}>
                <h6>{item?.date}</h6>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* ----------------- Press Footer Section--------------------- */}

        <div className="mt-5">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Press;
