import * as React from "react";
import { useState } from "react";
import CardMain from "../../../HomePage/SubComponents/MapandListing/CardMain";
import "../../../HomePage/SubComponents/CardListing/Card.css";
import "../../../../src/Components.css";
import "../../../../src/App.css";
import "./FavCards.css";
import baseURL from "../../../config";
import axios from "axios";
import NotFoundImge from "../../../images/EmptyFav.png";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CardMainSkeleton from "../../../HomePage/SubComponents/MapandListing/CardMainSkeleton";
import { setFavItems } from "../../../ReduxStore/Slices/HeartDataSlice";

export default function FavouriteCards() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const dispatch = useDispatch();

  const favouriteData = useSelector((state) => state.heartDataSlice);
  const [data, setData] = useState([]);
  const [cardToShow, setCardToShow] = useState([]);

  // Loader
  const [isLoading, setIsLoading] = useState(true);

  const handleFavoriteUpdate = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/User-Data/GetFavouriteByUser`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      if (response?.data?.result === 1) {
        const Favdata = response?.data?.data?.map((item) => item.assignment);
        const favItems = response?.data?.data
          .filter(
            (item) =>
              !favouriteData?.favItems?.some((fav) => fav.uId === item.uId)
          )
          .map((item) => ({
            uId: item?.proId,
            tableUId: item?.uId,
          }));

        if (favItems.length > 0) {
          dispatch(setFavItems(favItems));
        }
        setData(Favdata);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error("There is problem in fetching data");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("There is problem in fetching data");
      console.error("There is problem in fetching data", error);
    }
  };

  useEffect(() => {
    if (!jwt_token) {
      return navigate("/login", {
        state: { returnUrl: `dashboard/favourite` },
      });
    }
    handleFavoriteUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const commonIds = favouriteData?.favItems?.map((item) => item.uId);
    const filteredData = data?.filter((item) => commonIds.includes(item.uId));
    setCardToShow(filteredData);
  }, [data, favouriteData]);

  return (
    <>
      <div className="font mt-3">
        <h3 className="fw-bold text-black ">Favorite Property</h3>
      </div>
      <div className="cardsmapper d-flex flex-wrap">
        <div
          className={` ${isLoading ? "aligncenter justifycenter" : ""}  col-12`}
        >
          {isLoading ? (
            <div
              className="d-flex flex-wrap justify-content-start mb-3 mob_fav_card"
              style={{ gap: "15px" }}
            >
              {Array.from(new Array(8))?.map((_, index) => (
                <CardMainSkeleton key={index} />
              ))}
            </div>
          ) : (
            <>
              {cardToShow?.length === 0 ? (
                <div className="no__FoundMain font py-0 Mobile__Justigy__Start ">
                  <div className="font">
                    <p>
                      You have not saved any favourite properties yet. To add a
                      property to your favorite list is simple, by tapping on
                      the heart icon
                    </p>
                  </div>
                  <div className=" ">
                    <img
                      className="ReponsiveWidth"
                      src={NotFoundImge}
                      alt="No Favourite"
                      style={{ width: "320px" }}
                      loading="lazy"
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="d-flex flex-wrap justify-content-start mb-3 mob_fav_card"
                    style={{ gap: "15px" }}
                  >
                    {cardToShow?.length !== 0 &&
                      cardToShow?.map((item, index) => (
                        <CardMain key={index} item={item} />
                      ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
