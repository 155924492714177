import React, { useState, memo } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ArrowDownIcon, WoodFlooringIcon } from "../../../../SvgIcons/Icons";
import { Select, MenuItem } from "@mui/material";
import RealtorPropertiesMap from "../RealtorPropertiesMap";

const TransactionHistory = ({ isMobileView, data }) => {
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  };

  const [expanded, setExpanded] = useState(isMobileView ? false : true);
  const [value, setValue] = useState(0);
  const tabLabels = ["All", "Active", "Sold", "Rented"];
  const durationTabs = ["All", "1 Year", "2 Year", "3 Year"];
  const neighbourhood = ["Toronto", "Ontario", "Oshawa", "Downtown"];
  const [selectedNeighbourhood, setSelectedNeighbourhood] = useState(" ");
  const [selectedDuration, setSelectedDuration] = useState(0);
  const [selectedIndustryType, setSelectedIndustryType] =
    useState("Residential");

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNeighbourhood = (event) => {
    setSelectedNeighbourhood(event.target.value);
  };

  const handleDuration = (event, newValue) => {
    setSelectedDuration(newValue);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <RemoveIcon className="Property_minusWebGreyIcon" />
          ) : (
            <AddIcon className="Property_plusWebGreyIcon" />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex flex-column" : ""}>
          <div className="d-flex align-items-center gap-2">
            {isMobileView && (
              <WoodFlooringIcon color={"#282828"} size={"20px"} />
            )}
            <h2 className="property_Details_heading">Transaction History</h2>
          </div>
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
            Essential insights to make informed decisions—everything you need to
            know in one place.
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="d-flex mt-3 justify-content-between align-items-center flex-wrap gap-3">
          <div
            className="Prop_detailsFloorPlan_selectBtnGroup"
            style={{ width: "fit-content" }}
          >
            <div className="btn-group" role="group" aria-label="Basic example">
              <button
                type="button"
                className={`Prop_detailsFloor_plan_selectBtn PropertyDetails_Mbl_f14 ${
                  selectedIndustryType === "Residential" &&
                  "floor_plan_buildingSelectBtn"
                }`}
                onClick={() => setSelectedIndustryType("Residential")}
              >
                Residential
              </button>
              <button
                type="button"
                className={`Prop_detailsFloor_plan_selectBtn PropertyDetails_Mbl_f14 ${
                  selectedIndustryType === "Commercial" &&
                  "floor_plan_buildingSelectBtn"
                }`}
                onClick={() => setSelectedIndustryType("Commercial")}
                style={{
                  borderRadius: "0px 10px 10px 0px",
                  borderLeft: "none",
                }}
              >
                Commercial
              </button>
            </div>
          </div>
          <div
            style={{ width: "250px" }}
            className="Prop_details_simpleChart_container border-0 p-0"
          >
            <Select
              labelId="Select"
              id="terms"
              value={selectedNeighbourhood}
              onChange={handleNeighbourhood}
              sx={{
                borderRadius: "10px",
                padding: "9px 12px",
                width: "100%",
              }}
              IconComponent={ArrowDownIcon}
            >
              <MenuItem value=" " disabled>
                Select neighbourhood
              </MenuItem>
              {neighbourhood.map((item, index) => (
                <MenuItem key={index} value={item}>{`${item}`}</MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <Box className="mt-3">
          <AppBar
            position="static"
            sx={{
              boxShadow: "none",
            }}
          >
            <Tabs
              value={value}
              onChange={handleTabChange}
              textColor="inherit"
              sx={{
                backgroundColor: "#fff",
              }}
              className="Property_details_tabs"
              elevation={0}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {tabLabels?.map((tab, index) => (
                <Tab
                  key={index}
                  label={`${tab} (12)`}
                  sx={{
                    border: "1px solid #CCCCCC",
                    fontWeight: "700",
                    padding: "15px 30px",
                  }}
                />
              ))}
            </Tabs>
          </AppBar>
          <AppBar
            position="static"
            className="mt-3"
            sx={{
              boxShadow: "none",
            }}
          >
            <Tabs
              value={selectedDuration}
              onChange={handleDuration}
              textColor="inherit"
              sx={{
                backgroundColor: "#fff",
              }}
              className="Property_details_tabs realtor_duration_tabs"
              variant="scrollable"
              elevation={0}
              scrollButtons
              allowScrollButtonsMobile
            >
              {durationTabs?.map((tab, index) => (
                <Tab
                  key={index}
                  label={`${tab}`}
                  sx={{
                    fontWeight: "700",
                    padding: "15px 30px",
                  }}
                  className="realtor_transaction_duration"
                />
              ))}
            </Tabs>
          </AppBar>
          <TabPanel className="PropertyDetail_tabPanel">
            <div
              className="w-100 py-2 PropDetails_HideScrollBar position-relative"
              style={{ overflowX: "auto" }}
            >
              <table
                className="Property_floorPlan_table Retailer_history_table w-100"
                style={{ minWidth: "100%" }}
              >
                <thead className="w-100">
                  <tr className="w-100">
                    <th
                      style={{
                        width: "33.33%",
                        borderTopLeftRadius: "16px",
                      }}
                    >
                      Buyer Side
                    </th>
                    <th
                      style={{
                        width: "33.33%",
                      }}
                    ></th>
                    <th
                      style={{ width: "33.33%", borderTopRightRadius: "16px" }}
                    >
                      Average
                    </th>
                  </tr>
                </thead>
                <tbody style={{ borderBottom: "1px solid #d9d9d9" }}>
                  <tr>
                    <td>Total Deals</td>
                    <td className="history_average_price">57</td>
                    <td className="history_average_price">57</td>
                  </tr>
                  <tr>
                    <td>Total Value</td>
                    <td className="history_average_price">$21,178,051</td>
                    <td className="history_average_price">$21,178,051</td>
                  </tr>
                  <tr>
                    <td>Average Sale Price</td>
                    <td className="history_average_price">$371,545</td>
                    <td className="history_average_price">$371,545</td>
                  </tr>
                  <tr>
                    <td>Median Sale Price</td>
                    <td className="history_average_price">$371,545</td>
                    <td className="history_average_price">$371,545</td>
                  </tr>
                  <tr>
                    <td>Median days on market</td>
                    <td className="history_average_price">$371,545</td>
                    <td className="history_average_price">$371,545</td>
                  </tr>
                  <tr>
                    <td>Average Price / SqFt</td>
                    <td className="history_average_price">$371,545</td>
                    <td className="history_average_price">$371,545</td>
                  </tr>
                  <tr>
                    <td>Closed Rate</td>
                    <td className="history_average_price">$371,545</td>
                    <td className="history_average_price">$371,545</td>
                  </tr>
                  <tr>
                    <td>Price Range</td>
                    <td className="history_average_price">$73K - $1.4M</td>
                    <td className="history_average_price">$73K - $1.4M</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="w-100 py-2 PropDetails_HideScrollBar position-relative"
              style={{ overflowX: "auto" }}
            >
              <table
                className="Property_floorPlan_table Retailer_history_table w-100"
                style={{ minWidth: "100%" }}
              >
                <thead className="w-100">
                  <tr className="w-100">
                    <th
                      style={{
                        width: "33.33%",
                        borderTopLeftRadius: "16px",
                      }}
                    >
                      Seller Side
                    </th>
                    <th
                      style={{
                        width: "33.33%",
                      }}
                    ></th>
                    <th style={{ width: "33.33%", borderTopRightRadius: "16px" }}>
                      Average
                    </th>
                  </tr>
                </thead>
                <tbody style={{ borderBottom: "1px solid #d9d9d9" }}>
                  <tr>
                    <td>Total Deals</td>
                    <td className="history_average_price">57</td>
                    <td className="history_average_price">57</td>
                  </tr>
                  <tr>
                    <td>Total Value</td>
                    <td className="history_average_price">$21,178,051</td>
                    <td className="history_average_price">$21,178,051</td>
                  </tr>
                  <tr>
                    <td>Average Sale Price</td>
                    <td className="history_average_price">$371,545</td>
                    <td className="history_average_price">$371,545</td>
                  </tr>
                  <tr>
                    <td>Median Sale Price</td>
                    <td className="history_average_price">$371,545</td>
                    <td className="history_average_price">$371,545</td>
                  </tr>
                  <tr>
                    <td>Median days on market</td>
                    <td className="history_average_price">$371,545</td>
                    <td className="history_average_price">$371,545</td>
                  </tr>
                  <tr>
                    <td>Average Price / SqFt</td>
                    <td className="history_average_price">$371,545</td>
                    <td className="history_average_price">$371,545</td>
                  </tr>
                  <tr>
                    <td>Closed Rate</td>
                    <td className="history_average_price">$371,545</td>
                    <td className="history_average_price">$371,545</td>
                  </tr>
                  <tr>
                    <td>Price Range</td>
                    <td className="history_average_price">$73K - $1.4M</td>
                    <td className="history_average_price">$73K - $1.4M</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </TabPanel>
        </Box>

        <div className="w-100">
          <RealtorPropertiesMap isMobileView={isMobileView}/>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(TransactionHistory);
