import React from "react";
import "./CookiePolicy.css";
import Footer from "../Footer/Footer";
import HelmetLayout from "../../Utils/HelmetLayout/Helmet";

export default function CookiePolicy() {
  return (
    <div>

      <HelmetLayout pageName={'cookie-policy'} canonicalLink={'cookie-policy'} />

      <div className="d-flex text-white container-fluid p-4 topContainer">
        <div>
          <h2 className="topHeading">Cookie Policy</h2>
        </div>
        <div>
          <p className="topHeadingPara">
            Cookies and similar technologies (such as HTML5 storage
            specifications, web beacons, and embedded scripts) are tiny data
            files that often contain an anonymous, unique identifier. These
            technologies gather information from your device and your
            interactions on our Platforms. This information may include the date
            and time of visits and how you use our Platforms. They enable our
            Platforms to recognize you during future visits from the same
            computer or mobile device, and to retain certain information about
            you. In this Policy, we use the term "cookies" to encompass all
            technologies that collect information in this manner.
          </p>
          <p className="topHeadingPara">
            The purpose of cookies is to enhance the efficiency and
            user-friendliness of our Platforms, analyze your usage of them, and
            support our promotional and marketing endeavors (including targeted
            and behavioral advertising). You have the option to decline cookies
            if your browser permits, but please be aware that this choice may
            impact your ability to use certain features of our Platforms and
            access specific content.
          </p>
          <p className="topHeadingPara">
            For further information about cookies and similar technologies, we
            recommend visiting the following resources: Your Online Choices, All
            About Cookies, and AboutCookies.org.
          </p>
        </div>
      </div>

      <div className="container content_contaner">
        <div>
          <h5 className="content_Heading1">Consent to the use of cookies</h5>
          <p className="contentPara">
            When you visit our Platforms, you acknowledge that we may store and
            retrieve essential cookies on your computer or mobile device.
            Essential cookies are crucial for the functioning of our Platforms,
            enabling basic features and ensuring their security. It's important
            to note that your consent isn't required for the storage and
            retrieval of essential cookies, as they are vital for the operation
            of our Platforms, and we have a legitimate business interest in
            using them. As a result, these cookies cannot be disabled.
          </p>
          <p className="contentPara">
            Additionally, by visiting our Platforms, you grant us permission to
            store and access specific non-essential cookies on your computer or
            mobile device. While these cookies aren't essential for the
            operation of our Platforms, they enhance functionality or gather
            additional insights about your usage.
          </p>
        </div>

        <div>
          <h5 className="content_Heading1">Why do we use cookies?</h5>
          <p className="contentPara">
            We're constantly striving to enhance our Platforms and deliver
            services that align with your interests. To achieve this, we employ
            various types of cookies for the following objectives:
          </p>

          <ol>
            <li>
              <p className="contentPara">
                Facilitating smooth navigation on our Platforms.
              </p>
            </li>
            <li>
              <p className="contentPara">
                Aiding in event registration, account creation and access,
                utilizing our services, and sharing feedback with us.
              </p>
            </li>
            <li>
              <p className="contentPara">
                Analyzing your interactions with our Platforms.
              </p>
            </li>
            <li>
              <p className="contentPara">
                Supporting our promotional and marketing endeavors, which
                include targeted advertising.
              </p>
            </li>
          </ol>
        </div>

        <div>
          <h5 className="content_Heading1">What types of cookies do we use?</h5>
          <p className="contentPara">
            We employ both first-party cookies (directly placed by us or our
            authorized service providers) and third-party cookies (placed by
            approved third-party partners). When third-party partners utilize
            such cookies, they are accountable for their use. We encourage you
            to refer to their cookie management policies and utilize their
            consent tools to set your preferences and opt out of information
            collection by these third-party partners during your visits to our
            Platforms.
          </p>
          <p className="contentPara">
            <strong>1. Strictly necessary cookies: </strong>
            These are vital for enabling you to navigate our Platforms, utilize
            fundamental functions, and ensure Platform security. They are
            typically set in response to actions like setting privacy
            preferences, logging in, or filling out forms. You can configure
            your browser to block or receive warnings about these cookies, but
            doing so may result in some parts of our Platforms not functioning.
          </p>
          <p className="contentPara">
            <strong>2. Functionality cookies: </strong>
            These enhance and personalize Platform functionalities. They
            remember your choices (e.g., username or recent real estate
            searches) and offer personalized services. By using these cookies,
            you're spared from entering certain information each time you log
            in. They can be activated by our teams or third parties whose
            services are utilized on our Platforms. Blocking these cookies may
            lead to certain features of the Platforms not working optimally.
          </p>
          <p className="contentPara">
            <strong>3. Performance cookies: </strong>
            These enable us to track visits and traffic sources, providing us
            with deeper insights into our Platforms' user demographics. They aid
            in identifying the most and least popular pages and in observing
            visitor movements within our Platforms. All data collected by these
            cookies is aggregated and hence, anonymous. If you opt out of these
            cookies, we won't have visibility into your visits, limiting our
            ability to enhance Platform performance.
          </p>
          <p className="contentPara">
            <strong>4. Targeting Cookies: </strong>
            These are typically third-party cookies placed by our advertising
            partners. They help in creating a profile of your interests,
            allowing us to display relevant ads on our Platforms and other
            sites. They also control ad frequency and gauge ad campaign
            effectiveness. These cookies remember your device's visits to the
            Platforms, providing insights into shopping behaviors to enhance our
            Platforms and offer tailored ads and offers. If you choose not to
            allow these cookies, you won't experience our targeted advertising
            on different websites.
          </p>
        </div>

        <div>
          <h5 className="content_Heading1">How can you block cookies?</h5>
          <p className="contentPara">
            If you wish, you have the option to withhold or revoke your consent
            for specific cookie categories (excluding strictly necessary
            cookies) by selecting the "Manage Cookies Settings" button below
          </p>
          <button className="ManageBtn">
            Manage Cookie
          </button>
          <p className="contentPara mt-3">
            You also have the option to adjust your web browser settings to
            either delete or prevent certain cookies from being stored on your
            computer. Most browsers provide a "Help" feature that guides users
            on how to block new cookies, receive notifications upon receiving a
            new cookie, or disable cookies altogether. Here are guides for some
            popular browsers:
          </p>
          <ul className="contentList">
            <li>
              <a
                className="HelpLink"
                target="blank"
                href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              >
                <b>Microsoft Edge</b>
              </a>
            </li>
            <li>
              <a
                className="HelpLink"
                href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
                target="blank"
              >
                <b>Mozilla Firefox</b>
              </a>
            </li>
            <li>
              <a
                className="HelpLink"
                href="https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DDesktop"
                target="blank"
              >
                <b>Google Chrome</b>
              </a>
            </li>
            <li>
              <a
                className="HelpLink"
                href="https://support.apple.com/en-us/HT201265"
                target="blank"
              >
                <b>Safari</b>
              </a>
            </li>
          </ul>
          <p className="contentPara">
            Depending on your browser, you can typically choose to "Block
            third-party cookies" in your settings if you want to accept only our
            cookies, but not those from third parties.
          </p>
          <p className="contentPara">
            Please be aware that if you choose to refuse or block cookies, some
            pages on our Platforms may not display properly, and certain
            features may become unavailable.
          </p>
          <p className="contentPara">
            For more information on configuring your browser preferences, you
            can also visit the following resources: Your Online Choices, All
            About Cookies, and AboutCookies.org.
          </p>
          <p className="contentPara">
            Additionally, you can block the use of specific third-party cookies
            by following the steps outlined in the links provided below:
          </p>
          <ul className="contentList">
            <li>
              <p className="contentPara">
                <strong>Google Analytics:</strong> If you wish to prevent Google
                Analytics from using your data, you can download the Google
                Analytics Opt-out Browser Add-on.
              </p>
            </li>
            <li>
              <p className="contentPara">
                <strong>Google AdWords: </strong> If you prefer not to
                participate in our Google AdWords remarketing, you can opt out
                by visiting Google’s Ads Preferences Manager.
              </p>
            </li>
            <li>
              <p className="contentPara">
                <strong>Facebook: </strong> If you do not want Facebook to use
                information based on your activity on websites or applications
                outside of Facebook for the purpose of serving advertisements,
                you can opt out in your Ads Preferences.
              </p>
            </li>
          </ul>
          <p className="contentPara">
            Furthermore, you can opt out of personalized advertising from
            third-party advertisers and advertising networks that are members of
            the Digital Advertising Alliance of Canada (DAAC) by visiting the
            DAAC Opt-Out Page. pt out in your Ads Preferences.
          </p>
          <p className="contentPara">
            Please note that even if you opt not to receive advertisements
            tailored to your interests, you may still see our advertisements.
            However, these will not be customized to your specific needs and may
            be displayed more frequently.
          </p>
        </div>

        <div>
          <h5 className="content_Heading1">Policy Updates</h5>
          <p className="contentPara">
            We reserve the right to make changes or additions to this Policy at
            any time. We recommend that you periodically review it for any
            updates. In the event of any modifications to this Policy, we will
            notify you by updating the effective date at the top of this page.
          </p>
        </div>

        <div>
          <h5 className="content_Heading1">Contact us</h5>
          <p className="contentPara">
            If you have any questions or concerns regarding this Policy, please
            feel free to reach out to us at privacy@assignmentSold.com. We're here to
            help!
          </p>
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
}
