import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

const formatNumber = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};



export default function RadiusRangeSlider(props) {
  const { rangeRadiusSliderValues, setRadiusRangeRadSliderValues } = props;

  const [stepValue, setStepValue] = useState(1);


  const handleChangeValue2 = (newValues) => {
    const newValue = Math.round(newValues);
    if (newValue < 50) {
          setStepValue(1);
        } else if (newValue < 100) {
          setStepValue(3);
        } else if (newValue < 200) {
          setStepValue(5);
        } else if (newValue < 300) {
          setStepValue(7);
        } else if (newValue < 400) {
          setStepValue(10);
        } else if (newValue < 500) {
          setStepValue(13);
        } else if (newValue < 600) {
          setStepValue(17);
        } else if (newValue < 700) {
          setStepValue(20);
        } else if (newValue < 800) {
          setStepValue(30);
        } else if (newValue < 900) {
          setStepValue(35);
        } else if (newValue < 1000) {
          setStepValue(50);
        }
        setRadiusRangeRadSliderValues({value1: 1, value2: newValue});
  };

  const formatValue = (value) => {
    return formatNumber(value) + " Km";
  };


  return (
    <Box sx={{ width: "100%" }}>
        <Nouislider
          range={{
            min: [1],
            "50%": [50],
            max: [1000],
          }}
        start={[rangeRadiusSliderValues?.value2]}
        step={stepValue}
        connect="lower"
        onChange={handleChangeValue2}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between",marginTop:'3px'}}>
        <Typography variant="caption" id="input-slider" sx={{ color: "#F6941C", fontSize: ".7em", fontWeight: "bold" }}>
          {formatValue(rangeRadiusSliderValues?.value2)}
        </Typography>
      </Box>
    </Box>
  );
}
