import React, { useState, memo } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ListingCarousel from "./Carousel";
import { PropertyData } from "../../PropertData";
import MoreSimilarListing from "../MoreSimilarListings";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ListingComparableIcon } from "../../../../SvgIcons/Icons";

const TabPanel = memo((props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
});

const ListingComparable = ({
  setIsSignUpModalOpen,
  isUserlogin,
  setSignUpModalHeading,
  isMobileView,
}) => {
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const OPTIONS = { slidesToScroll: "auto" };

  const ListingRentSoldTabs = ["For Sale", "For Rent"];

  const ListingSoldTabDataMapping = {
    0: PropertyData.forSale || [],
    1: PropertyData.forRent || [],
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion PropertyDetails_MobileAccordion Property_simpleAccordion MobileAccordion_BottomBorder"
      sx={{ background: "#fff" }}
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex align-items-center gap-2" : ""}>
          {isMobileView && <ListingComparableIcon />}
          <h2 className="property_Details_heading">Listing Comparable</h2>
          {!isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
              Easily compare similar listings to find the best value for your
              dream home.
            </p>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isMobileView && (
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
            Easily compare similar listings to find the best value for your
            dream home.
          </p>
        )}
        <div className="w-100">
          <Box className="mt-3">
            <AppBar position="static" sx={{ boxShadow: "none" }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                textColor="inherit"
                sx={{ backgroundColor: "#fff" }}
                className="Property_details_tabs"
                elevation={0}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                {ListingRentSoldTabs.map((tab, index) => (
                  <Tab
                    key={index}
                    label={tab}
                    sx={{
                      border: "1px solid #CCCCCC",
                      fontWeight: "700",
                      padding: "15px 30px",
                    }}
                  />
                ))}
              </Tabs>
            </AppBar>
            {ListingRentSoldTabs.map((tab, index) => (
              <TabPanel
                key={index}
                value={value}
                index={index}
                className="PropertyDetail_tabPanel position-relative"
              >
                <div className="mt-2">
                  {isUserlogin ? (
                    <>
                      {ListingSoldTabDataMapping[index].length > 0 ? (
                        <ListingCarousel
                          slides={ListingSoldTabDataMapping[index]}
                          options={OPTIONS}
                        />
                      ) : (
                        <div>No data available for {tab}</div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <div className="position-absolute d-flex align-items-center justify-content-center w-100 h-100 top-0 left-0">
                        <button
                          className="PropertyDetails_Mbl_f14 Prop_loginReqBtn"
                          onClick={() => {
                            setIsSignUpModalOpen(true);
                            setSignUpModalHeading(
                              "Join to Unlock Comparable Listings!"
                            );
                          }}
                        >
                          Login Required
                        </button>
                      </div>
                      <div
                        style={{
                          width: "992px",
                          overflow: "hidden",
                          height: "425px",
                        }}
                      >
                        <img
                          src="/images/listingBlur.png"
                          width={"100%"}
                          style={{ height: "100%" }}
                          loading="lazy"
                          alt="blurListings"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </TabPanel>
            ))}
          </Box>

          <div className="">
            <MoreSimilarListing
              isUserlogin={isUserlogin}
              setIsSignUpModalOpen={setIsSignUpModalOpen}
              setSignUpModalHeading={setSignUpModalHeading}
            />
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(ListingComparable);
