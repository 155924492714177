import { createSlice } from "@reduxjs/toolkit";

const PdfDownloadSlice = createSlice({
  name: "PdfDataSlice",
  initialState: [],
  reducers: {
    addPdfData: (state, action) => {
          state.push(action.payload);
    },
    removePdfData: () => {
      return [];
    },
  
  },
});
export const { addPdfData, removePdfData} = PdfDownloadSlice.actions;
export default PdfDownloadSlice.reducer;