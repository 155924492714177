import { createSlice } from "@reduxjs/toolkit";

const adminSiteDataSlice = createSlice({
  name: "adminSiteDataSlice",
  initialState: [],
  reducers: {
    addAdminWebData: (state, action) => {
          state.push(action.payload);
    },
    removeAdminWebData: () => {
      return [];
    },
  
  },
});
export const { addAdminWebData, removeAdminWebData} = adminSiteDataSlice.actions;
export default adminSiteDataSlice.reducer;