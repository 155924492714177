import React, { useEffect, useState } from "react";
import DashboardLayout from "../../Dashboard";
import Footer from "../../Footer/footer";
import axios from "axios";
import baseURL from "../../../config";
import Cookies from "universal-cookie";
import RefinedEmails from "./RefinedEmails";
import "./emailInbox.css";
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";

const EmailInboxes = () => {
  const [loading, setloading] = useState(true);
  const [data, setData] = useState([]);
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const [mailInbox, setMailInbox] = useState("");
  const FetchData = () => {
    setloading(true);
    const headers = {
      Authorization: `Bearer ${jwt_token}`,
    };

    axios
      .get(`${baseURL}/api/Admin/GetEmails`, { headers })
      .then((response) => {
        const mails = response?.data?.data || [];
        setData(mails);
        setloading(false);
      })
      .catch((error) => {
        console.error(error);
        setloading(false);
      });
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };
  const formatTime = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };
  useEffect(() => {
    FetchData();
    // eslint-disable-next-line
  }, []);
  const handlemail = (mail) => {
    setMailInbox(mail);
  };
  return (
    <DashboardLayout>
      <section className="page-content mt-4 mb-2">
        {loading ? (
          <div className="text-center">
            <RotatingLines strokeColor="grey" />
          </div>
          
        ) : (
          <>
            {mailInbox ? ( 
              <><RefinedEmails setMailInbox={setMailInbox} mailInbox={mailInbox}/></>
            ) : (
              <>
                <div className="row row-cols-1 mt-5 row-cols-md-3 g-3 MobilE_____Margins">
                  {data?.map((x) => (
                    <div
                      onClick={() => handlemail(x.email)}
                      className="col car__13"
                    >
                      <p> {x.email}</p>
                      <p> {formatDate(x.createdAt)}</p>
                      <p> {formatTime(x.createdAt)}</p>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
        <ToastContainer />
      </section>
      <Footer />
    </DashboardLayout>
  );
};

export default EmailInboxes;
