import React from "react";
import CustomOffer from '../../../../images/PriceTabimages/custom.png'
import { useNavigate } from "react-router-dom";

export default function WorkingWithAnAgent({isUserLogIn}) {


  const navigate = useNavigate()
  return (

    <div className="py-5">
      <div className="d-flex coloring ">
        <div className="custom_offer_img_circle">
          <img src={CustomOffer} alt='customOffer' loading="lazy"/>
        </div>
        <div className="ps-5 Buyer_fullWwidthTabs">
          <h2>Using a real estate agent</h2>

          <ul>
            <li>Our platform makes it easy for buyers and sellers to connect directly (without agents).</li>
            <li>
              Some buyers choose to work directly with our partner agents who can help with offers, showings, paperwork and more! Connecting with them is easy and free.
            </li>
          </ul>
        </div>
      </div>
      <div className="text-center">
      <button className={"Buyer_fullWidthBtn"} onClick={()=>navigate('/login')} disabled={isUserLogIn}>{isUserLogIn ? 'Registered' : 'Register / JoinNow'}</button>
      </div>
    </div>

  );
}
