import React, { useEffect } from 'react'
import "../main.css"
import { RxHome } from 'react-icons/rx';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import baseURL from '../../config';
import Cookies from 'universal-cookie';
import { removeItem } from '../../ReduxStore/Slices/LoginSlice';
import { removeProfileData } from '../../ReduxStore/Slices/ProfileDataSlice';
import { addUserProfileData, removeUserProfileData } from '../../ReduxStore/Slices/UserProfileData';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import axios from 'axios';
import { IoIosArrowDown } from 'react-icons/io';
import jwtDecode from 'jwt-decode';
const Navbar = () => {
    const userDataSlice = useSelector((state) => state.userProfileDataSlice[0]);
    // const [searchQuery, setSearchQuery] = useState('');
    // const [data, setdata] = useState();
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const jwt_token = cookies.get("jwt_authorization");
    const navigate = useNavigate();
    const decode = jwtDecode(jwt_token);
    const UserRole = decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    const fetchCurrentUserData = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${jwt_token}`,
            };
            const response = await axios.get(
                `${baseURL}/api/User-Data/GetCurrentUser`,
                { headers }
            );
            const responseData = response?.data?.data;
            dispatch(addUserProfileData(responseData));
            // setdata(responseData)
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {

        if (!userDataSlice) {
            fetchCurrentUserData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDataSlice]);

    const handleLogout = () => {
        cookies.remove("jwt_authorization", { path: "/" });
        localStorage.removeItem('fullName')
      localStorage?.removeItem('lastName')
      localStorage.removeItem('email')
      localStorage?.removeItem('phoneNumber')
        localStorage.setItem('trackVariable', '0');
        dispatch(removeItem());
        dispatch(removeProfileData());
    sessionStorage.setItem('ChatId', '')
        localStorage.setItem("anonymous", 0);
        dispatch(removeUserProfileData());
        navigate("/")
        window.location.reload();
    };

    return (
        <div className='page-content navheight' >
            <section className="search-and-user justify-content-between" style={{ marginBottom: "0", height: "-webkit-fill-available" }}>
                <div>
                    <h3 className='fw-bold text-primary Admin_portal_heading' >
                        <AdminPanelSettingsIcon className='fs-1' />  Admin Portal
                    </h3>
                </div>

                <div className='d-flex aligncenter '>
                    <NavLink className="nonedecor" to="/">
                        <Button className="bton me-4">
                            <RxHome /> {" "}   Home
                        </Button>
                    </NavLink>

                    <ul className="navbar-nav   mb-2 mb-lg-0">

                        <li className="nav-item pl-5 dropdown d-flex">

                            <button
                                className="nav-link dropdown-toggle btn-link d-flex aligncenter"
                                // role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <IoIosArrowDown className="down-icon" />

                                <img
                                    className="pr-img"
                                    src={
                                        userDataSlice?.imageUrl
                                            ? userDataSlice.imageUrl.startsWith('https://lh3.googleusercontent.com')
                                                ? userDataSlice.imageUrl // Google image URL
                                                : `${baseURL}/${userDataSlice.imageUrl}` // Backend image URL
                                            : require("../../images/ProfileAvatar.jpg") // Default image if no URL is provided
                                    }
                                    alt="User Profile"
                                    loading="lazy"
                                />

                            </button>

                            <ul className="dropdown-menu  ">
                                <li className="">
                                    <NavLink className="nonedecor" to="/dashboard/profile">
                                        <button className="dropdown-item">Profile</button>
                                    </NavLink>
                                </li>
                                {
                                    UserRole === "Seller" || UserRole === "Staff" || UserRole === "Buyer" ?
                                    <li className="">
                                    <NavLink className="nonedecor" to="/dashboard/favourite">
                                        <button className="dropdown-item">Favourites</button>
                                    </NavLink>
                                </li>
                                        : null
                                }
                                {
                                    UserRole === "Seller" || UserRole === "Staff" || UserRole === "Buyer" ?
                                    <li className="">
                                    <NavLink className="nonedecor" to="/dashboard/saved-search">
                                        <button className="dropdown-item">Saved Searches</button>
                                    </NavLink>
                                </li>
                                        : null
                                }
                                {
                                    UserRole === "Seller" || UserRole === "Staff" || UserRole === "Marketing" ?
                                        <li className="">
                                            <NavLink className="nonedecor" to="/dashboard/assignments-listing/Active">
                                                <button className="dropdown-item">Listings</button>
                                            </NavLink>
                                        </li>
                                        : null
                                }
                                {
                                    UserRole === "Seller" || UserRole === "Staff" ?
                                        <li className="">
                                            <NavLink className="nonedecor" to="/dashboard/order-summary/Active">
                                                <button className="dropdown-item">My order</button>
                                            </NavLink>
                                        </li>
                                        : null
                                }
                                {/* <li className="">
                                    <NavLink className="nonedecor" to="/dashboard/Messages">
                                        <button className="dropdown-item">Messages</button>
                                    </NavLink>
                                </li> */}


                                <li className="">
                                    <NavLink className="nonedecor" to="/dashboard/Contacts">
                                        <button className="dropdown-item">Contacts</button>
                                    </NavLink>
                                </li>

                                <li>
                                    <button className="dropdown-item" onClick={handleLogout}>Log Out</button>
                                </li>

                            </ul>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    )
}

export default Navbar