import React from "react";
import "../../Components.css";
import "./ResetPassword.css";
import { TextField } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import baseURL from "../../config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [timer, setTimer] = useState(60);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLinkSent, setIsLinkSent] = useState(false);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const startTimer = () => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(intervalId);
          setIsButtonDisabled(false);
          setIsLinkSent(false);
          return 60;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  
  const sendResetLink = async () => {
    setIsButtonDisabled(true);
  
    if (isValidEmail && email.length > 0) {
      try {
        const response = await axios.get(
          `${baseURL}/api/PasswordResetCode/${email}`
        );
        if (response?.data?.result === 1) {
          startTimer();
          setIsLinkSent(true)
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.error);
          setIsButtonDisabled(false);
        }
        setIsSubmitted(false);
      } catch (error) {
        toast.error("Try Again");
        setIsButtonDisabled(false);
        setIsSubmitted(false);
      }
    } else {
      setIsValidEmail(false);
      setIsButtonDisabled(false);
    }
  };
  
  return (
    <div className="container centered  aligning reset_container">
      <div className="imagecentered">
      <NavLink to="/">
        <img
          className="imgclass1"
          src={require("../../images/New__LOGO.png")}
          alt="AssignmentSold Logo"
          loading="lazy"
        />
        </NavLink>
      </div>
      <div>
        {isLinkSent ? (
          <>
            <p className="font m-0 resetheading justifycenter reset_heading">
              Link has been sent to your Email
            </p>
            <p className=" m-0 font textheading justifycenter">
              {`Check ${email} to reset your password`}
            </p>
          </>
        ) : (
          <>
            <p className="font  m-0 resetheading justifycenter reset_heading">
              Reset Your Password
            </p>
            <p className=" m-0 font textheading justifycenter">
              Enter your email to reset your password
            </p>
            <p className="font fieldhead m-0 mt-2">
              <strong>Email</strong>
            </p>
            <TextField
              sx={{ width: "386px", borderRadius: "4px", marginBottom: "20px" }}
              className="fieldsettings reset_fieldSetting"
              id="outlined-basic"
              type="email"
              label={<span className="font">Enter Email Address</span>}
              variant="outlined"
              onChange={handleEmailChange}
            />
            {isSubmitted && !isValidEmail ? (
              <span style={{ color: "red",display:'block' }}>
                Invalid email. Please enter a valid email address.
              </span>
            ) : null}
          </>
        )}
      </div>
      <button
        className="Resetbutton reset_button mb-3 mt-3"
        onClick={() => {
          sendResetLink();
          setIsSubmitted(true);
        }}
        disabled={isButtonDisabled}
      >
        {isButtonDisabled
          ? `You can get a new Link in ${timer} seconds`
          : "Send Reset Link"}
      </button>
      {!isButtonDisabled ? (
        <NavLink to="/login">
          <p className="back2login">Back to Login</p>
        </NavLink>
      ) : null}

      <ToastContainer />
    </div>
  );
}
