import AgentCardStructure from "./AgentCardStructure";
import AgentCarddata from "./AgentCardData";
import "@fontsource/montserrat";
import "../../../../App.css";

function AgentCard() {
  return (
    <div className="agentCard_main">
        {AgentCarddata.map((val, index) => (
          <div key={index} className="p-0 mb-4 agent_card">
            <AgentCardStructure
              name={val.name}
              price={val.price}
              city={val.city}
              num={val.number}
              totalsale={val.totalsales}
              trsale={val.torrontosales}
              pricerange={val.pricerange}
              imgsrc={val.imgsrcs}
            />
          </div>
        ))}
    </div>
  );
}

export default AgentCard;
