import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
    name: "Login",
    initialState: 0,
    reducers: {
      addItem: (state, action) => {
        // Instead of modifying state directly with push, return a new state
        return state + action.payload; // Assuming action.payload is a number
      },
      removeItem: () => {
        return 0;// Assuming action.payload is a number
      },
    },
  });
export const { addItem, removeItem } = loginSlice.actions;
export default loginSlice.reducer;