import React, { useState, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  AvailablityRatingIcon,
  HomePrepIcon,
  InvestmentAdviceIcon,
  KnowledgeRatingIcon,
  LeadGenerationIcon,
  MarketReachIcon,
  NegotiationRatingIcon,
  OfficialTick,
  OverAllValuesIcon,
  ProfessioalismRatingIcon,
  PropertyBuyingIcon,
  PropertyKnowledgeIcon,
  PropertySellingIcon,
  RealtorExperienceIcon,
  ResponsivenessRatingIcon,
  ReviewsSummaryIcon,
  StagingConsultantIcon,
  StarIcon,
  ValueOfServiceIcon,
  WatchingNeighbourIcon,
} from "../../../SvgIcons/Icons";

const ReviewsSummary = ({ isMobileView, data }) => {
  const [expanded, setExpanded] = useState(isMobileView ? false : true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <RemoveIcon className="Property_minusWebGreyIcon" />
          ) : (
            <AddIcon className="Property_plusWebGreyIcon" />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex flex-column" : ""}>
          <div className="d-flex align-items-center gap-2">
            {isMobileView && (
              <ReviewsSummaryIcon color={"#282828"} size={"20px"} />
            )}
            <h2 className="property_Details_heading">Review's Summary</h2>
          </div>
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
            A concise overview of reviews, highlighting key feedback on
            experiences, satisfaction, and recommendations. Get a snapshot of
            what clients value most
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {/* Reviews Start */}
        <div className="row mt-3 mx-0 w-100">
          <div className="col-md-3 col-6 py-2 ps-0 pe-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{ borderColor: "#F4ECFF", background: "#E4D2FF" }}
              >
                <StarIcon color={"#653EBE"} />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.97
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Total Deals Closed
              </p>
            </div>
          </div>

          <div className="col-md-3 col-6 py-2 px-1 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon PropertyDetails_Mbl_f14"
                style={{
                  borderColor: "#DFF4FF",
                  background: "var(--Light-blue, #BDDFFF)",
                }}
              >
                <OverAllValuesIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.97
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Overall Values
              </p>
            </div>
          </div>

          <div className="col-md-3 col-6 py-2 px-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{ borderColor: "#F4ECFF", background: "#E4D2FF" }}
              >
                <StarIcon color={"#653EBE"} />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.97
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Average Rating
              </p>
            </div>
          </div>

          <div className="col-md-3 col-6 py-2 pe-0 ps-1 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#ECFDF3",
                  background: "#D1FADF",
                }}
              >
                <ValueOfServiceIcon color={"#713F12"} />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                5.0
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Value of Service
              </p>
            </div>
          </div>

          <div className="col-md-4 col-6 py-2 ps-0 pe-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#DFF4FF",
                  background: "var(--Light-blue, #BDDFFF)",
                }}
              >
                <RealtorExperienceIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.95
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Experience</p>
            </div>
          </div>

          <div className="col-md-4 col-6 py-2 px-1 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon PropertyDetails_Mbl_f14"
                style={{
                  borderColor: "#FFFCDF",
                  background: "#FFF7A0",
                }}
              >
                <ResponsivenessRatingIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                5.0
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Responsiveness Rating
              </p>
            </div>
          </div>

          <div className="col-md-4 col-6 py-2 pe-0 ps-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#ECFDF3",
                  background: "#D1FADF",
                }}
              >
                <ProfessioalismRatingIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.95
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Professionalism Rating
              </p>
            </div>
          </div>

          <div className="col-md-3 col-6 py-2 ps-0 pe-1 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{ borderColor: "#F4ECFF", background: "#E4D2FF" }}
              >
                <MarketReachIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.97
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Market Reach</p>
            </div>
          </div>

          <div className="col-md-3 col-6 py-2 px-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon PropertyDetails_Mbl_f14"
                style={{
                  borderColor: "#DFF4FF",
                  background: "var(--Light-blue, #BDDFFF)",
                }}
              >
                <LeadGenerationIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.95
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Lead Generation
              </p>
            </div>
          </div>

          <div className="col-md-3 col-6 py-2 px-1 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{ borderColor: "#ECFDF3", background: "#D1FADF" }}
              >
                <PropertySellingIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.95
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Property Selling
              </p>
            </div>
          </div>

          <div className="col-md-3 col-6 py-2 pe-0 ps-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{ borderColor: "#F4ECFF", background: "#E4D2FF" }}
              >
                <PropertyBuyingIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.97
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Property Buying
              </p>
            </div>
          </div>

          <div className="col-md-4 col-6 py-2 ps-0 pe-1 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{ borderColor: "#ECFDF3", background: "#D1FADF" }}
              >
                <NegotiationRatingIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.97
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Negotiation Rating
              </p>
            </div>
          </div>

          <div className="col-md-4 col-6 py-2 px-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon PropertyDetails_Mbl_f14"
                style={{
                  borderColor: "#FFFCDF",
                  background: "#FFF7A0",
                }}
              >
                <StarIcon color={"#713F12"} />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.97
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Client-referred
              </p>
            </div>
          </div>

          <div className="col-md-4 col-6 py-2 pe-0 ps-1 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#DFF4FF",
                  background: "var(--Light-blue, #BDDFFF)",
                }}
              >
                <OfficialTick color={"#0253A0"} size={"24"} />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.95
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Success Rate</p>
            </div>
          </div>

          <div className="col-md-3 col-6 py-2 ps-0 pe-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#DFF4FF",
                  background: "var(--Light-blue, #BDDFFF)",
                }}
              >
                <KnowledgeRatingIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.97
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Knowledge Rating
              </p>
            </div>
          </div>

          <div className="col-md-3 col-6 py-2 px-1 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon PropertyDetails_Mbl_f14"
                style={{
                  borderColor: "#ECFDF3",
                  background: "#D1FADF",
                }}
              >
                <PropertyKnowledgeIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                5.0
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Prop. knowledge
              </p>
            </div>
          </div>

          <div className="col-md-3 col-6 py-2 px-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{ borderColor: "#F4ECFF", background: "#E4D2FF" }}
              >
                <MarketReachIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.98
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Market Knowledge
              </p>
            </div>
          </div>

          <div className="col-md-3 col-6 py-2 pe-0 ps-1 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#DFF4FF",
                  background: "var(--Light-blue, #BDDFFF)",
                }}
              >
                <AvailablityRatingIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                5.0
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Value of Service
              </p>
            </div>
          </div>

          <div className="col-md-4 col-6 py-2 ps-0 pe-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#ECFDF3",
                  background: "#D1FADF",
                }}
              >
                <HomePrepIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.97
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Home Prep</p>
            </div>
          </div>

          <div className="col-md-4 col-6 py-2 px-1 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon PropertyDetails_Mbl_f14"
                style={{ borderColor: "#F4ECFF", background: "#E4D2FF" }}
              >
                <StagingConsultantIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                4.97
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Staging Consult
              </p>
            </div>
          </div>

          <div className="col-md-4 col-6 py-2 pe-0 ps-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#DFF4FF",
                  background: "var(--Light-blue, #BDDFFF)",
                }}
              >
                <InvestmentAdviceIcon />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                5.0
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Invesment Advice
              </p>
            </div>
          </div>

          <div className="col-md-12 col-6 py-2 px-0 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#FFFCDF",
                  background: "#FFF7A0",
                }}
              >
                <WatchingNeighbourIcon color={"#713F12"} />
              </div>
              <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                5.0
              </h6>
              <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                Invesment Advice
              </p>
            </div>
          </div>
        </div>
        {/* Reviews End */}

        {/* KeyStats Start */}
        <div className="mt-4">
          <h2 className="property_Details_heading">Key Stats</h2>
          <p className=" mt-2 mb-1 fw-bold color_Primary_Natural_blue">
            Hired on assignmentsold.ca 88 times
          </p>
          <p>333 reviews in 18 cities and 41 neighborhoods.</p>
          <div
            className="w-100 py-2 PropDetails_HideScrollBar position-relative"
            style={{ overflowX: "auto" }}
          >
            <table
              className="Property_floorPlan_table Retailer_history_table w-100"
              style={{ minWidth: "100%" }}
            >
              <thead className="w-100">
                <tr className="w-100">
                  <th
                    style={{
                      width: "50%",
                      borderTopLeftRadius: "16px",
                    }}
                  >
                    City Name
                  </th>
                  <th style={{ width: "50%", borderTopRightRadius: "16px" }}>
                    Reviews
                  </th>
                </tr>
              </thead>
              <tbody style={{ borderBottom: "1px solid #d9d9d9" }}>
                <tr>
                  <td>1st in Montreal, QC</td>
                  <td className="history_average_price">296</td>
                </tr>
                <tr>
                  <td>1st in Terrebonne, QC </td>
                  <td className="history_average_price">7</td>
                </tr>
                <tr>
                  <td>13th in Laval, QC</td>
                  <td className="history_average_price">6</td>
                </tr>
                <tr>
                  <td>2nd in Quebec, QC</td>
                  <td className="history_average_price">4</td>
                </tr>
                <tr>
                  <td>2nd in Saint Jerome,</td>
                  <td className="history_average_price">3</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="w-100 py-2 PropDetails_HideScrollBar position-relative"
            style={{ overflowX: "auto" }}
          >
            <table
              className="Property_floorPlan_table Retailer_history_table w-100"
              style={{ minWidth: "100%" }}
            >
              <thead className="w-100">
                <tr className="w-100">
                  <th
                    style={{
                      width: "50%",
                      borderTopLeftRadius: "16px",
                    }}
                  >
                    City Name
                  </th>
                  <th style={{ width: "50%", borderTopRightRadius: "16px" }}>
                    Reviews
                  </th>
                </tr>
              </thead>
              <tbody style={{ borderBottom: "1px solid #d9d9d9" }}>
                <tr>
                  <td>1st in Montreal, QC</td>
                  <td className="history_average_price">296</td>
                </tr>
                <tr>
                  <td>1st in Terrebonne, QC </td>
                  <td className="history_average_price">7</td>
                </tr>
                <tr>
                  <td>13th in Laval, QC</td>
                  <td className="history_average_price">6</td>
                </tr>
                <tr>
                  <td>2nd in Quebec, QC</td>
                  <td className="history_average_price">4</td>
                </tr>
                <tr>
                  <td>2nd in Saint Jerome,</td>
                  <td className="history_average_price">3</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* KeyStats End */}

        {/* Track Record Start */}
        <div className="mt-4">
          <h2 className="property_Details_heading">Track Record</h2>
          <p className=" mt-2 mb-1 fw-bold color_Primary_Natural_blue">
            3 Years in top 10
          </p>
          <div
            className="w-100 py-2 PropDetails_HideScrollBar position-relative"
            style={{ overflowX: "auto" }}
          >
            <table
              className="Property_floorPlan_table Retailer_history_table w-100"
              style={{ minWidth: "100%" }}
            >
              <thead className="w-100">
                <tr className="w-100">
                  <th
                    style={{
                      width: "50%",
                      borderTopLeftRadius: "16px",
                    }}
                  >
                    City Name
                  </th>
                  <th style={{ width: "50%", borderTopRightRadius: "16px" }}>
                    Year
                  </th>
                </tr>
              </thead>
              <tbody style={{ borderBottom: "1px solid #d9d9d9" }}>
                <tr>
                  <td>1st in Montreal, QC</td>
                  <td className="history_average_price">2023</td>
                </tr>
                <tr>
                  <td>1st in Terrebonne, QC </td>
                  <td className="history_average_price">2022</td>
                </tr>
                <tr>
                  <td>13th in Laval, QC</td>
                  <td className="history_average_price">2021</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="mt-3 mb-1 fw-bold color_Primary_Natural_blue">
            3 Years in Top 100 in Canada
          </p>
          <div
            className="w-100 py-2 PropDetails_HideScrollBar position-relative"
            style={{ overflowX: "auto" }}
          >
            <table
              className="Property_floorPlan_table Retailer_history_table w-100"
              style={{ minWidth: "100%" }}
            >
              <thead className="w-100">
                <tr className="w-100">
                  <th
                    style={{
                      width: "50%",
                      borderTopLeftRadius: "16px",
                    }}
                  >
                    City Name
                  </th>
                  <th style={{ width: "50%", borderTopRightRadius: "16px" }}>
                    Year
                  </th>
                </tr>
              </thead>
              <tbody style={{ borderBottom: "1px solid #d9d9d9" }}>
                <tr>
                  <td>1st in Montreal, QC</td>
                  <td className="history_average_price">2023</td>
                </tr>
                <tr>
                  <td>1st in Terrebonne, QC </td>
                  <td className="history_average_price">2022</td>
                </tr>
                <tr>
                  <td>13th in Laval, QC</td>
                  <td className="history_average_price">2021</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* Track Record End */}
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(ReviewsSummary);
