import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import WhatsInclude from "./Subcomponents/WhatsInclude";
import Pricemyhouse from "./Subcomponents/Pricemyhouse";
import Box from "@mui/material/Box";
import "./Tabs.css";
import Showmyhouse from "./Subcomponents/Showmyhouse";
import OffersandNegotiations from "./Subcomponents/OffersandNegotiations";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box>
      <AppBar position="static" sx={{
        boxShadow: "none",
        // border: "1px solid #CCCCCC",
      }}>

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor={isMobile ? "none" : "secondary"}
          textColor="inherit"
          variant={isMobile ? "scrollable" : "standard"}
          aria-label="full width tabs example"
          sx={{
            backgroundColor: "rgb(250, 250, 250)",
            boxShadow: "",
            // border: "1px solid #CCCCCC",
          }}
          elevation={0}
        >
          <Tab
            label="What’s Include"
            {...a11yProps(0)}
            sx={{
              fontSize: "13px",
              border: "1px solid #CCCCCC",
              fontWeight: "700",
              padding: "15px 30px"
            }}
          />
          <Tab
            label="How do i price my house?"
            {...a11yProps(1)}
            sx={{
              fontSize: "11px",
              border: "1px solid #CCCCCC",
              fontWeight: "700",
              padding: "15px 30px"
            }}
          />
          <Tab
            label="Who will show my house?"
            {...a11yProps(2)}
            sx={{
              fontSize: "12px",
              border: "1px solid #CCCCCC",
              fontWeight: "700",
              padding: "15px 30px"
            }}
          />
          <Tab
            label="What about offers and negotiations?"
            {...a11yProps(3)}
            sx={{ fontSize: "12px", border: "1px solid #CCCCCC", fontWeight: "700", padding: "15px 30px" }}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <WhatsInclude />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Pricemyhouse />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Showmyhouse />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <OffersandNegotiations />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
