


const SellingWithUsAdvCard = ({data}) => {
    return (
        <div className="SellingAdv_card">
            <div className="text-center">
               <img src={require(`../../../images/${data?.imgUrl}`)} alt={data?.heading} loading="lazy"/>
            </div>
            <h3 className="sellerAdv_cardHeading text-center">{data?.heading}</h3>
            <p className="sellerAdv_cardDes text-center">{data?.description}</p>
        </div>
    )
}

export default SellingWithUsAdvCard;