import React from 'react'
import '../main.css'
// import HomeIcon from '@mui/icons-material/Home';
import EqualizerIcon from '@mui/icons-material/Equalizer'
// import MoveToInboxIcon from '@mui/icons-material/MoveToInbox'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import FilterIcon from '@mui/icons-material/Filter'
import ViewListIcon from '@mui/icons-material/ViewList'
import FavoriteSharpIcon from '@mui/icons-material/FavoriteSharp'
import SaveSharpIcon from '@mui/icons-material/SaveSharp'
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp'
import HighlightAltIcon from '@mui/icons-material/HighlightAlt'
import { NavLink, useLocation } from 'react-router-dom'
// import { backMessage } from '../../ReduxStore/Slices/BackToMessage'
import PeopleIcon from '@mui/icons-material/People'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import jwtDecode from 'jwt-decode'
import Cookies from 'universal-cookie'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
const Sidebar = () => {
  // const profileData = useSelector((state) => state.profileDataSlice[0]?.data);
  const cookies = new Cookies()
  const location = useLocation()
  const jwt_token = cookies.get('jwt_authorization')
  const decode = jwtDecode(jwt_token)
  const UserRole =
    decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
  let isActive = true
  // const emailConfirmedStr = decode['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous'];
  // const emailConfirmed = emailConfirmedStr === "True";
  // if (emailConfirmed === false) isActive = false;

  const handleLinkClick = (event, path) => {
    if (!isActive) {
      event.preventDefault()
    }
    if (path !== '/dashboard/Messages') {
      sessionStorage.removeItem('ChatId')
    }
    // if (path === '/dashboard/Messages') {
    //   dispatch(backMessage())
    // }
  }

  return (
    <div>
      <header className='page-header'>
        <nav>
          <NavLink
            to={isActive === true ? '/' : '/'}
            onClick={handleLinkClick}
            aria-label='forecastr logo'
            className='logo'
          >
            <img
              className='logo'
              src={require('../../Components/Images/icon.png')}
              alt='AssignmentSold Logo'
              loading="lazy"
            />
          </NavLink>
          <ul className='admin-menu'>
            {/* <li className='mt-2  lh-1'>
                            <NavLink to="/dashboard/home" activeClassName="active">
                                <HomeIcon />
                                <span>Dashboard</span>
                            </NavLink>
                        </li> */}
            <li className='mt-2 lh-1'>
              <NavLink
                to={
                  isActive === true
                    ? '/dashboard/profile'
                    : '/dashboard/profile'
                }
                className={isActive ? '' : 'disable-link'}
                activeclassname='active'
                onClick={event => handleLinkClick(event, '/dashboard/profile')}
              >
                <AccountCircleSharpIcon />
                <span>Profile</span>
              </NavLink>
            </li>
            {UserRole === 'Seller' || UserRole === 'Staff' || UserRole === 'Marketing' ? (
              <li className='mt-2 lh-1'>
                <NavLink
                  to={
                    isActive === true
                      ? '/dashboard/assignments-listing/Active'
                      : '/dashboard/profile'
                  }
                  onClick={event =>
                    handleLinkClick(event, '/dashboard/assignments-listing/Active')
                  }
                  className={`${isActive ? '' : 'disable-link'} ${(location.pathname === '/dashboard/assignments-listing/Suspended' || location.pathname === '/dashboard/assignments-listing/Pending') ? 'active' : ''}`}
                  activeclassname='active'
                >
                  <ViewListIcon />
                  <span>Listing and Verification</span>
                </NavLink>
              </li>
            ) : null}

            {UserRole === 'Marketing' && (
              <li className='mt-2 lh-1'>
                <NavLink
                  to={'/submit-assignment'}
                  activeclassname='active'
                  className={isActive ? '' : 'disable-link'}
                >
                  <ViewListIcon />
                  <span>Add Listing</span>
                </NavLink>
              </li>
            )}

            {(UserRole === 'Seller' || UserRole === 'Staff') && (
              <li className='mt-2 lh-1'>
                <NavLink
                  to={
                    isActive === true
                      ? '/dashboard/order-summary/Active'
                      : '/dashboard/profile'
                  }
                  activeclassname='active'
                  className={`${isActive ? '' : 'disable-link'} ${(location.pathname === '/dashboard/order-summary/PreviousOrder' || location.pathname === '/dashboard/order-summary/Pending') ? 'active' : ''}`}
                  onClick={event => handleLinkClick(event, '/dashboard/stats')}
                >
                  <ArticleOutlinedIcon />
                  <span>My Order</span>
                </NavLink>
              </li>
            )}

            {/* {UserRole === 'Seller' ||
            UserRole === 'SuperAdmin' ||
            UserRole === 'Staff' ||
            UserRole === 'Buyer' ? (
              <li className='mt-2 lh-1'>
                <NavLink
                  to={
                    isActive === true
                      ? '/dashboard/Messages'
                      : '/dashboard/profile'
                  }
                  onClick={event =>
                    handleLinkClick(event, '/dashboard/Messages')
                  }
                  className={isActive ? '' : 'disable-link'}
                  activeclassname='active'
                >
                  <MoveToInboxIcon />
                  <span>My Messages</span>
                </NavLink>
              </li>
            ) : null} */}

            {UserRole === 'Seller' ||
            UserRole === 'Staff' ||
            UserRole === 'Buyer' ? (
              <li className='mt-2 lh-1'>
                <NavLink
                  to={
                    isActive === true
                      ? '/dashboard/favourite'
                      : '/dashboard/profile'
                  }
                  activeclassname='active'
                  className={isActive ? '' : 'disable-link'}
                  onClick={event =>
                    handleLinkClick(event, '/dashboard/favourite')
                  }
                >
                  <FavoriteSharpIcon />
                  <span>Favourites </span>
                </NavLink>
              </li>
            ) : null}

            {/* {UserRole === 'SuperAdmin' || UserRole === 'Staff' ? (
              <li className='mt-2 lh-1'>
                <NavLink
                  to={
                    isActive === true
                      ? '/dashboard/users'
                      : '/dashboard/profile'
                  }
                  onClick={event => handleLinkClick(event, '/dashboard/users')}
                  className={isActive ? '' : 'disable-link'}
                  activeclassname='active'
                >
                  <PeopleIcon />
                  <span>User Listings</span>
                </NavLink>
              </li>
            ) : null} */}
            {UserRole === 'SuperAdmin' || UserRole === 'Staff' ? (
              <li className='mt-2 lh-1'>
                <NavLink
                  to={
                    isActive === true
                      ? '/dashboard/users'
                      : '/dashboard/profile'
                  }
                  onClick={event => handleLinkClick(event, '/dashboard/users')}
                  className={isActive ? '' : 'disable-link'}
                  activeclassname='active'
                >
                  <PeopleIcon />
                  <span>Users</span>
                </NavLink>
              </li>
            ) : null}

            {/* {UserRole === "Buyer" ? */}

            {UserRole === 'Seller' ||
            UserRole === 'Staff' ||
            UserRole === 'Buyer' ? (
              <li className='mt-2 lh-1'>
                <NavLink
                  to={
                    isActive === true
                      ? '/dashboard/saved-search'
                      : '/dashboard/profile'
                  }
                  activeclassname='active'
                  className={isActive ? '' : 'disable-link'}
                  onClick={event =>
                    handleLinkClick(event, '/dashboard/saved-search')
                  }
                >
                  <SaveSharpIcon />
                  <span>Saved Searches </span>
                </NavLink>
              </li>
            ) : null}

            {/* the code before warning is
                        <li li className='mt-2 lh-1'>
                            <NavLink to={isActive === true ? "/dashboard/chat" : "/dashboard/profile"} activeClassName="active" className={isActive ? "" : "disable-link"} onClick={handleLinkClick}>
                                <ArchiveRoundedIcon />
                                <span>Conversation</span>
                            </NavLink>

                        </li>

                        the code after resolve the li warning is */}
            {(UserRole === 'SuperAdmin' || UserRole === 'Staff') && (
              <li className='mt-2 lh-1'>
                <NavLink
                  to={
                    isActive === true
                      ? '/dashboard/stats'
                      : '/dashboard/profile'
                  }
                  activeclassname='active'
                  className={isActive ? '' : 'disable-link'}
                  onClick={event => handleLinkClick(event, '/dashboard/stats')}
                >
                  <EqualizerIcon />
                  <span>Statistics</span>
                </NavLink>
              </li>
            )}

            {/* {(UserRole === 'SuperAdmin' || UserRole === 'Staff') && (
              <li className='mt-2 lh-1'>
                <NavLink
                  to={
                    isActive === true
                      ? '/dashboard/buyer-request'
                      : '/dashboard/profile'
                  }
                  activeclassname='active'
                  className={isActive ? '' : 'disable-link'}
                  onClick={event => handleLinkClick(event, '/dashboard/stats')}
                >
                  <BusinessCenterIcon />
                  <span>Buyer Request</span>
                </NavLink>
              </li>
            )} */}

            {UserRole === 'SuperAdmin' || UserRole === 'Staff' ? (
              <li className='mt-2 lh-1'>
                <NavLink
                  to={
                    isActive === true
                      ? '/dashboard/refined-images'
                      : '/dashboard/profile'
                  }
                  onClick={event =>
                    handleLinkClick(event, '/dashboard/refined-images')
                  }
                  className={isActive ? '' : 'disable-link'}
                  activeclassname='active'
                >
                  <FilterIcon />
                  <span>Refined Images</span>
                </NavLink>
              </li>
            ) : null}
            {UserRole === 'SuperAdmin' || UserRole === 'Staff' ? (
              <li className='mt-2 lh-1'>
                <NavLink
                  to={
                    isActive === true
                      ? '/dashboard/admin-emails'
                      : '/dashboard/profile'
                  }
                  onClick={event =>
                    handleLinkClick(event, '/dashboard/admin-emails')
                  }
                  className={isActive ? '' : 'disable-link'}
                  activeclassname='active'
                >
                  <MailOutlineIcon />
                  <span>Email Inbox</span>
                </NavLink>
              </li>
            ) : null}

            {UserRole === 'SuperAdmin' || UserRole === 'Staff' ? (
              <li className='mt-2 lh-1'>
                <NavLink
                  to={
                    isActive === true
                      ? '/dashboard/highlights'
                      : '/dashboard/profile'
                  }
                  className={isActive ? '' : 'disable-link'}
                  activeclassname='active'
                  onClick={event =>
                    handleLinkClick(event, '/dashboard/highlights')
                  }
                >
                  <HighlightAltIcon />
                  <span>Web Highlights</span>
                </NavLink>
              </li>
            ) : null}
          </ul>
        </nav>
      </header>

      {/* {emailConfirmed === false ? <Verify /> : null} */}
    </div>
  )
}

export default Sidebar
