import { createSlice } from "@reduxjs/toolkit";

const tokenTimeSlice = createSlice({
  name: "TokenTimeSlice",
  initialState: "",
  reducers: {
    addToken: (state, action) => {

      return action.payload;
    },
    removeToken: () => {
      return "";
    },
  
  },
});
export const { addToken, removeToken} = tokenTimeSlice.actions;
export default tokenTimeSlice.reducer;