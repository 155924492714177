import React, { useState, memo, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { PropertyData } from "../PropertData";
import {
  ArrowRightIcon,
  BathIcon,
  BedIcon,
  DownLoadIcon,
  ScrollArrowIcon,
  SqftTableIcon,
  WoodFlooringIcon,
} from "../../../SvgIcons/Icons";
import { NeedHomeBaseURL } from "../../../config";
import { useNavigate } from "react-router-dom";

const AvailableHomes = ({
  isUserlogin,
  isMobileView,
  setIsSignUpModalOpen,
  setSignUpModalHeading,
  data,
}) => {
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  };

  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(isMobileView ? false : true);
  const [value, setValue] = useState(0);
  const [selectedBuildingType, setSelectedBuildingType] = useState("Condos");
  const [dataAccBuilType, setDataAccBuilType] = useState([]);
  const [visibleItemsCount, setVisibleItemsCount] = useState(10);
  const tabLabels = ["FLoor Plans", "Units", "Move Ins", "Sold"];
  const [planData, setPlanData] = useState([]);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setVisibleItemsCount(10);
  };

  const getFilteredDataLength = (index) => {
    switch (index) {
      case 0:
        return dataAccBuilType?.filter(
          (unit) =>
            unit.unitType === "Plan" &&
            unit.status !== "Sold" &&
            !unit.isQuickMoveIn
        ).length;
      case 1:
        return dataAccBuilType?.filter(
          (unit) =>
            unit.unitType === "Unit" &&
            unit.status !== "Sold" &&
            !unit.isQuickMoveIn
        ).length;
      case 2:
        return dataAccBuilType?.filter(
          (unit) => unit.status !== "Sold" && unit.isQuickMoveIn
        ).length;
      case 3:
        return dataAccBuilType?.filter((unit) => unit.status === "Sold").length;
      default:
        return 0;
    }
  };

  useEffect(() => {
    let filteredBuildingData;
    if (selectedBuildingType === "Condos") {
      filteredBuildingData = data?.preFloorPlans?.filter(
        (type) => type.colType === "Condos"
      );
    } else if (selectedBuildingType === "Townhouse") {
      filteredBuildingData = data?.preFloorPlans?.filter(
        (type) => type.colType === "Single-Family Homes"
      );
    } else if (selectedBuildingType === "Semi-Detached") {
      filteredBuildingData = data?.preFloorPlans?.filter(
        (type) => type.colType === "Semi-Detached"
      );
    } else if (selectedBuildingType === "Detached") {
      filteredBuildingData = data?.preFloorPlans?.filter(
        (type) => type.colType === "Detached"
      );
    }
    setDataAccBuilType(filteredBuildingData);
    // eslint-disable-next-line
  }, [selectedBuildingType]);

  useEffect(() => {
    let filterData;
    if (value === 0) {
      filterData = dataAccBuilType?.filter(
        (unit) =>
          unit.unitType === "Plan" &&
          unit.status !== "Sold" &&
          !unit.isQuickMoveIn
      );
    } else if (value === 1) {
      filterData = dataAccBuilType?.filter(
        (unit) =>
          unit.unitType === "Unit" &&
          unit.status !== "Sold" &&
          !unit.isQuickMoveIn
      );
    } else if (value === 2) {
      filterData = dataAccBuilType?.filter(
        (unit) => unit.status !== "Sold" && unit.isQuickMoveIn
      );
    } else if (value === 3) {
      filterData = dataAccBuilType?.filter((unit) => unit.status === "Sold");
    }
    setPlanData(filterData);
  }, [value, dataAccBuilType]);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <RemoveIcon className="Property_minusWebGreyIcon" />
          ) : (
            <AddIcon className="Property_plusWebGreyIcon" />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex align-items-center gap-2" : ""}>
          {isMobileView && <WoodFlooringIcon color={"#282828"} size={"20px"} />}
          <h2 className="property_Details_heading">Floor Plans</h2>
          {!isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
              Discover your perfect home in a vibrant community, ready for you
              to move in.
            </p>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isMobileView && (
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
            Discover your perfect home in a vibrant community, ready for you to
            move in.
          </p>
        )}
        <div className="Prop_detailsFloorPlan_selectBtnGroup">
          <div
            className="btn-group mt-3"
            role="group"
            aria-label="Basic example"
            style={{ minWidth: "445px" }}
          >
            <button
              type="button"
              className={`Prop_detailsFloor_plan_selectBtn PropertyDetails_Mbl_f14 ${
                selectedBuildingType === "Condos" &&
                "floor_plan_buildingSelectBtn"
              }`}
              onClick={() => setSelectedBuildingType("Condos")}
            >
              Condos
            </button>
            <button
              type="button"
              className={`Prop_detailsFloor_plan_selectBtn PropertyDetails_Mbl_f14 ${
                selectedBuildingType === "Townhouse" &&
                "floor_plan_buildingSelectBtn"
              }`}
              onClick={() => setSelectedBuildingType("Townhouse")}
              style={{
                borderRadius: "0px",
                borderLeft: "none",
              }}
            >
              Townhouse
            </button>
            <button
              type="button"
              className={`Prop_detailsFloor_plan_selectBtn PropertyDetails_Mbl_f14 ${
                selectedBuildingType === "Semi-Detached" &&
                "floor_plan_buildingSelectBtn"
              }`}
              onClick={() => setSelectedBuildingType("Semi-Detached")}
              style={{
                borderRadius: "0px",
                borderLeft: "none",
              }}
            >
              Semi-Detached
            </button>
            <button
              type="button"
              className={`Prop_detailsFloor_plan_selectBtn PropertyDetails_Mbl_f14 ${
                selectedBuildingType === "Detached" &&
                "floor_plan_buildingSelectBtn"
              }`}
              style={{ borderRadius: "0px 10px 10px 0px", borderLeft: "none" }}
              onClick={() => setSelectedBuildingType("Detached")}
            >
              Detached
            </button>
          </div>
        </div>
        <Box className="mt-3">
          <AppBar
            position="static"
            sx={{
              boxShadow: "none",
            }}
          >
            <Tabs
              value={value}
              onChange={handleTabChange}
              textColor="inherit"
              sx={{
                backgroundColor: "#fff",
              }}
              className="Property_details_tabs"
              elevation={0}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
            >
              {tabLabels?.map((tab, index) => (
                <Tab
                  key={index}
                  label={`${tab} (${getFilteredDataLength(index)})`}
                  sx={{
                    border: "1px solid #CCCCCC",
                    fontWeight: "700",
                    padding: "15px 30px",
                  }}
                />
              ))}
            </Tabs>
          </AppBar>
          <TabPanel className="PropertyDetail_tabPanel">
            <div
              className="w-100 py-2 PropDetails_HideScrollBar position-relative"
              style={{ overflowX: "auto" }}
            >
              <table className="Property_floorPlan_table w-100">
                <thead className="w-100">
                  <tr className="w-100">
                    <th style={{ width: "35%" }}>
                      Name <ScrollArrowIcon size={"16"} />
                    </th>
                    <th style={{ width: "18%" }}>Price</th>
                    <th style={{ width: "13%" }}>Bed</th>
                    <th style={{ width: "13%" }}>Bath</th>
                    <th style={{ width: "13%" }}>Sqft</th>
                    <th style={{ width: "10%" }}></th>
                  </tr>
                </thead>
                {planData?.slice(0, visibleItemsCount).map((item, index) => (
                  <tbody
                    key={index}
                    style={{ borderBottom: "1px solid #d9d9d9" }}
                  >
                    <tr>
                      <td>
                        <div className="d-flex Property_floorPlans_NameDetails">
                          {item?.preFloorPlanMedias?.length > 0 ? (
                            <img
                              src={`${NeedHomeBaseURL}/${item?.preFloorPlanMedias[0]?.fileUrl}`}
                              alt={item?.unitName}
                              loading="lazy"
                            />
                          ) : (
                            <img
                              src={`/images/PropertyNoImage.png`}
                              alt={item?.unitName}
                              loading="lazy"
                            />
                          )}
                          <div>
                            {item?.unitName && <h6>{item?.unitName}</h6>}
                            {item?.unitStyle && (
                              <p className="m-0">{item?.unitStyle}</p>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        {isUserlogin ? (
                          <span className="property_floorPlan_price">{`$${item?.price?.toLocaleString(
                            "en-US"
                          )}`}</span>
                        ) : (
                          <img
                            src="/images/pricingBlur.png"
                            width={"84px"}
                            height={"100%"}
                            alt="blurPricing"
                            loading="lazy"
                          />
                        )}
                      </td>
                      <td>
                        <span className="property_floorPlan_Beds">
                          <BedIcon
                            width={"25"}
                            height={"24"}
                            color={"#027545"}
                          />
                          &nbsp;{item?.bath}
                        </span>
                      </td>
                      <td>
                        <span
                          className="property_floorPlan_Beds"
                          style={{
                            background: "var(--Blue-50, #EFF8FF)",
                            color: "#1658CB",
                          }}
                        >
                          <BathIcon />
                          &nbsp;{item?.bed}
                        </span>
                      </td>
                      <td>
                        <span
                          className="property_floorPlan_Beds"
                          style={{
                            background: "var(--Primary-50, #F9F5FF)",
                            color: "#653EBE",
                          }}
                        >
                          <SqftTableIcon />
                          &nbsp;{item?.sqft}
                        </span>
                      </td>
                      <td className="text-end pe-2">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/floor-plan/:id")}
                        >
                          <ArrowRightIcon />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>

              {PropertyData?.AvailableHome?.[0]?.[tabLabels[value]]?.length >
                visibleItemsCount &&
                isUserlogin && (
                  <div className="text-center mt-3">
                    <button
                      onClick={() =>
                        setVisibleItemsCount(visibleItemsCount + 10)
                      }
                      className="Detail_showMoreBtn"
                    >
                      Show all
                    </button>
                  </div>
                )}
              {!isUserlogin && planData?.length > 0 && (
                <div className="position-absolute d-flex align-items-center justify-content-center w-100 h-100 top-0 left-0">
                  <button
                    className="PropertyDetails_Mbl_f14 Prop_loginReqBtn"
                    onClick={() => {
                      setIsSignUpModalOpen(true);
                      setSignUpModalHeading(
                        "Join us to view the latest floor prices!"
                      );
                    }}
                  >
                    Login Required
                  </button>
                </div>
              )}
            </div>
          </TabPanel>
        </Box>

        {planData?.length > 0 && (
          <>
            {isUserlogin ? (
              <button className="FindNearBy_Btn available_homeBtn PropertyDetails_Mbl_f14">
                Download
                <DownLoadIcon />
              </button>
            ) : (
              <button
                className="FindNearBy_Btn PropertyDetails_Mbl_f14 available_homeBtn"
                onClick={() => {
                  setIsSignUpModalOpen(true);
                  setSignUpModalHeading(
                    "Join us to view the latest floor prices!"
                  );
                }}
              >
                Login to Download
              </button>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(AvailableHomes);
