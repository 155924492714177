import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./SellProperty.css";

export default function FaqAccordion() {
  return (
    <div className="w-50 Faq_main">
      <Accordion className="mb-2 rounded">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="fs-5 fw-bold font faq_mobile_font">Who is financeit?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="font faqMobile_des_font">
            Financeit is a Canadian company that allows businesses like
            DuProprio to offer financing plans to their clients. In Canada, a
            number of companies also do business with them, such as Home Depot,
            Trevi, Qualinet and Fenplast. Financeit offers respected and secure
            service in French.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="my-3 rounded">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="fs-5 fw-bold font faq_mobile_font">
            What happens after my online request is not accepted?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="font faqMobile_des_font">
            A AssignmentSold advisor will be in touch to guide you through the next
            steps. They'll arrange a meeting for property photography and set up
            your financing with Financeit after the visit.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="my-3 rounded">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className="fs-5 fw-bold font faq_mobile_font">
            What do I do if my request is not accepted?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="font faqMobile_des_font">
            If your financing request is declined, you can consider adding a co-
            applicant to improve your chances of approval. Need Home advisors
            can help you with this if it applies to your situation. Keep in
            mind, you also have the option to pay for your package using a Visa
            or Mastercard.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className="my-3 rounded">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className="fs-5 fw-bold font faq_mobile_font">
            {" "}
            What are Financeit's interest rate?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography className="font faqMobile_des_font">
            The equal payments come with a 15.99% interest rate. For Silver or
            Gold packages, these payments start after a 6-month period.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className="my-3 rounded">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className="fs-5 fw-bold font faq_mobile_font"> How do I make repayments?</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography className="font faqMobile_des_font">
            To make a partial or full repayment, simply get in touch with
            Financeit directly. They offer services in both French and English.
            You won't incur any penalties or fees for making early repayments.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className="my-3 rounded">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className="fs-5 fw-bold font faq_mobile_font">
            {" "}
            Is my credit rating affected if I check my eligibility for the
            financing plan?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography className="font faqMobile_des_font">
            Checking your eligibility won't impact your credit score. However,
            an official financing request might. If you submit a request on
            Financeit's website, any actions that could affect your credit
            rating will be clearly indicated in the second step.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
