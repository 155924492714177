import React, { useState } from 'react'
import { AppBar, Toolbar, IconButton, styled } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { Modal } from 'react-bootstrap'
import MobileSidebar from '../Sidebar/MobileSidebar'
import { BiLogOut } from 'react-icons/bi'
import jwtDecode from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'
import { removeUserProfileData } from '../../ReduxStore/Slices/UserProfileData'
import { removeProfileData } from '../../ReduxStore/Slices/ProfileDataSlice'
import { showStatus, hideStatus } from '../../ReduxStore/Slices/ActStatus'
import ClearIcon from '@mui/icons-material/Clear'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { removeItem } from '../../ReduxStore/Slices/LoginSlice'
import Cookies from 'universal-cookie'
import LanguageIcon from '@mui/icons-material/Language'
import baseURL from '../../config'
import { Link, useNavigate } from 'react-router-dom'
import { hideActivity } from '../../ReduxStore/Slices/ActivityIconSlice'
const AppBarWrapper = styled(AppBar)({
  backgroundColor: 'white'
})

const Navbar = ({ showComponent, setShowComponent }) => {
  const userDataSlice = useSelector(state => state.userProfileDataSlice[0])
  const activitySignal = useSelector(state => state.ActivityIconSlice)
  const actStatus = useSelector(state => state.ActStatus)
  const [fullscreen] = useState(true)
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const cookies = new Cookies()
  const jwt_token = cookies.get('jwt_authorization')
  const showActivity = () => {
    dispatch(showStatus())
  }
  const HandleLogout = () => {
    cookies.remove('jwt_authorization', { path: '/' })
    localStorage.removeItem('fullName')
    localStorage?.removeItem('lastName')
    localStorage.removeItem('email')
    localStorage?.removeItem('phoneNumber')
    sessionStorage.removeItem('ChatId')
    dispatch(removeItem())
    dispatch(removeProfileData())
    localStorage.setItem('anonymous', 0)
    localStorage.setItem('trackVariable', '0')
    dispatch(removeUserProfileData())
    navigate('/')
    window.location.reload()
  }
  const handleBackArrow = () => {
    dispatch(hideActivity())
  }
  const handleBackCross = () => {
    dispatch(hideStatus())
  }
  let UserRole = ''
  if (jwt_token) {
    let token = cookies.get('jwt_authorization')
    const decode = jwtDecode(token)
    UserRole =
      decode['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
  }
  return (
    <div>
      <AppBarWrapper elevation={0}>
        <Toolbar
          style={{ minHeight: '42px' }}
          className='d-flex justify-content-between'
        >
          {activitySignal === 1 ? (
            <>
              {activitySignal === 1 && actStatus !== 1 ? (
                <>
                  <ArrowBackIcon
                    className='backArrowAPP'
                    onClick={handleBackArrow}
                  />
                </>
              ) : (
                <>
                  <ClearIcon
                    className='backArrowAPP'
                    onClick={handleBackCross}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {/* {showComponent ? (
                <IconButton
                  edge='start'
                  color='white'
                  size='sm'
                  aria-label='component'
                >
                  {showComponent ? (
                    <CloseIcon
                      onClick={() => setShowComponent(false)}
                      className='text-primary'
                    />
                  ) : (
                    <MenuIcon className='text-primary' />
                  )}
                </IconButton>
              ) : ( */}
                <IconButton
                  edge='start'
                  color='white'
                  size='sm'
                  aria-label='menu'
                  onClick={() => setShow(prevShow => !prevShow)} // Toggle the sidebar modal
                >
                  {show ? (
                    <CloseIcon className='text-primary' />
                  ) : (
                    <MenuIcon className='text-primary' />
                  )}
                </IconButton>
              {/* )} */}
            </>
          )}

          <div className='d-flex '>
            <Link to='/'>
              <div className='fw-bold d-flex aligncenter'>
                <img
                  src={require('../../Components/Images/icon.png')}
                  style={{ height: '25px', width: '25px' }}
                  alt='AssignmentSold Logo'
                  loading="lazy"
                />
                <span className='fs-6 font12 text-primary ps-2'>
                  ASSIGNMENT<span className='text-warning'>SOLD.CA</span>
                </span>
              </div>
            </Link>
          </div>
          <div style={{ float: 'right' }}>
            {/* {activitySignal === 1 && UserRole !== 'Buyer' ? (
              <>
                <LanguageIcon className='green__Globe' onClick={showActivity} />
              </>
            ) : (
              <> */}
                <Link to='/dashboard/profile'>
                  <img
                    className='text-black pr-img'
                    src={
                      userDataSlice?.imageUrl
                        ? userDataSlice.imageUrl.startsWith(
                            'https://lh3.googleusercontent.com'
                          )
                          ? userDataSlice.imageUrl // Google image URL
                          : `${baseURL}/${userDataSlice.imageUrl}` // Backend image URL
                        : require('../../images/ProfileAvatar.jpg') // Default image if no URL is provided
                    }
                    alt='User Profile'
                    loading="lazy"
                  />
                </Link>
              {/* </>
            )} */}
          </div>
        </Toolbar>
        <div className='navborders'></div>
      </AppBarWrapper>

      {/* ==========================================Side Bar Modal ======================================= */}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className='p-0'>
          <Modal.Title>Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-3 px-0'>
          <MobileSidebar />
        </Modal.Body>
        <Modal.Footer className='text-danger justify-content-start ps-4'>
          <div className='d-flex' onClick={() => setShow(false)}>
            <BiLogOut style={{ width: '30px', height: '30px' }} />
            <h6 className='ps-2 mt-1 font13 ' onClick={HandleLogout}>
              Log Out
            </h6>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Navbar
