import React from 'react';
import baseURL from '../../../../config';
import { useNavigate } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton';

const RelatedArticlesCard = ({ data, loading }) => {

    const navigate = useNavigate()

    const handleDetails = () => {
        if (!loading) {
            navigate(`/blogs/details/${data?.slug}`)
        }
    }
    
    return (
        <div className='relatedArticle__card d-flex'>
            {loading ?
                <Skeleton variant="rounded" animation="wave" width={100} height={81} /> :
                <>
                    {data?.blogMedias?.length > 0 ?
                        <img src={`${baseURL}/${data?.blogMedias[0]?.fileUrl}`} className='relatedArticle_img' alt={data?.title} loading="lazy"/>
                        :
                        <img
                            src={require("../../../../images/no-image.png")}
                            alt={data?.title}
                            className='relatedArticle_img'
                            loading="lazy"
                        />
                    }
                </>
            }
            {loading ?
                <div style={{ width: 'calc(100% - 110px)', marginLeft: '10px' }}>
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem' }} />
                </div> :
                <h4 className='relatedArticle_content' onClick={handleDetails}>{data?.title?.length > 50 ? `${data?.title.slice(0, 50)}...` : data?.title}</h4>
            }
        </div>
    )
}

export default RelatedArticlesCard
