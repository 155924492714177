import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";

export const RealtorStep1Schema = Yup.object({
  fname: Yup.string().required("Name is required"),

  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format"
    )
    .required("Email is required"),

  cno: Yup.string()
    .test("is-valid-phone", "Invalid phone number", (value, { parent }) => {
      const selectedCountry = parent.selectedCountry;
      if (!selectedCountry) {
        return true;
      }

      return isValidPhoneNumber(value, selectedCountry);
    })
    .required("Phone number is required"),

  recoId: Yup.string()
    .matches(/^\d{7}$/, "Invalid RecoId")
    .required("RecoId is required"),

  province: Yup.string().required("Province is required"),
});

export const RealtorStep2Schema = Yup.object({
  brokerageName: Yup.string().required("Brokerage name is required"),

  brokerageEmail: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format"
    )
    .required("Brokerage email is required"),

  brokeragePhoneNumber: Yup.string()
    .test("is-valid-phone", "Invalid phone number", (value, { parent }) => {
      const selectedCountry = parent.selectedCountry;
      if (!selectedCountry) {
        return true;
      }

      return isValidPhoneNumber(value, selectedCountry);
    })
    .required("Brokerage Phone number is required"),

  brokerageAddress: Yup.string().required("Brokerage address is required"),
});

export const RealtorStep3Schema = Yup.object({
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
      "Password must include lowercase, uppercase, numbers, and at least one special character"
    )
    .required("Password is required"),

  cpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("This field is required"),

  termsAndConditions: Yup.boolean().oneOf(
    [true],
    "You must agree to the Terms and Conditions"
  ),
});
