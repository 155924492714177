import React, { useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { useEffect } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';

export default function BillingAddress({ onNext, onPrevious, formData, updateFormData }) {
    const [isFormValid, setIsFormValid] = useState(true);
    const cookies = new Cookies();
    const jwt_token = cookies.get("jwt_authorization");
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
    useEffect(() => {
        function handleResize() {
            setIsMobileView(window.innerWidth <= 800);
        }

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleInputChange = (field, value) => {
        const updatedFormData = {
            ...formData,
            [field]: value,
        };
        updateFormData(updatedFormData); // Update the parent state
    };

    useEffect(() => {
        const validatePaymentDetails = () => {
            const isValid = formData?.apartment?.trim() !== '' && formData?.postalCode?.trim() !== '' && formData.cityRegion?.trim() !== '' && formData.civicNumber?.trim() !== '' && formData.streetName?.trim() !== '' && formData?.checkbox_4 === true && formData?.checkbox_5 === true;
            setIsFormValid(isValid);
        };

        validatePaymentDetails();
    }, [formData]);




    // ---------------------------------------handle Address
    const [inputValue2, setInputValue2] = useState("");

    const [
        inputValue2ChangedByAutocomplete,
        setInputValue2ChangedByAutocomplete,
    ] = useState(false);
    const [newFilteredCities2, setNewFilteredCities2] = useState([]);
    const typingTimeout = useRef();

    const isOptionEqualToValue = (option, value) => {
        return option.code === value.code && option.label === value.label;
    };

    const handleOptionSelect = async (selectedOption) => {
        if (!selectedOption) {
            //   formik.setFieldValue("city", "");
            //   formik.setFieldValue("postalcode", "");
            //   formik.setFieldValue("country", "");
            //   formik.setFieldValue("region", "");
            //   formik.setFieldValue("latitude", "");
            //   formik.setFieldValue("longitude", "");
            // setcity("");
            // setpostal_code("");
            return; // Exit the function early
        }
        try {
            const response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${selectedOption?.responseDesc}.json?proximity=ip&access_token=pk.eyJ1IjoiY2hpc2h0aS1hZG1pbiIsImEiOiJjbGw1N2FsNTcwYzFsM2ttazRzcnlhanpsIn0.h1YsNrhMozZkeWn6jcYTwg`);

            if (response?.data?.features && response.data.features.length > 0) {
                let mapboxcity;
                let mapboxpostcode;

                // Check if mapboxcity and mapboxpostcode are already found in the first feature
                const firstFeature = response.data.features[0];
                firstFeature.context.forEach((e, index) => {
                    if (e?.id.includes("place")) {
                        mapboxcity = e.text;
                    } else if (e?.id.includes("postcode")) {
                        mapboxpostcode = e.text;
                    }
                });

                // If both city and postcode are found in the first feature, update the form data and exit
                if (mapboxpostcode && mapboxcity) {
                    const updatedFormData = {
                        ...formData,
                        cityRegion: mapboxcity,
                        postalCode: mapboxpostcode
                    };

                    updateFormData(updatedFormData);
                    return;
                }

                // If city is found but postcode is not found, continue searching in other features
                if (mapboxcity) {
                    for (let i = 1; i < response.data.features.length; i++) {
                        const feature = response.data.features[i];
                        feature.context.forEach((e, index) => {
                            if (e?.id.includes("postcode")) {
                                mapboxpostcode = e.text;
                            }
                        });

                        // If postcode is found, update the form data and exit
                        if (mapboxpostcode) {
                            const updatedFormData = {
                                ...formData,
                                cityRegion: mapboxcity,
                                postalCode: mapboxpostcode
                            };
                            updateFormData(updatedFormData);
                            return;
                        }
                    }
                }
            }
        } catch (error) {
            toast.error("Something went wrong while filling the fields");
        }




    };

    const handleInputChange2 = async (event, value) => {
        // setisAddressfilled(false);
        const inputValue2 = value;
        if (!inputValue2ChangedByAutocomplete) {
            setInputValue2(inputValue2);

        }

        if (inputValue2.trim() === "") {
            return;
        }

        if (typingTimeout.current) {
            clearTimeout(typingTimeout.current);
        }

        typingTimeout.current = setTimeout(async () => {
            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${jwt_token}`,
                    },
                };
                const response = await axios.get(
                    `https://config.assignmentsold.ca/api/User-Data/GetAddressResponse/${inputValue2}`,
                    config
                );
                const filteredCities = response.data?.data.map((item) => {
                    return {
                        responseTitle: item.responseTitle,
                        responseDesc: item.responseDesc,
                    };
                });
                // response2?.data?.features[0]?.context.map((e) => {
                //   e?.id.includes("place") && formik.setFieldValue("city", e.text);
                //   e?.id.includes("postcode") && formik.setFieldValue("postalcode", e.text);
                //   e?.id.includes("country") && formik.setFieldValue("country", e.text);
                //   e?.id.includes("region") && formik.setFieldValue("region", e.text);
                // })
                setNewFilteredCities2(filteredCities);
            } catch (error) { }
        }, 300);
    };



    return <>
        <div className='bg-white getquote_card_padding' style={{ borderRadius: "30px" }}>
            <div className='margin_bottom' >
                <h4 >
                    2. Billing Address
                </h4>
            </div>
            <Form className="d-flex mt-3 fullwidthcolumn">
                <Form.Group className=" w-100 pe-3 mobileviewWidth FieldWidth">
                    <Form.Label className="fs-6 mb-0 fontmobile16 text-shadow-black">Address</Form.Label>
                    <Autocomplete
                        size="small"
                        id="country-select-demo"
                        sx={{ width: "100%" }}
                        freeSolo
                        options={newFilteredCities2}
                        inputValue={inputValue2}
                        onInputChange={(event, value) => {
                            handleInputChange2(event, value);
                            setInputValue2ChangedByAutocomplete(false);
                        }}
                        autoHighlight={false}
                        getOptionLabel={(option) => option.responseTitle}
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                {...props}
                            >
                                <div className="fd-col">
                                    <div>
                                        <FmdGoodOutlinedIcon className="locaicon" />
                                        {`${option.responseTitle}`}
                                    </div>
                                    <div className="respDesc">{`${option.responseDesc}`}</div>
                                </div>
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{
                                    // width: "100%",
                                    // height: "40px",
                                    borderRadius: "4px",

                                }}
                                size='small'
                                className="fieldsettings  mob_filedsettings bg-white"
                                value={inputValue2}
                                id="outlined-basic"


                                name="Location"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                    maxLength: 55,
                                }}
                            />
                        )}
                        isOptionEqualToValue={isOptionEqualToValue}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setInputValue2(newValue.responseDesc);
                                setInputValue2ChangedByAutocomplete(true);
                                handleOptionSelect(newValue);
                            }
                        }}
                    />
                </Form.Group>

            </Form>
            <Form className="mt-3 d-flex Mobilefont13 fullwidthcolumn">
                <Form.Group className=" w-50 pe-3 mobileviewWidth FieldWidth">
                    <Form.Label className="fs-6 mb-0 fontmobile16 text-shadow-black">Civic Number</Form.Label>
                    <Form.Control
                        size="small"
                        type="text"
                        className='fontmobile14'
                        value={formData.civicNumber}
                        onChange={(e) => handleInputChange('civicNumber', e.target.value)}
                    />
                </Form.Group>
                <Form.Group className=" w-50 ps-3 mobile_padding_input mobileviewWidth FieldWidth">
                    <Form.Label className="fs-6 mb-0 fontmobile16 text-shadow-black">Street Name</Form.Label>
                    <Form.Control
                        size="small"
                        type="text"
                        className='fontmobile14'
                        value={formData.streetName}
                        onChange={(e) => handleInputChange('streetName', e.target.value)}
                    />
                </Form.Group>
            </Form>
            <Form className="d-flex mt-3 fullwidthcolumn">
                <Form.Group className=" w-50 pe-3 mobileviewWidth FieldWidth">
                    <Form.Label className="fs-6 mb-0 fontmobile16 text-shadow-black">Apartment</Form.Label>
                    <Form.Control
                        size="small"
                        type="text"
                        className='fontmobile14'
                        value={formData.apartment}
                        onChange={(e) => handleInputChange('apartment', e.target.value)}
                    />
                </Form.Group>
                <Form.Group className=" w-50 ps-3 mobile_padding_input mobileviewWidth FieldWidth">
                    <Form.Label className="fs-6 mb-0 fontmobile16 text-shadow-black">Postal code</Form.Label>
                    <Form.Control
                        size="small"
                        type="text"
                        className='fontmobile14'
                        value={formData.postalCode}
                        onChange={(e) => handleInputChange('postalCode', e.target.value)}
                    />
                </Form.Group>
            </Form>
            <Form className="d-flex mt-3 fullwidthcolumn">
                <Form.Group className=" w-50 pe-3 mobileviewWidth FieldWidth">
                    <Form.Label className="fs-6 mb-0 fontmobile16 text-shadow-black">City/Region</Form.Label>
                    <Form.Control
                        size="small"
                        className='fontmobile14'
                        type="text"
                        value={formData.cityRegion}
                        onChange={(e) => handleInputChange('cityRegion', e.target.value)}
                    />
                </Form.Group>

            </Form>

            <div className='mt-3'>
                <span className='py-3' >
                    <input type="checkbox" onChange={(e) => handleInputChange("checkbox_4", !formData.checkbox_4)} checked={formData.checkbox_4} />
                    <span className='ms-2'>All owners have provided their consent to sell this property.</span>
                </span>
                <br />
                <span  >
                    <input type="checkbox" onChange={(e) => handleInputChange("checkbox_5", !formData.checkbox_5)} checked={formData.checkbox_5} />
                    <span className='ms-2'>
                        Use as billing address.
                    </span>
                </span>
            </div>
        </div>

        <div className='d-flex' >
            <button
                className='btn btn-outline-primary fs-5 me-2 bottom fontmobile16 primary-color bg-white font text-shadow-black'
                onClick={onPrevious}
                style={{ position: 'absolute', bottom: '80px', left: '20px', border: "1px solid", padding: isMobileView ? "8px 12px" : "10px 25px" }}
            >
                &lt; Previous
            </button>
            <button
                style={{ position: 'absolute', bottom: '80px', right: '20px', border: "1px solid", padding: isMobileView ? "8px 12px" : "10px 25px" }}
                className='btn btn-primary fs-5 bottom fontmobile16 primary-bg font text-shadow-black'
                onClick={() => onNext(formData)}
                disabled={!isFormValid}
            >
                Next &gt;
            </button>
        </div>
    </>;
}





// <div
// style={{
//     position: "absolute",
//     top: "124.5px",
//     left: "1595.5px",
//     borderRight: "5px solid #02549e",
//     boxSizing: "border-box",
//     width: "5px",
//     height: "1163px",
// }}
// />
// <div
// style={{
//     position: "absolute",
//     top: "256px",
//     left: "1628px",
//     width: "72px",
//     height: "444px",
//     textAlign: "center",
//     fontSize: "32px",
//     color: "#d9d9d9",
// }}
// >
// <div
//     style={{
//         position: "absolute",
//         top: "0px",
//         left: "0px",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "flex-start",
//         justifyContent: "flex-start",
//         gap: "52px",
//     }}
// >
//     <div
//         style={{
//             position: "relative",
//             width: "72px",
//             height: "72px",
//             color: "#02549e",
//         }}
//     >
//         <div
//             style={{
//                 position: "absolute",
//                 top: "0px",
//                 left: "0px",
//                 borderRadius: "50%",
//                 backgroundColor: "#fff",
//                 border: "4px solid #02549e",
//                 boxSizing: "border-box",
//                 width: "72px",
//                 height: "72px",
//             }}
//         />
//         <div
//             style={{
//                 position: "absolute",
//                 top: "25px",
//                 left: "24px",
//                 fontWeight: "600",
//                 display: "inline-block",
//                 width: "23px",
//                 height: "22px",
//             }}
//         >
//             1
//         </div>
//     </div>
//     <div
//         style={{ position: "relative", width: "72px", height: "72px" }}
//     >
//         <div
//             style={{
//                 position: "absolute",
//                 top: "0px",
//                 left: "0px",
//                 borderRadius: "50%",
//                 backgroundColor: "#fff",
//                 border: "2px solid #d9d9d9",
//                 boxSizing: "border-box",
//                 width: "72px",
//                 height: "72px",
//             }}
//         />
//         <div
//             style={{
//                 position: "absolute",
//                 top: "25px",
//                 left: "24px",
//                 fontWeight: "500",
//                 display: "inline-block",
//                 width: "23px",
//                 height: "22px",
//             }}
//         >
//             2
//         </div>
//     </div>
//     <div
//         style={{ position: "relative", width: "72px", height: "72px" }}
//     >
//         <div
//             style={{
//                 position: "absolute",
//                 top: "0px",
//                 left: "0px",
//                 borderRadius: "50%",
//                 backgroundColor: "#fff",
//                 border: "2px solid #d9d9d9",
//                 boxSizing: "border-box",
//                 width: "72px",
//                 height: "72px",
//             }}
//         />
//         <div
//             style={{
//                 position: "absolute",
//                 top: "25px",
//                 left: "24px",
//                 fontWeight: "500",
//                 display: "inline-block",
//                 width: "23px",
//                 height: "22px",
//             }}
//         >
//             3
//         </div>
//     </div>
//     <div
//         style={{ position: "relative", width: "72px", height: "72px" }}
//     >
//         <div
//             style={{
//                 position: "absolute",
//                 top: "0px",
//                 left: "0px",
//                 borderRadius: "50%",
//                 backgroundColor: "#fff",
//                 border: "2px solid #d9d9d9",
//                 boxSizing: "border-box",
//                 width: "72px",
//                 height: "72px",
//             }}
//         />
//         <div
//             style={{
//                 position: "absolute",
//                 top: "25px",
//                 left: "24px",
//                 fontWeight: "500",
//                 display: "inline-block",
//                 width: "23px",
//                 height: "22px",
//             }}
//         >
//             4
//         </div>
//     </div>
// </div>
// </div>


// <img
// style={{
//     position: "absolute",
//     top: "281px",
//     left: "1585px",
//     width: "24px",
//     height: "24px",
//     objectFit: "cover",
// }}
// alt=""
// src={premiumbadge}
// />