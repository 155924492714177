import { useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import baseURL from "../../../../config";



const BlogMainCard = ({ item, loading }) => {

  const navigate = useNavigate()
  const handledetailsNavigate = () => {
    if (!loading) {
      navigate(`/blogs/details/${item?.slug}`)
    }
  }

  return (
    <div className='ArticleMainCard w-100 d-flex'>
      <div className='ArticleMainCard_Image'>
        {loading ?
          <Skeleton variant="rounded" animation="wave" style={{ width: '100%', height: '100%' }} /> :
          <>
           {item?.blogMedias?.length > 0 ?
                <img src={`${baseURL}/${item?.blogMedias[0]?.fileUrl}`} alt={item?.title} style={{ cursor: 'pointer' }} onClick={handledetailsNavigate} loading="lazy"/>
                :
                <img
                  src={require("../../../../images/no-image.png")}
                  alt={item?.title}
                  onClick={handledetailsNavigate}
                  loading="lazy"
                />
              }
            </>
        }
      </div>

      <div className="w-100 ms-3">
        {!loading && (
          <div className='categoryAndRead mt-2'>
            <div className='ArticleCard_category'>
              <h6>{item?.blogCategory?.name}</h6>
            </div>
            <div className='ArticleCard_ReadTime'>
              <h6>{item?.readtime} min read</h6>
            </div>
          </div>
        )}

        <div className='ArticleCardTitleDes main_article_cardHead__des'>

          {loading ?
            <>
              <Skeleton variant="text" animation="wave" sx={{ fontSize: '1.5rem' }} />
              <Skeleton variant="text" animation="wave" sx={{ fontSize: '0.6rem' }} />
              <Skeleton variant="text" animation="wave" sx={{ fontSize: '0.6rem' }} />
              <Skeleton variant="text" animation="wave" sx={{ fontSize: '0.6rem' }} />
            </> :
            <>
              <h3 onClick={handledetailsNavigate}>{item?.title}</h3>
              <p>
                {`${item?.des?.replace(/<[^>]*>/g, '').slice(0, 150)}...`}
              </p>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default BlogMainCard;
