import React, { useState, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { AwardIcon } from "../../../SvgIcons/Icons";

const WiningAwards = ({ data, isMobileView }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  const certificate = [
    "/images/realtorCertificate.png",
    "/images/realtorCertificate.png",
    "/images/realtorCertificate.png",
    "/images/realtorCertificate.png",
  ];

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex flex-column gap-2" : ""}>
          <div className="d-flex">
            {isMobileView && <AwardIcon />}
            <h2 className="property_Details_heading">
              Umar Khan Wining Awards
            </h2>
          </div>

          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
            Umar Khan’s impressive collection of 100 awards highlights his
            excellence across multiple areas: 45 in sales performance, 10 in
            customer service, 5 in community impact, 15 in marketing innovation,
            15 in leadership, and 10 in team collaboration. These honors
            showcase his dedication, expertise, and impactful contributions to
            real estate.
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="row">
          {certificate?.map((item, index) => {
            return (
              <div
                key={index}
                className="col-md-3 col-6 mt-3 d-flex justify-content-center"
              >
                <img
                  width={"90%"}
                  src={item}
                  alt="realtorCertificate"
                  loading="lazy"
                />
              </div>
            );
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(WiningAwards);
