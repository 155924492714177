import React, { useState } from "react";

const Transist = ({ data }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleExpand = () => {
    setShowMore(!showMore);
  };

  const transitData = data || [];
  const transitDataToShow = showMore ? transitData : transitData.slice(0, 6);

  const scores = [96, 97, 136, 650];

  return (
    <>
      <div className="row mx-0">
        {transitDataToShow?.map((item, i) => {
          return (
            <div
              className="col-md-6 col-12 mbl_Xpadding0 px-2 explore_propertyCard mt-4"
              key={i}
            >
              <h5 className="PropertyDetails_Mbl_f18">{item?.name}</h5>
              <div className="d-flex prop_transitScore">
                {scores?.map((score, i) => {
                  return (
                    <p className="m-0" key={i}>
                      {score}
                    </p>
                  );
                })}
              </div>
              <h6>14 min walk</h6>
            </div>
          );
        })}
      </div>

      {transitData?.length > 6 && !showMore ? (
        <button onClick={toggleExpand} className="Detail_showMoreBtn p-0 mt-2">
          Show all
        </button>
      ) : null}
    </>
  );
};

export default Transist;
