
import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../Dashboard'
import { IconButton, InputBase } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import "./savedsearch.css";
import SavedSearchTable from './savedSearchTable';
import axios from 'axios';
import MobileSavedSearch from './MobileSavedSearch';
import useMediaQuery from '../../CustomHook/UseMediaQuery';
import { RotatingLines } from 'react-loader-spinner';
import Footer from '../../Footer/footer';
import baseURL from '../../../config';
import Cookies from 'universal-cookie';
import NotFoundImge from '../../../images/SavedSearchMobileDemo.svg'

const SavedSearchDashboard = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setloading] = useState(true);
  const [data, setData] = useState([]);
  // const [original, setoriginal] = useState([])
  const cookies = new Cookies();
  const jwt_token = cookies.get("jwt_authorization");
  const isPhoneScreen = useMediaQuery('(max-width: 786px)');

  const fetchApi = () => {
    setloading(true);
    const headers = {
      Authorization: `Bearer ${jwt_token}`,
    };
    axios
      .get(`${baseURL}/api/User-Data/GetSaveSearch`, { headers })
      .then((response) => {
        setData(response?.data?.data);
        // setoriginal(response?.data?.data)
        setloading(false);
      })
      .catch((error) => {
        console.error(error);
        setloading(false);
      });
  }

  useEffect(() => {
    fetchApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderDeviceSizeText(isPhoneScreen) {
    return isPhoneScreen ?
      <>
        {data?.length > 0 &&
          <div className=' d-flex justify-content-end my-3'>
            {/* <button className='font13 btnpadding mx-1 py-1' >Select All</button>
            <button className='font13 mx-1 btnpadding py-1 text-white bg-danger' >Delete(0)</button> */}
          </div>
        }
        {
          loading === true ?
            <center className='mt-5 text-center'>
              < RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="70"
                visible={loading}
              />
            </center>
            :

            data.length > 0 ?

              data.map((row, index) => {
                return <MobileSavedSearch row={row} key={index} />
              })
              :
              <div className="no__FoundMain font  ">
                <div className="">
                  <p>Get notified automatically when your filtered search gets new property matches. To add a new Saved Search, apply your chosen filters, and then
                  select the 'Save' button located below the filter button. Now regularly check your back here or in your inbox for new Properties matching your search filters and property requirements. </p>
                </div>
                <div className=" ">
                  <img className="w-100" src={NotFoundImge} alt="Result not found" loading="lazy"/>
                </div>
              </div>
        }
      </>
      :
      <SavedSearchTable data={data} loading={loading} setloading={setloading} setData={setData} />
  }

  const handleSearchInputChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    setSearchQuery(inputValue);

    if (inputValue.length === 0) {
      fetchApi(); // Fetch the original data if the search query is empty
    } else {
      const filteredData = data.filter((listing) =>
        listing.searchName.toLowerCase().includes(inputValue)
      );
      setData(filteredData);
    }
  };

  return (
    <DashboardLayout>
      <section className='page-content br-page-content'>
        {/* <div className='savedsearch-container'> */}
        <div className='d-flex flexDrirection justify-content-between  mt-2 my_messages_moble_heading'>
          <h3 className='searchtitle mobile_margin_Top3'>Saved Search</h3>
          <div className='mt-3'>
            {/* <div className='filterbox-container'> */}
            {/* <button className=" btnpadding mobilevisibility text-danger">
              Delete (0)
            </button> */}
            {data.length > 0 && <form className='searchbox' onSubmit={(e) => e.preventDefault()}>
              <InputBase
                size='small'
                sx={{ ml: 1, flex: 1, p: 1 }}
                placeholder="Search Here"
                value={searchQuery}
                onChange={handleSearchInputChange} // Add onChange event handler
              />

              <IconButton sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </form>
            }
          </div>
        </div>
        {
          renderDeviceSizeText(isPhoneScreen)
        }
      </section>
      <Footer />
    </DashboardLayout >
  )
}

export default SavedSearchDashboard
