import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReplayIcon from '@mui/icons-material/Replay';
import { useState } from "react";
import {mailRefresh} from "../../../../ReduxStore/Slices/RefreshMail"
import {useDispatch} from "react-redux"
export default function Maillistings({mails, setActiveId, setMailInbox}) {
  const dispatch = useDispatch();
  const [activeEmail, setActiveEmail] = useState(null);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };
  const formatTime = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };
  const passId = (id) => {
    setActiveEmail(id);
    setActiveId(id)
  };
  const ArrowBack =() =>{
    setMailInbox("");
  }
  const fetchNewData=()=>{
    dispatch(mailRefresh(1));
  }
  return (
    <div >
          <div className='d-flex justify-content-between'>
        <p className='Text__Heading All__Mails m-0 p-2'> All Mails</p>
        <div className="d-flex">
        <p className='px-2'><ArrowBackIcon onClick={()=>ArrowBack()} /></p>
        <p className=''><ReplayIcon onClick={()=>fetchNewData()} /></p>
        </div>
      </div>
      <div className="scrollsxe">
      {mails?.fetchedEmails?.map((x) => (      
        <div className={`visit__Status__Mail ${activeEmail === x.id ? "active-email" : ""}`} key={x.id}>
          <div className="row">
            <div className="col-lg-2 col-2 pt-1">
              <div className="d-flex justify-content-end align-items-end left__Badge ">
                <p className="m-0 Message_Badge">{x.messageNumber}</p>
              </div>
              <MailOutlineIcon className="green__Globe" />
            </div>
            <div className="col-lg-8 col-8 p-0">
              <div
                className={`link__Trest  ${activeEmail === x.id ? "active-email" : ""}`}
                onClick={() => passId(x.id)}             
              >
                <p className="m-0 visted___Pomb giveMeEllipsis1">{x.subject}</p>
                <p href="#." className="m-0 text__Grey Links__00">
                  From: {x.from}
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-2 p-0 ">
              <div className="DatenTime">
                <p className="Datsdse m-0">{formatDate(x.dateSent)}</p>
                <p className="Timese m-0">{formatTime(x.dateSent)}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
      </div>
    </div>
  );
}
