import React, { useRef, useState } from 'react';
import DashboardLayout from '../../Dashboard';
import { FiUpload } from 'react-icons/fi';
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControlLabel, MenuItem, Radio, RadioGroup, Select } from '@mui/material';
import KeyboardBackspaceSharpIcon from '@mui/icons-material/KeyboardBackspaceSharp';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from "@mui/icons-material/Delete";
import { addImageData, clearImageData, removeImageData } from '../../../ReduxStore/Slices/ImageUploadSlice';
import { useEffect } from 'react';
import baseURL from '../../../config';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner';


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "1px solid #FFFFFF",
    borderRadius: "12px",
    p: 2,
};


const ListingGallary = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const params = useParams()
    const [images, setImages] = useState([]);
    const [mediaCat, setMediaCat] = useState("");
    const [open, setOpen] = React.useState(false);
    const [caption, setCaption] = useState("");
    const [editOpen, setEditOpen] = React.useState(false);
    const [imgName, setImgName] = useState("");
    const [, setSelectedOption] = useState("");
    const cookies = new Cookies();
    const jwt_token = cookies.get("jwt_authorization");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [PrimaryError, setPrimaryError] = useState(false);
    const [loading, setloading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [imgId, setimgId] = useState("");
    const [imgUrl, setimgUrl] = useState("");
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    useEffect(() => {
        dispatch(clearImageData())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [modalData, setModalData] = useState({
        category: "",
        setAsLogo: "false",
        caption: "",
        imageURL: "",
        id: "",
        assignmentId: "",

    });
    const reduxData = {
        imgName: imgName,
        caption: caption,
        logo: modalData.setAsLogo,
        category: mediaCat,
        imageURL: selectedImage,
    };

    useEffect(() => {
        const headers = {
            Authorization: `Bearer ${jwt_token}`,
        };
        setloading(true);
        axios.get(`${baseURL}/api/Assignment/GetmediaAssignment/${params.id}`, { headers })
            .then(res => {
                if (res?.data?.data) {
                    res?.data?.data.forEach((media) => {
                        const imageData = {
                            assignmentId: media?.assignmentId,
                            imgName: media.fileName,
                            logo: media?.primaryImage.toString(),
                            category: media.mediaCategory,
                            imageURL: `${baseURL}/${media.fileUrl}`,
                            id: media?.id,
                        };
                        dispatch(addImageData(imageData));
                        // setloading(false);
                    });
                }
                setloading(false);
            })
            .catch(err => {
                toast.error('Error',err)
                setloading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])


    const handleUploadClick = (e) => {
        e.preventDefault();
        const hasExistingLogo = imageData.some((e) => e.logo === 'true' && reduxData.logo === 'true');
        if (hasExistingLogo) {
            setPrimaryError(true);
            return;
        }

        setloading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${jwt_token}`,
                'Content-Type': 'multipart/form-data',
            },
        };
        const MediaData = {
            AssignmentId: params?.id,
            PrimaryImage: reduxData.logo === 'true' ? true : false,
            MediaCategory: reduxData.category,
            Verify: true,
            FileName: reduxData.imgName,
            Extension: "jpeg",
        };
        let imageCounter = 1;
        const formData = new FormData();
        formData.append("assignmentmediarequest", JSON.stringify(MediaData));
        images.forEach((image, index) => {
            const imageName = `${imageCounter}_${image.name}.jpeg`;
            formData.append(imageName, image);
            imageCounter++;
        });
        axios
            .post(`${baseURL}/api/Assignment/AddmediaAssignment`, formData, config)
            .then((response) => {
                if (response.data.result === 0) {
                    toast.error(response.data.error[0])
                    setloading(false)
                }
                else {
                    dispatch(addImageData(reduxData));
                    setloading(false)
                }
            })
            .catch((error) => {
                console.error("Error while calling the API:", error);
                setloading(false);
            });

        setOpen(false);
        setCaption("");
        setMediaCat("");
        setSelectedOption("");
        setSelectedImage("");
        setPrimaryError(false);



    };
    const handleEditOpen = (item) => {

        setModalData({
            category: item.category || "",
            setAsLogo: item.logo || 'false',
            caption: item.caption || "",
            imageURL: item.imageURL || "",
            id: item.id,
            assignmentId: item.assignmentId
        });
        setEditOpen(true);
        setPrimaryError(false)
    };
    const handleMediaCatChange = (event) => {
        setMediaCat(event.target.value);
    };


    const handleClose = () => {
        setOpen(false);
        setPrimaryError(false);
    }
    const fileInputRef = useRef(null);

    const handleImageOpen = () => {
        setSelectedImage("");

        fileInputRef.current.click();
    };
    const handleEditClose = () => {
        setEditOpen(false);
        setPrimaryError(false);
    }

    const edittedData = {
        caption: modalData.caption,
        logo: modalData.setAsLogo,
        category: modalData.category,
        imageURL: modalData.imageURL,
        id: modalData.id,
        assignmentId: modalData.assignmentId
    };

    const handleEditUploadClick = () => {
        const hasExistingLogo = imageData.some((e) => e.logo === 'true' && edittedData.logo === 'true' && edittedData.id !== e.id);
        if (hasExistingLogo) {
            setPrimaryError(true);
            return;
        }
        setloading(true);
        const config = {
            headers: {
                Authorization: `Bearer ${jwt_token}`,
                'Content-Type': 'multipart/form-data', // Set the content type for FormData
            },
        };
        const EditedMediaData = {
            Id: edittedData.id,
            AssignmentId: edittedData.assignmentId,
            PrimaryImage: edittedData.logo === "true" ? true : false,
            MediaCategory: edittedData.category
        };
        const formData = new FormData();
        formData.append("editmediarequest", JSON.stringify(EditedMediaData));
        axios
            .put(`${baseURL}/api/Assignment/EditmediaAssignment`, formData, config)
            .then((response) => {
                dispatch(removeImageData(edittedData.imageURL));
                dispatch(addImageData(edittedData));
                setloading(false);
            })
            .catch((error) => {
                console.error("Error while calling the API:", error);
                setloading(false)

            });

        setEditOpen(false);

    };

    const handleImageChange = (evt) => {
        const file = evt.target.files[0];
        if (file) {
            setImgName(file.name);
            setSelectedImage(URL.createObjectURL(file));
            setImages([...images, file]);
            setOpen(true);
        }
    };


    const imageData = useSelector((state) => state.imageUploadSlice);
    const handleBoxDelete = (MediaId, url) => {
        setimgId(MediaId);
        setimgUrl(url);
        handleShowModal();
    };

    const handleDeleteAssignment = () => {
        handleCloseModal();
        // setloading(true);
        const headers = {
            Authorization: `Bearer ${jwt_token}`,
        };
        const apiEndpoint = `${baseURL}/api/Assignment/DeletemediaAssignment/${imgId}`;
        axios
            .delete(apiEndpoint, { headers })
            .then(response => {
                toast.success(response?.data?.message);
                dispatch(removeImageData(imgUrl));
                setloading(false);
            })
            .catch(error => {
                toast.error(error);
                setloading(false);
            });

    }

    return (
        <DashboardLayout>
            <ToastContainer />
            <div className="page-content">
                <div className="pageheight">
                    <Modal
                        open={editOpen}
                        onClose={handleEditClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style} className="mob_ImageBox">
                            <div className="font">
                                <div className="row">
                                    <div className="col-lg-6 col-12 align-center">
                                        <strong>Add Media</strong>{" "}
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <div className="col-lg-3 col-12">
                                        <img
                                            className="randomimages2"
                                            src={modalData.imageURL}
                                            alt="Listing Img"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <p className="font  m-0">
                                            <strong>Set as Primary</strong>
                                        </p>
                                        <FormControl component="fieldset" className="mt-1">
                                            <RadioGroup
                                                aria-label="isPrimary"
                                                name="isPrimary"
                                                value={modalData.setAsLogo}
                                                onChange={(e) =>
                                                    setModalData({ ...modalData, setAsLogo: e.target.value })
                                                }
                                            >
                                                <FormControlLabel value='true' control={<Radio />} label="Yes" />
                                                <FormControlLabel value='false' control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    {PrimaryError === true ? <div className="col-lg-5 text-danger text-center align-center">
                                        <p >Primary Image Already Selected</p>
                                    </div>
                                        : null}

                                </div>
                                <div className="row w-100 mob-ImgField">
                                    <div className="col-12 mob-ImgField">
                                        <p className="font fieldhead m-0">
                                            <strong> Media Category</strong>
                                        </p>
                                        <FormControl fullWidth>
                                            <Select
                                                id="demo-simple-select"
                                                value={modalData.category}
                                                onChange={(e) =>
                                                    setModalData({ ...modalData, category: e.target.value })
                                                }
                                                name="mediaCategory"
                                                displayEmpty
                                            >
                                                <MenuItem value="Exterior">Exterior</MenuItem>
                                                <MenuItem value="Interior">Interior</MenuItem>
                                                <MenuItem value="Floor Plan">Floor Plan</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-12 mt-2 floatright">
                                        <button
                                            onClick={handleEditUploadClick}
                                            className="btn btn-primary"
                                        >
                                            Upload
                                        </button>
                                        <div className="p-1"></div>
                                        <button onClick={handleEditClose} className="btn btn-danger">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Modal>

                    {/* Modal for Add MEdia */}

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style} className="mob_ImageBox">
                            <div className="font">
                                {/* Row for delete button and heading */}

                                <div className="row">
                                    <div className="col-lg-6 col-12 align-center">
                                        <strong>Add Media</strong>{" "}
                                    </div>
                                </div>
                                {/* Row for image icon */}
                                <div className="row p-2">
                                    <div className="col-lg-3 col-12">
                                        <img
                                            className="randomimages2"
                                            src={selectedImage}
                                            alt="Listing Img"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <p className="font  m-0">
                                            <strong>Set as Primary</strong>
                                        </p>
                                        <FormControl component="fieldset" className="mt-1">
                                            <RadioGroup
                                                aria-label="isPrimary"
                                                name="isPrimary"
                                                value={modalData.setAsLogo}
                                                onChange={(e) =>
                                                    setModalData({ ...modalData, setAsLogo: e.target.value })
                                                }
                                            >
                                                <FormControlLabel value='true' control={<Radio />} label="Yes" />
                                                <FormControlLabel value='false' control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    {PrimaryError === true ? <div className="col-lg-5 text-danger text-center align-center">
                                        <p >Primary Image Already Selected</p>
                                    </div>
                                        : null}
                                </div>
                                {/* Row for inputfields */}
                                <div className="row w-100 mob-ImgField">
                                    <form action="" onSubmit={handleUploadClick}>
                                        <div className="col-12 mob-ImgField">
                                            <p className="font fieldhead m-0">
                                                <strong> Media Category</strong>
                                            </p>
                                            <FormControl fullWidth>
                                                <Select
                                                    id="demo-simple-select"
                                                    value={mediaCat}
                                                    onChange={handleMediaCatChange}
                                                    name="mediaCategory"
                                                    displayEmpty
                                                    required
                                                >
                                                    <MenuItem value="Exterior">Exterior</MenuItem>
                                                    <MenuItem value="Interior">Interior</MenuItem>
                                                    <MenuItem value="Floor Plan">Floor Plan</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className="col-12 mt-2 floatright">
                                            <button type="submit" className="btn btn-primary">
                                                Upload
                                            </button>
                                            <div className="p-1"></div>
                                            <button onClick={handleClose} className="btn btn-danger">
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </Box>
                    </Modal>

                    {/* Modal for delete   */}
                    <Dialog
                        open={showModal}
                        onClose={handleCloseModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure want to delete this Image.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseModal}>No</Button>
                            <Button onClick={handleDeleteAssignment} autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <div className='mobile4remMarginTop'>
                    <div className="headingtitlelister my-3">
                        <h3 className="Single_allListingHeading m-0 d-flex align-items-center">
                            <ArrowBackIosNewIcon className='mx-1' style={{fontSize:'18px'}} onClick={() => navigate(-1)}/>
                            <span style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>Media Gallery</span>{" "}
                        </h3>
                    </div>

                        {loading ? (
                            <center className="text-center">
                                <RotatingLines
                                    strokeColor="grey"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="70"
                                    visible={loading}
                                />
                            </center>
                        ) :
                            < div className="row  row-with-gap pb-4 mobileresponsive">
                                <div className="col-lg-3 cardwidth col-12  boxupload">
                                    <div onClick={handleImageOpen} className="x">
                                        <FiUpload className="FiUpload" />
                                    </div>
                                    <p className="m-0 pt-2 uploadfile">
                                        <strong>Upload a file </strong>
                                    </p>
                                    <p className="m-0 pt-2 uploadfiletxt">
                                        Please select Image files only
                                    </p>
                                    <input
                                        accept="image/jpeg, image/png"
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: "none" }}
                                        onChange={handleImageChange}
                                    />
                                </div>

                                {imageData?.map((item) => (
                                    <div
                                        key={item.imageURL}
                                        className="col-lg-3 cardwidth col-12 p-2 borderoncard"
                                    >

                                        <div style={{ position: "relative" }}>
                                            <img
                                                className="randimages w-100"
                                                src={item.imageURL}
                                                alt="Listing Img"
                                                loading='lazy'
                                            />
                                            {item.logo === 'true' ? (
                                                <div style={{ position: "absolute", bottom: 0, right: 5 }}>
                                                    <p className="mainstyling primary-bg">Primary</p>
                                                </div>
                                            ) : null}

                                            <div style={{ position: "absolute", top: 10, right: 10 }}>
                                                <p className="bin custom-cursor">
                                                    <DeleteIcon
                                                        onClick={() => {
                                                            handleBoxDelete(item.id, item.imageURL);
                                                        }}
                                                    />
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 ">
                                                <p className="m-0 p-2">
                                                    <strong>{item.caption}</strong>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row p-2 displayBlock">
                                            <span className="">
                                                <button className="cardsims">Image</button>
                                            </span>
                                            {item.category && (
                                                <>
                                                    <span className="">
                                                        <button className="cardsime">{item.category}</button>
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                        <div className="row mt-4">
                                            <div className="col-12 w-100">
                                                {" "}
                                                <button
                                                    onClick={() => handleEditOpen(item)}
                                                    className="w-100 sdfe"
                                                >
                                                    Edit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
            </div>


        </DashboardLayout >
    )


}

export default ListingGallary;