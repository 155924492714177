import React, { useEffect, useRef, useState } from "react";
import "./RealtorProfile.css";
import "@fontsource/open-sans";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";
import Navbar from "../Navbar/Navbar";
import AboutProperty from "../PropertyDetails/SubComponents/AboutProperty";
import PropertyFaqs from "../PropertyDetails/SubComponents/PropertyFaqs";
import QueryCard from "../PropertyDetails/SubComponents/QueryCard";
import NearbyCities from "../PropertyDetails/SubComponents/NearByCities";
import { Link } from "react-scroll";
import ListingComparable from "../PropertyDetails/SubComponents/ListingComparable";
import PopularSearches from "../PropertyDetails/SubComponents/PopularSearches";
import AgentQueryModal from "../PropertyDetails/SubComponents/Modals/AgentQueryModal";
import Footer from "../Footer/Footer";
import PropertyNotes from "../PropertyDetails/SubComponents/PropertyNotes";
import RatingModal from "../PropertyDetails/SubComponents/Modals/RatingModal";
import FeaturedListing from "../PropertyDetails/SubComponents/FeaturedListing";
import Cookies from "universal-cookie";
import WatchNeigbourHoodModal from "../PropertyDetails/SubComponents/Modals/WatchNeighbourHoodModal";
import SignUpModal from "../Modals/SignUpModal";
import ListingSoldModal from "../PropertyDetails/SubComponents/Modals/ListingSoldModal";
import { toast, ToastContainer } from "react-toastify";
import EmailConfirmationModal from "../Modals/EmaiConfirmationModal";
import CommuteModal from "../PropertyDetails/SubComponents/Modals/CommuteModal";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import PropertyComment from "../PropertyDetails/SubComponents/PropertyComment";
import PropertyHeader from "../PropertyDetails/SubComponents/PropertyHeader";
import { NeedHomeBaseURL } from "../../config";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PropertyData } from "../PropertyDetails/PropertData";
import RealtorHeader from "./SubComponents/RealtorHeader";
import TransactionHistory from "./SubComponents/TransactionHistory";
import AboutRealtor from "./SubComponents/About";
import WiningAwards from "./SubComponents/WiningAwards";
import SocialPresence from "./SubComponents/SocialPresence";
import ContactCard from "./SubComponents/ContactCard";
import ServicesAreas from "./SubComponents/ServicesAreas";
import ReviewsSummary from "./SubComponents/ReviewsSummary";
import ProductionOverTime from "./SubComponents/ProductionOverTime";
import RealtorArticles from "./SubComponents/Articles";
import RealtorInNews from "./SubComponents/RealtorInNews";
import NeighbourhoodSale from "./SubComponents/NeighbourhoodSale";

const RealtorProfile = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const jwt_token = cookies.get("jwt_authorization");
  const userData = useSelector((state) => state.userProfileDataSlice);
  const originurls = window.location.pathname;
  const pathParts = originurls.split("/");
  const PropertySlug = pathParts[pathParts.length - 1];
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const lastScrollTopRef = useRef(0);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [isNotifyWhenSoldModalOpen, setIsNotifyWhenSoldModalOpen] =
    useState(false);
  const [isNeighborhoodModalOpen, setIsNeighborhoodModalOpen] = useState(false);
  const [isCommuteModalOpen, setIsCommuteModalOpen] = useState(false);
  const [isEmailConfirmedModal, setIsEmailConfirmedModal] = useState(false);
  const [isNotifyLoading, setIsNotifyLoading] = useState(false);
  const [signUpModalHeading, setSignUpModalHeading] =
    useState("Create an account");

  //Hide or Show Navbar
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTopRef.current && !isScrollingDown) {
        setIsScrollingDown(true);
      } else if (
        currentScrollTop < lastScrollTopRef.current &&
        isScrollingDown
      ) {
        setIsScrollingDown(false);
      }

      lastScrollTopRef.current = currentScrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrollingDown]);

  //Handle Mobile View
  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 768);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Fetch Property Data
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${NeedHomeBaseURL}/api/Preconstruction/GetPreconstructionDetail/${PropertySlug}`
      );
      if (response?.data?.result === 1) {
        setData(response?.data?.data);
        setIsLoading(false);
      } else {
        toast.error("There is no data for this Property");
        navigate("/");
      }
    } catch (error) {
      toast.error("There is problem in fetching data");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // eslint-disable-next-line
  }, [PropertySlug]);

  const handleSignUpModalClose = () => {
    setIsSignUpModalOpen(false);
    setSignUpModalHeading("Create an account");
  };

  const handleInfoModalClick = () => {
    setIsInfoModalOpen(true);
  };

  const handleInfoModalClose = () => {
    setIsInfoModalOpen(false);
  };

  const handleRatingModalClose = () => {
    setRatingModalOpen(false);
  };

  const handleWatchNeighborHoodClick = () => {
    if (jwt_token) {
      setIsNeighborhoodModalOpen(true);
    } else {
      setIsSignUpModalOpen(true);
      setSignUpModalHeading("Join us to view nearby Listings!");
    }
  };

  const handleNeighbourHoodModalClose = () => {
    setIsNeighborhoodModalOpen(false);
  };

  const handleNotifyWhenSoldClick = async () => {
    if (jwt_token && userData[0]?.id) {
      setIsNotifyLoading(true);
      console.log(userData[0]?.id);
      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };
      const Notifydata = {
        Id: "0",
        ProId: data?.uId, //UId of listing
        UserId: userData[0]?.id,
      };
      try {
        const response = await axios.post(
          `${NeedHomeBaseURL}/api/AuthData/AddNotify`,
          Notifydata,
          {
            headers,
          }
        );
        if (response?.data?.result === 1) {
          setIsNotifyWhenSoldModalOpen(true);
          setIsNotifyLoading(false);
        } else {
          toast.error("Something went wrong");
          setIsNotifyLoading(false);
        }
      } catch (error) {
        toast.error(error.message);
        setIsNotifyLoading(false);
      }
    } else {
      setIsSignUpModalOpen(true);
      setSignUpModalHeading("Join us to view sold Listings!");
    }
  };

  const handleNotifyWhenSoldModalClose = () => {
    setIsNotifyWhenSoldModalOpen(false);
  };

  const handleCommuteModalClick = () => {
    if (jwt_token) {
      setIsCommuteModalOpen(true);
    } else {
      setIsSignUpModalOpen(true);
      setSignUpModalHeading("Join us to navigate Your Commute!");
    }
  };

  const handleEmailConfirmedModalClose = () => {
    setIsEmailConfirmedModal(false);
  };

  const sections = [
    { id: "about", label: "About Umar Khan" },
    { id: "winingAwards", label: "Wining Awards" },
    { id: "socialPresence", label: "Social Media Presence" },
    { id: "transactionHistory", label: "Transaction History" },
    { id: "activeListings", label: "Umar khan Active Listing" },
    { id: "teamListings", label: "Khan Team Active Listing" },
    { id: "faq", label: "FAQ" },
    { id: "queryCard", label: "Ask a question" },
  ];

  return (
    <>
      {isLoading ? (
        <div className="container mt-3">
          <Skeleton animation="wave" variant="rounded" height={300} />
          <Skeleton
            variant="text"
            animation="wave"
            sx={{ fontSize: "1.5rem" }}
          />
          <Skeleton variant="text" animation="wave" sx={{ fontSize: "1rem" }} />
        </div>
      ) : (
        <>
          <div
            style={{
              position: "sticky",
              top: 0,
              zIndex: isScrollingDown ? 100 : 1100,
              transition: "all ease .4s",
            }}
          >
            <Navbar />
          </div>
          <PropertyHeader
            data={data}
            isUserlogin={jwt_token}
            setIsSignUpModalOpen={setIsSignUpModalOpen}
            handleWatchNeighborHoodClick={handleWatchNeighborHoodClick}
            handleNotifyWhenSoldClick={handleNotifyWhenSoldClick}
            isNotifyLoading={isNotifyLoading}
            handleInfoModalClick={handleInfoModalClick}
            handleCommuteModalClick={handleCommuteModalClick}
            isMobileView={isMobileView}
            isScrollingDown={isScrollingDown}
          />
          <div className="container Propert_details_main_container realtor_main_container">
            <div>
              <RealtorHeader />
            </div>

            <div className="row mt-5">
              <div className="col-lg-8 col-12">
                <div id="aboutRealtor">
                  <AboutRealtor isMobileView={isMobileView} />
                </div>
                <div id="winingAwards" className="Prop_details_CompMargin">
                  <WiningAwards isMobileView={isMobileView} />
                </div>
                <div id="socialPresence" className="Prop_details_CompMargin">
                  <SocialPresence isMobileView={isMobileView} />
                </div>
                <div
                  id="transactionHistory"
                  className="Prop_details_CompMargin"
                >
                  <TransactionHistory isMobileView={isMobileView} />
                </div>

                <div
                  id="ProductionOverTime"
                  className="Prop_details_CompMargin"
                >
                  <ProductionOverTime isMobileView={isMobileView} />
                </div>

                <div
                  id="expertiseNeighbour"
                  className="Prop_details_CompMargin"
                >
                  <NeighbourhoodSale isMobileView={isMobileView} />
                </div>

                <div
                  id="featuredListing"
                  className="PropDetails_BorderNoneMargin"
                >
                  <FeaturedListing isMobileView={isMobileView} />
                </div>
                <div
                  id="compareListing"
                  className="PropDetails_BorderNoneMargin"
                >
                  <ListingComparable
                    setIsSignUpModalOpen={setIsSignUpModalOpen}
                    setSignUpModalHeading={setSignUpModalHeading}
                    isUserlogin={jwt_token}
                    isMobileView={isMobileView}
                  />
                </div>
                <div id="articles" className="Prop_details_CompMargin">
                  <RealtorArticles isMobileView={isMobileView} />
                </div>
                <div id="realtorInNews" className="Prop_details_CompMargin">
                  <RealtorInNews isMobileView={isMobileView} />
                </div>
                <div id="reviewsSummary" className="Prop_details_CompMargin">
                  <ReviewsSummary isMobileView={isMobileView} />
                </div>
                <div
                  id="faq"
                  className="Prop_details_CompMargin Details_mbl_margin"
                >
                  <PropertyFaqs data={PropertyData?.FAQs} />
                </div>
                <div
                  id="queryCard"
                  className="Prop_details_CompMargin Details_mbl_margin"
                >
                  <QueryCard handleInfoModalClick={handleInfoModalClick} />
                </div>
                <div
                  id="propertyComment"
                  className="Prop_details_CompMargin Details_mbl_margin"
                >
                  <PropertyComment />
                </div>
                <div
                  id="aboutProperty"
                  className="Prop_details_CompMargin Details_mbl_margin"
                >
                  <AboutProperty data={data} isMobileView={isMobileView} />
                </div>
                <div id="servicesAreas" className="Prop_details_CompMargin">
                  <ServicesAreas isMobileView={isMobileView} />
                </div>
                <div id="popularSearches" className="Prop_details_CompMargin">
                  <PopularSearches isMobileView={isMobileView} />
                </div>
                <div id="nearByCities" className="Prop_details_CompMargin">
                  <NearbyCities isMobileView={isMobileView} />
                </div>
              </div>

              {!isMobileView && (
                <div className="col-lg-4 col-12 PropDetailsMob_lg_hide">
                  <div
                    className={`position-sticky ${
                      isScrollingDown
                        ? "Stick_headerOnly"
                        : "prop_represent_linkStatic"
                    }  `}
                  >
                    <div>
                      <ContactCard
                        handleInfoModalClick={handleInfoModalClick}
                      />
                    </div>

                    <div>
                      <PropertyNotes
                        data={data}
                        setIsSignUpModalOpen={setIsSignUpModalOpen}
                        isUserlogin={jwt_token}
                        setSignUpModalHeading={setSignUpModalHeading}
                      />
                    </div>

                    <div className="Prop_details_section_box mt-3">
                      <ul>
                        {sections?.map((section, index) => (
                          <li key={index}>
                            <Link
                              spy={true}
                              activeClass="active"
                              offset={-100}
                              smooth={true}
                              duration={500}
                              to={section.id}
                            >
                              {section.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {isSignUpModalOpen && (
              <SignUpModal
                open={isSignUpModalOpen}
                handleClose={handleSignUpModalClose}
                setIsSignUpModalOpen={setIsSignUpModalOpen}
                setIsEmailConfirmedModal={setIsEmailConfirmedModal}
                heading={signUpModalHeading}
              />
            )}

            {isInfoModalOpen && (
              <AgentQueryModal
                open={isInfoModalOpen}
                handleClose={handleInfoModalClose}
              />
            )}

            {ratingModalOpen && (
              <RatingModal
                data={data}
                open={ratingModalOpen}
                handleClose={handleRatingModalClose}
                setIsSignUpModalOpen={setIsSignUpModalOpen}
                setSignUpModalHeading={setSignUpModalHeading}
                isUserlogin={jwt_token}
              />
            )}

            {isNeighborhoodModalOpen && (
              <WatchNeigbourHoodModal
                open={isNeighborhoodModalOpen}
                handleClose={handleNeighbourHoodModalClose}
                isUserlogin={jwt_token}
              />
            )}

            {isNotifyWhenSoldModalOpen && (
              <ListingSoldModal
                open={isNotifyWhenSoldModalOpen}
                handleClose={handleNotifyWhenSoldModalClose}
                setIsSignUpModalOpen={setIsSignUpModalOpen}
                setSignUpModalHeading={setSignUpModalHeading}
              />
            )}

            {isCommuteModalOpen && (
              <CommuteModal
                latitude={data?.lat}
                longitude={data?.lng}
                open={isCommuteModalOpen}
                setIsCommuteModalOpen={setIsCommuteModalOpen}
                setIsSignUpModalOpen={setIsSignUpModalOpen}
                setSignUpModalHeading={setSignUpModalHeading}
              />
            )}

            {isEmailConfirmedModal && (
              <EmailConfirmationModal
                open={isEmailConfirmedModal}
                handleClose={handleEmailConfirmedModalClose}
              />
            )}
          </div>

          <div className="Prop_detailsMobileFixedBtn prop_details_BtnShow Property_detailsBtn_hide">
            <button
              className="propertyDetails_moreInfo PropDetails_gradientBtn PropDetails_gradientBtn"
              onClick={handleInfoModalClick}
            >
              Book this Agent
            </button>
            <button
              className="propertyDetails_moreInfo NoteBtn"
              onClick={() => {
                if (jwt_token) {
                  setRatingModalOpen(true);
                } else {
                  setIsSignUpModalOpen(true);
                  setSignUpModalHeading(
                    "Become a member to access & Write Research Notes."
                  );
                }
              }}
            >
              Research Note
            </button>
          </div>
        </>
      )}

      <div style={{ marginTop: "6rem" }}>
        <Footer />
      </div>

      <ToastContainer />
    </>
  );
};

export default RealtorProfile;
