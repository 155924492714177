import baseURL from "../../../config";
import { AllPhotoIcons } from "../../../SvgIcons/Icons";
import { memo } from "react";

const PropertyImages = ({ data, setImageModalOpen, projectName,setActiveSlide }) => {
  const handleImageModalOpen = (sliderImg) => {
    if (data?.length > 0) {
      setImageModalOpen(true);
      setActiveSlide(sliderImg)
    }
  };

  return (
    <>
      <div className="Prop_details_galleryContiner">
        <div
          className="PropDetails_galleryMainImg Prop_thubnail_img position-relative property_details_mainImage"
          onClick={()=>handleImageModalOpen(0)}
        >
          {data?.length > 0 ? (
            <img
              src={`${baseURL}/${data[0]?.fileUrl}`}
              alt={projectName}
              loading="lazy"
              style={{objectFit: 'cover'}}
            />
          ) : (
            <img
              src="/images/PropertyNoImage.png"
              alt={projectName}
              loading="lazy"
              style={{objectFit:'cover'}}
            />
          )}
        </div>
        <div className="PropDetails_gallerySubImgContainer PropDetailsMob_lg_hide">
          <div
            className="PropDetails_gallerySubImg"
            onClick={()=>handleImageModalOpen(1)}
          >
            {data?.length > 1 ? (
              <img
                src={`${baseURL}/${data[1]?.fileUrl}`}
                alt={projectName}
                loading="lazy"
                style={{objectFit: 'cover'}}
              />
            ) : (
              <img
                src="/images/PropertyNoImage.png"
                alt={projectName}
                loading="lazy"
                style={{objectFit: 'cover'}}
              />
            )}
          </div>
          <div className="PropDetails_gallerySubImg2Container">
            <div
              className="PropDetails_gallerySubimg2"
              onClick={()=>handleImageModalOpen(2)}
            >
              {data?.length > 2 ? (
                <img
                  src={`${baseURL}/${data[2]?.fileUrl}`}
                  alt={projectName}
                  loading="lazy"
                  style={{objectFit: 'cover'}}
                />
              ) : (
                <img
                  src="/images/PropertyNoImage.png"
                  alt={projectName}
                  loading="lazy"
                  style={{objectFit: 'cover'}}
                />
              )}
            </div>
            <div
              className="PropDetails_gallerySubimg2"
              onClick={()=>handleImageModalOpen(3)}
            >
              {data?.length > 3 ? (
                <img
                  src={`${baseURL}/${data[3]?.fileUrl}`}
                  alt={projectName}
                  loading="lazy"
                  style={{objectFit: 'cover'}}
                />
              ) : (
                <img
                  src="/images/PropertyNoImage.png"
                  alt={projectName}
                  loading="lazy"
                  style={{objectFit: 'cover'}}
                />
              )}
            </div>
          </div>
        </div>
        {data?.length > 0 && (
          <button
            className="PropDetails_allImgBtn d-flex align-items-center"
            onClick={()=>handleImageModalOpen(0)}
          >
            <AllPhotoIcons /> <span>{`View all ${data?.length} Photos`}</span>
          </button>
        )}
      </div>
    </>
  );
};

export default memo(PropertyImages);
