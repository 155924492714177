import * as Yup from 'yup'
import { isValidPhoneNumber } from 'react-phone-number-input'

const ScheduleScheme = Yup.object({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Invalid email format'
    )
    .required('This field is required'),
  firstName: Yup.string().min(1).max(50).required('This field is required'),
  lastName: Yup.string().min(1).max(50).required('This field is required'),
  phone: Yup.string()
    .test('is-valid-phone', 'Invalid phone number', (value, { parent }) => {
      const selectedCountry = parent.selectedCountry
      if (!selectedCountry) {
        return true
      }

      return isValidPhoneNumber(value, selectedCountry)
    })
    .required('Phone number is required'),
  schedule: Yup.string().min(5).max(1000).required('This field is required')
})
export default ScheduleScheme
