const AgentCarddata = [
  {
    imgsrcs: require("../../../../images/pic1.jpeg"),
    name: "Darlene Robertson",
    city: "HomeSmart",
    number: "+1 (903) 679-96-15",
    totalsales: "364",
    torrontosales: "300",
    pricerange: "$690K - $15.6M",
  },
  {
    imgsrcs: require("../../../../images/pic2.jpeg"),
    name: "Darlene Robertson",
    city: "HomeSmart",
    number: "+1 (903) 679-96-15",
    totalsales: "364",
    torrontosales: "300",
    pricerange: "$690K - $15.6M",
  },
  {
    imgsrcs: require("../../../../images/pic3.jpeg"),
    name: "Darlene Robertson",
    city: "HomeSmart",
    number: "+1 (903) 679-96-15",
    totalsales: "364",
    torrontosales: "300",
    pricerange: "$690K - $15.6M",
  },
  {
    imgsrcs: require("../../../../images/pic1.jpeg"),
    name: "Darlene Robertson",
    city: "HomeSmart",
    number: "+1 (903) 679-96-15",
    totalsales: "364",
    torrontosales: "300",
    pricerange: "$690K - $15.6M",
  },

  {
    imgsrcs: require("../../../../images/pic3.jpeg"),
    name: "Darlene Robertson",
    city: "HomeSmart",
    number: "+1 (903) 679-96-15",
    totalsales: "364",
    torrontosales: "300",
    pricerange: "$690K - $15.6M",
  },
  {
    imgsrcs: require("../../../../images/pic2.jpeg"),
    name: "Darlene Robertson",
    city: "HomeSmart",
    number: "+1 (903) 679-96-15",
    totalsales: "364",
    torrontosales: "300",
    pricerange: "$690K - $15.6M",
  },
  {
    imgsrcs: require("../../../../images/pic1.jpeg"),
    name: "Darlene Robertson",
    city: "HomeSmart",
    number: "+1 (903) 679-96-15",
    totalsales: "364",
    torrontosales: "300",
    pricerange: "$690K - $15.6M",
  },
  {
    imgsrcs: require("../../../../images/pic2.jpeg"),
    name: "Darlene Robertson",
    city: "HomeSmart",
    number: "+1 (903) 679-96-15",
    totalsales: "364",
    torrontosales: "300",
    pricerange: "$690K - $15.6M",
  },
  {
    imgsrcs: require("../../../../images/pic3.jpeg"),
    name: "Darlene Robertson",
    city: "HomeSmart",
    number: "+1 (903) 679-96-15",
    totalsales: "364",
    torrontosales: "300",
    pricerange: "$690K - $15.6M",
  },
  {
    imgsrcs: require("../../../../images/pic1.jpeg"),
    name: "Darlene Robertson",
    city: "HomeSmart",
    number: "+1 (903) 679-96-15",
    totalsales: "364",
    torrontosales: "300",
    pricerange: "$690K - $15.6M",
  },

  {
    imgsrcs: require("../../../../images/pic3.jpeg"),
    name: "Darlene Robertson",
    city: "HomeSmart",
    number: "+1 (903) 679-96-15",
    totalsales: "364",
    torrontosales: "300",
    pricerange: "$690K - $15.6M",
  },
  {
    imgsrcs: require("../../../../images/pic2.jpeg"),
    name: "Darlene Robertson",
    city: "HomeSmart",
    number: "+1 (903) 679-96-15",
    totalsales: "364",
    torrontosales: "300",
    pricerange: "$690K - $15.6M",
  },
];

export default AgentCarddata;
