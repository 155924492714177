import React, { useState, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  ArticleNextReadIcon,
  NeighbourSalesIcon,
} from "../../../SvgIcons/Icons";

const NeighbourhoodSales = ({ isMobileView }) => {
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex flex-column" : ""}>
          <div className="d-flex align-items-center gap-2">
            {isMobileView && <span><NeighbourSalesIcon size={21} color={'#282828'}/></span>}
            <h2 className="property_Details_heading">
              Umar Khan’s Expertise Neighborhoods
            </h2>
          </div>
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
            This extensive experience across these areas highlights Umar's deep
            understanding of the local real estate markets and his ability to
            effectively serve clients in these communities.
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className="w-100 mt-3  mx-0 realtor_neighbourSale_container">
          <div>
            <div className="position-relative realtor_neighbourSale">
              <img
                src="https://config.assignmentsold.ca/Attachments/Assignment/00168611-Screenshot%202024-10-22%20174649.png"
                alt="neighbour-sale"
                loading="lazy"
                className="small_image"
              />
              <div className="sales_inf position-absolute">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">McMurray Borough</h5>
                  <span>
                    <ArticleNextReadIcon color={"#FFFF"} size={14} />
                  </span>
                </div>
                <p>3 Sales</p>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="position-relative realtor_neighbourSale">
              <img
                src="https://config.assignmentsold.ca/Attachments/Assignment/00168611-Screenshot%202024-10-22%20174649.png"
                alt="neighbour-sale"
                loading="lazy"
                className="small_image"
              />
              <div className="sales_inf position-absolute">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">McMurray Borough</h5>
                  <span>
                    <ArticleNextReadIcon color={"#FFFF"} size={14} />
                  </span>
                </div>
                <p>3 Sales</p>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="position-relative realtor_neighbourSale">
              <img
                src="https://config.assignmentsold.ca/Attachments/Assignment/00168611-Screenshot%202024-10-22%20174649.png"
                alt="neighbour-sale"
                loading="lazy"
                className="big_image"
              />
              <div className="sales_inf position-absolute">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">McMurray Borough</h5>
                  <span>
                    <ArticleNextReadIcon color={"#FFFF"} size={14} />
                  </span>
                </div>
                <p>3 Sales</p>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="position-relative realtor_neighbourSale">
              <img
                src="https://config.assignmentsold.ca/Attachments/Assignment/00168611-Screenshot%202024-10-22%20174649.png"
                alt="neighbour-sale"
                loading="lazy"
                className="big_image"
              />
              <div className="sales_inf position-absolute">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">McMurray Borough</h5>
                  <span>
                    <ArticleNextReadIcon color={"#FFFF"} size={14} />
                  </span>
                </div>
                <p>3 Sales</p>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="position-relative realtor_neighbourSale">
              <img
                src="https://config.assignmentsold.ca/Attachments/Assignment/00168611-Screenshot%202024-10-22%20174649.png"
                alt="neighbour-sale"
                loading="lazy"
                className="small_image"
              />
              <div className="sales_inf position-absolute">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">McMurray Borough</h5>
                  <span>
                    <ArticleNextReadIcon color={"#FFFF"} size={14} />
                  </span>
                </div>
                <p>3 Sales</p>
              </div>
            </div>
          </div>
          <div className="mt-2">
            <div className="position-relative realtor_neighbourSale">
              <img
                src="https://config.assignmentsold.ca/Attachments/Assignment/00168611-Screenshot%202024-10-22%20174649.png"
                alt="neighbour-sale"
                loading="lazy"
                className="small_image"
              />
              <div className="sales_inf position-absolute">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">McMurray Borough</h5>
                  <span>
                    <ArticleNextReadIcon color={"#FFFF"} size={14} />
                  </span>
                </div>
                <p>3 Sales</p>
              </div>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(NeighbourhoodSales);
