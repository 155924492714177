import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormControl, FormLabel } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";
import { Box, Modal } from "@mui/material";
import { CloseIcon } from "../../../../SvgIcons/Icons";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import baseURL from "../../../../config";
import { toast } from "react-toastify";

const AgentQueryModal = ({ data, open, handleClose }) => {
  const [fadeOut, setFadeOut] = useState(false);
  const modalRef = useRef(null);

  const closeModal = () => {
    setFadeOut(true);
    setTimeout(() => {
      handleClose();
      setFadeOut(false);
    }, 300);
  };

  useEffect(() => {
    if (open && modalRef.current) {
      modalRef.current.classList.remove("Prop_detail_infoModal_fadeOut");
    }
  }, [open]);

  const RequestSchema = Yup.object({
    FirstName: Yup.string().required("Name is required"),
    Email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      )
      .required("Email is required"),
    Message: Yup.string().required("Message is required"),
    Phone: Yup.string()
      .test("is-valid-phone", "Invalid phone number", (value) =>
        isValidPhoneNumber(value)
      )
      .required("Phone number is required"),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handlePhoneInputChange = (value) => {
    formik.setFieldValue("Phone", value);
  };

  useEffect(() => {
    const fullName = localStorage.getItem("fullName") || "";
    const email = localStorage.getItem("email") || "";
    const phoneNumber = localStorage.getItem("phoneNumber") || "";

    formik.setValues({
      FirstName: fullName,
      Email: email,
      Phone: phoneNumber,
      Message: `I want to book an appointment to discuss ${data?.projectName}, ${data?.address} - ${data?.city}`,
    });
    // eslint-disable-next-line
  }, []);

  const formInitialValues = {
    FirstName: "",
    Email: "",
    Phone: "",
    Message: "",
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: RequestSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      const formData = {
        FirstName: values.FirstName,
        Email: values.Email,
        Phone: values.Phone,
        Offer: values.Message,
        ListingTitle: data?.projectName,
        ListingSlug: data?.slug,
        clickedBy: "Message",
      };
      const jsonData = JSON.stringify(formData);
      try {
        const response = await axios.post(
          `${baseURL}/api/ChatApp/CreateConversation`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response?.data?.result === 1) {
          setIsLoading(false);
          toast.success(response?.data?.message);
          handleClose();
          resetForm();
        } else {
          toast.error(response?.data?.error[0]);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        toast.error("Message not submitted");
      }
    },
  });

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={`Prop_detail_infoModal AgentQuery_modal ${
          fadeOut ? "Prop_detail_infoModal_fadeOut" : ""
        }`}
        ref={modalRef}
      >
        <div className="row m-0 w-100">
          <div className="col-md-6 col-12">
            <div className="Propert_detailRepresentatorCard">
              <img
                src="https://config.assignmentsold.ca/Attachments/Newhomez-Agent-Profile.jpg"
                alt="Representator Profile"
                loading="lazy"
              />
            </div>
            <h6 className="mt-3">Talha Chishti</h6>
            <p>Sales Representative</p>
            <p>AssignmentSold.ca Inc.</p>
            <p className="fw-bold">(647) 649 0400</p>
          </div>

          <div className="col-md-6 col-12">
            <form onSubmit={formik.handleSubmit}>
              <div className="mobile_marginTop">
                <FormLabel> Your Name</FormLabel>
                <br />
                <FormControl className="w-100">
                  <input
                    value={formik.values.FirstName}
                    onChange={formik.handleChange}
                    onKeyDown={(event) => {
                      if (!/^[a-zA-Z\s]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    name="FirstName"
                    placeholder="Your Name"
                    type="text"
                    className="request_Info_input"
                  />
                  {isSubmitted ? (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {formik.errors.FirstName}
                    </span>
                  ) : null}
                </FormControl>
              </div>

              <div style={{ marginTop: "12px" }}>
                <FormLabel>Email</FormLabel>
                <br />
                <FormControl className="w-100">
                  <input
                    value={formik.values.Email}
                    onChange={formik.handleChange}
                    name="Email"
                    placeholder="Email address"
                    type="text"
                    className="request_Info_input"
                  />
                  {isSubmitted ? (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {formik.errors.Email}
                    </span>
                  ) : null}
                </FormControl>
              </div>

              <div style={{ marginTop: "12px" }}>
                <FormLabel>Contact number</FormLabel>
                <br />
                <FormControl className="w-100">
                  <PhoneInput
                    size="small"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="CA"
                    value={formik.values.Phone}
                    onChange={handlePhoneInputChange}
                    name="Phone"
                    id="Phone"
                    className="Phone_input signUp_numberInput"
                    placeholder="Phone Number"
                  />
                  {isSubmitted ? (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {formik.errors.Phone}
                    </span>
                  ) : null}
                </FormControl>
              </div>

              <div style={{ marginTop: "12px" }}>
                <FormLabel>Still have a question</FormLabel>
                <br />
                <FormControl className="w-100 PropertyDetails_notes">
                  <textarea
                    value={formik.values.Message}
                    onChange={formik.handleChange}
                    name="Message"
                    placeholder="Type here ..."
                    className="request_Info_input"
                    style={{ height: "70px" }}
                  />
                  {isSubmitted ? (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {formik.errors.Message}
                    </span>
                  ) : null}
                </FormControl>
              </div>

              {isLoading ? (
                <div className="aligncenter justifycenter mt-3">
                  <RotatingLines width={50} strokeColor="grey" />
                </div>
              ) : (
                <div>
                  <button
                    type="submit"
                    onClick={() => {
                      formik.handleSubmit();
                      setIsSubmitted(true);
                    }}
                    style={{ marginTop: "12px" }}
                  >
                    Request for info
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
        <div
          className="position-absolute Property_detailsWeb_hide"
          style={{ top: "20px", right: "15px" }}
          onClick={closeModal}
        >
          <CloseIcon />
        </div>
      </Box>
    </Modal>
  );
};

export default AgentQueryModal;
