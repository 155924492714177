
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import baseURL from '../../../config';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { removeItem } from '../../../ReduxStore/Slices/LoginSlice';
import { removeProfileData } from '../../../ReduxStore/Slices/ProfileDataSlice';
import Cookies from 'universal-cookie';
import { useDispatch } from 'react-redux';

const ConfirmationEmail = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const Email = queryParams.get('Email');
  const Code = queryParams.get('code');
  const [loading, setloading] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const cookies = new Cookies();
  useEffect(() => {
    setloading(true);
    axios.post(`${baseURL}/api/ConfirmEmail`, { Email, Code })
      .then((response) => {
        if (response.data.result === 1) {
          setloading(false);
          cookies.remove("jwt_authorization", { path: "/" }); // Specify the correct path
          // cookies.remove("jwt_authorization" , {path: '/Security'});
          // cookies.remove("jwt_authorization", { domain: 'localhost:3000' }); // Specify the correct domain
          dispatch(removeItem());
          dispatch(removeProfileData());
          localStorage.setItem("anonymous", 0);
          setTimeout(() => {
            navigate("/login")
          }, 3000);
        } else {
          alert(response.data.message)
          navigate("/dashboard/profile")
        }
      })
      .catch((error) => {
        console.error(error);
        alert(error.message)
      });
          // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate,dispatch]);

  // Now, you can use the Email and code values in your component

  return (
    <center>
      <img
        className="imgclass1"
        src={require("../../../Components/Images/icon.png")}
        alt="AssignmentSold Logo"
        loading="lazy"
      />
      {loading === true ?
        <h1 className='mt-2'>Verifying Email... </h1>
        :
        <div className='mt-2'>
          <h1>  Verification Complete ...</h1>

          <h3> Redirecting to Login ...</h3>

        </div>

      }
    </center>
  );
};

export default ConfirmationEmail;
