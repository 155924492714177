import * as Yup from 'yup';

export const LoginSchema = Yup.object({
    email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format"
    )
    .required("Email is required"),
    password:Yup.string().min(8, "Password must be 8 characters long").max(500).required("Password is required"),
    
});