import React, { useRef, useState } from "react";
import "../../SignUp/Signup.css";
import "../../../Components.css";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import Loader from "../../Loader/Loader";
import baseURL from "../../../config";
import { useNavigate } from "react-router-dom";
import { addProfileData } from "../../../ReduxStore/Slices/ProfileDataSlice";
import { useDispatch } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { addToken } from "../../../ReduxStore/Slices/TokenTimeSlice";
import Cookies from "universal-cookie";
import PhoneInput from "react-phone-number-input";
import {
  RealtorStep1Schema,
  RealtorStep2Schema,
  RealtorStep3Schema,
} from "./RealtorSignUpSchema";

const RealtorSignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const typingTimeout = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [addressOptions, setAddressOptions] = useState([]);
  const [brokerageOptions, setBrokerageOptions] = useState([]);
  const [isPassChange, setIsPassChange] = useState(false);
  const [isConPassChange, setIsConPassChange] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [passHasLowerCase, setPassHasLowerCase] = useState(false);
  const [passHasCharacter, setPassHasCharacter] = useState(false);
  const [passHasCompleteLength, setPassHasCompleteLength] = useState(false);
  const [passHasNumber, setPassHasNumber] = useState(false);
  const [isExistingBrokerage, setIsExistingBrokerage] = useState(true);
  const [brokerageId, setBrokerageId] = useState("");

  const formInitialValues = {
    fname: "",
    lname: "",
    password: "",
    email: "",
    province: "",
    recoId: "",
    cno: "",
    brokerageName: "",
    brokerageAddress: "",
    brokeragePhoneNumber: "",
    brokerageEmail: "",
    cpassword: "",
    selectedCountry: "CA",
    termsAndConditions: false,
  };

  const handlePhoneInputChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  const handleCheckboxChange = () => {
    formik.setFieldValue(
      "termsAndConditions",
      !formik.values.termsAndConditions
    );
  };

  const handleAddressChange = async (event, value) => {
    const inputValue = value.trimStart();
    if (inputValue.length > 0) {
      if (typingTimeout.current) {
        clearTimeout(typingTimeout.current);
      }

      typingTimeout.current = setTimeout(async () => {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
          const response = await axios.get(
            `${baseURL}/api/brokerage/GetAddressResponse/${inputValue}`,
            config
          );
          const filteredCities = response.data?.data.map((item) => {
            return {
              responseTitle: item.responseTitle,
              responseDesc: item.responseDesc,
            };
          });
          setAddressOptions(filteredCities);
        } catch (error) {
          console.log(error);
        }
      }, 300);
    }
  };

  const handleBrokerageChange = (value) => {
    setBrokerageId(value?.id);
    formik.setFieldValue("brokerageName", value ? value.name : "");
    formik.setFieldValue("brokerageEmail", value ? value.email : "");
    formik.setFieldValue("brokerageAddress", value ? value.address : "");
    formik.setFieldValue("brokeragePhoneNumber", value ? value.phone : "");
    setAddressOptions([{ responseDesc: value.address }]);
  };

  const handleSelectBrokerageChange = () => {
    setIsSubmitted(false);
    formik.setFieldValue("brokerageName", "");
    formik.setFieldValue("brokerageEmail", "");
    formik.setFieldValue("brokerageAddress", "");
    formik.setFieldValue("brokeragePhoneNumber", "");
    setAddressOptions([]);
    setIsExistingBrokerage(!isExistingBrokerage);
  };

  const GetBrokerage = async () => {
    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.get(
        `${baseURL}/api/brokerage/GetBrokerage`,
        {
          headers,
        }
      );
      if (response.data.result === 1) {
        setBrokerageOptions(response?.data?.data);
        setCurrentStep(currentStep + 1);
        setIsLoading(false);
      } else {
        console.error("error");
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error.message);
      setIsLoading(false);
    }
  };

  const AddBrokerage = async () => {
    const headers = {
      "Content-Type": "application/json",
    };
    const data = {
      Id: "0",
      UId: "",
      Name: formik.values.brokerageName,
      Address: formik.values.brokerageAddress,
      Phone: formik.values.brokeragePhoneNumber,
      Email: formik.values.brokerageEmail,
      RecoId: "",
      IsVerify: "false",
    };
    try {
      const response = await axios.post(
        `${baseURL}/api/brokerage/AddUpdate`,
        data,
        {
          headers,
        }
      );
      if (response.data.result === 1) {
        setIsLoading(false);
        setCurrentStep(currentStep + 1);
        setBrokerageId(response?.data?.data?.id);
      } else {
        toast.error("Error adding Brokerage");
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Error adding Brokerage");
      setIsLoading(false);
    }
  };

  const SubmitRealtorForm = async () => {
    const headers = {
      "Content-Type": "application/json",
    };
    const data = JSON.stringify({
      Email: formik.values.email,
      Password: formik.values.password,
      FirstName: formik.values.fname,
      LastName: formik.values.lname,
      Phone: formik.values.cno,
      Type: "Realtor",
      Province: formik.values.province,
      RecoId: formik.values.recoId,
      BrokerageId: brokerageId,
    });
    try {
      const response = await axios.post(`${baseURL}/api/UserRegister`, data, {
        headers,
      });
      if (response.data.result === 1) {
        const jwt_token = response.data.data.refreshToken;
        const exp_time = response.data.data.refreshTokenExpiryTime;
        dispatch(addToken(exp_time));
        const refreshTokenExpiry = new Date(exp_time);
        cookies.set("jwt_authorization", jwt_token, {
          expires: refreshTokenExpiry,
        });
        localStorage.setItem("anonymous", 0);
        dispatch(addProfileData(response.data));
        toast.success(response.data.message);
        navigate("/email-confirmation");
      } else {
        toast.error(response.data.error[0]);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema:
      currentStep === 2
        ? RealtorStep2Schema
        : currentStep === 3
        ? RealtorStep3Schema
        : RealtorStep1Schema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (currentStep === 1) {
        GetBrokerage();
        setIsSubmitted(false);
      } else if (currentStep === 2) {
        if (isExistingBrokerage) {
          setIsLoading(false)
          setCurrentStep(currentStep + 1);
          setIsSubmitted(false);
        } else {
          AddBrokerage();
        }
      } else if (currentStep === 3) {
        SubmitRealtorForm();
      }
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowCPassword = () => setShowCPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    formik.setFieldValue("password", newPassword);
    validatePassword(newPassword);
  };

  const validatePassword = (password) => {
    if (/(?=.*[a-z])(?=.*[A-Z])/.test(password)) {
      setPassHasLowerCase(true);
    } else {
      setPassHasLowerCase(false);
    }

    if (/.*[0-9].*/.test(password)) {
      setPassHasNumber(true);
    } else {
      setPassHasNumber(false);
    }

    if (password.length >= 8) {
      setPassHasCompleteLength(true);
    } else {
      setPassHasCompleteLength(false);
    }

    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      setPassHasCharacter(true);
    } else {
      setPassHasCharacter(false);
    }
  };

  return (
    <div>
      <div className="centered pt-2">
        <div
          className="fieldandbuttons"
          style={{ maxWidth: "390px", width: "100%" }}
        >
          <div>
            <h6 className="steps_realtorSignUp">
              3 steps <span>to create an account</span>
            </h6>
            <div className="d-flex justify-content-between mt-3">
              <div className=" d-flex align-items-center justify-content-start">
                <span className="inActive_realtorStepCircle active_realtorStepCircle">
                  1
                </span>
                <span className="ps-2 active_realtorStep">Detail</span>
              </div>
              <div className=" d-flex align-items-center">
                <span
                  className={`inActive_realtorStepCircle ${
                    currentStep !== 1 ? "active_realtorStepCircle" : ""
                  }`}
                >
                  2
                </span>
                <span
                  className={`ps-2 InActive_realtorStep ${
                    currentStep !== 1 ? "active_realtorStep" : ""
                  }`}
                >
                  Brokerage
                </span>
              </div>
              <div className="d-flex align-items-center">
                <span
                  className={`inActive_realtorStepCircle ${
                    currentStep === 3 ? "active_realtorStepCircle" : ""
                  }`}
                >
                  3
                </span>
                <span
                  className={`ps-2 InActive_realtorStep ${
                    currentStep === 3 ? "active_realtorStep" : ""
                  }`}
                >
                  Password
                </span>
              </div>
            </div>
          </div>

          {currentStep === 1 && (
            <>
              <div className="row mt-4">
                <div className="col-6">
                  <FormControl>
                    <TextField
                      sx={{
                        borderRadius: "4px",
                      }}
                      size="small"
                      className=" w-100"
                      value={formik.values.fname}
                      onChange={formik.handleChange}
                      name="fname"
                      id="outlined-basic1"
                      label={<span className="font">Enter First Name</span>}
                      variant="outlined"
                    />
                    {isSubmitted ? (
                      <span style={{ color: "red" }}>
                        {formik.errors.fname}
                      </span>
                    ) : null}
                  </FormControl>
                </div>
                <div className="col-6">
                  <FormControl>
                    <TextField
                      size="small"
                      sx={{
                        borderRadius: "4px",
                      }}
                      className=" w-100"
                      value={formik.values.lname}
                      onChange={formik.handleChange}
                      name="lname"
                      id="outlined-basic2"
                      label={<span className="font">Enter Last Name</span>}
                      variant="outlined"
                    />
                    {isSubmitted ? (
                      <span style={{ color: "red" }}>
                        {formik.errors.lname}
                      </span>
                    ) : null}
                  </FormControl>
                </div>
              </div>

              <FormControl className="w-100 mt-4">
                <TextField
                  size="small"
                  sx={{
                    borderRadius: "4px",
                  }}
                  className="w-100"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  name="email"
                  id="email"
                  label={<span className="font">Enter your email</span>}
                  variant="outlined"
                />
                {isSubmitted ? (
                  <span style={{ color: "red" }}>{formik.errors.email}</span>
                ) : null}
              </FormControl>

              <FormControl className="w-100 mt-4">
                <PhoneInput
                  size="small"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="CA"
                  value={formik.values.cno}
                  onChange={(value) => handlePhoneInputChange("cno", value)}
                  name="phoneNumber"
                  id="phoneNumber"
                  className="Phone_input signUp_numberInput w-100"
                  placeholder="Enter Your Phone Number"
                />
                {isSubmitted ? (
                  <span style={{ color: "red" }}>{formik.errors.cno}</span>
                ) : null}
              </FormControl>

              <div className="row mt-4">
                <div className="col-6">
                  <FormControl>
                    <TextField
                      sx={{
                        borderRadius: "4px",
                      }}
                      size="small"
                      className="w-100"
                      value={formik.values.province}
                      onChange={formik.handleChange}
                      name="province"
                      id="province"
                      label={<span className="font">Province</span>}
                      variant="outlined"
                    />
                    {isSubmitted ? (
                      <span style={{ color: "red" }}>
                        {formik.errors.province}
                      </span>
                    ) : null}
                  </FormControl>
                </div>
                <div className="col-6">
                  <FormControl>
                    <TextField
                      size="small"
                      sx={{
                        borderRadius: "4px",
                      }}
                      className="w-100"
                      value={formik.values.recoId}
                      onChange={formik.handleChange}
                      name="recoId"
                      id="recoId"
                      label={<span className="font">CREA / RECO ID</span>}
                      variant="outlined"
                    />
                    {isSubmitted ? (
                      <span style={{ color: "red" }}>
                        {formik.errors.recoId}
                      </span>
                    ) : null}
                  </FormControl>
                </div>
              </div>
            </>
          )}

          {currentStep === 2 && (
            <>
              {isExistingBrokerage && (
                <FormControl className="mt-4 w-100">
                  <Autocomplete
                    size="small"
                    id="brokerageName"
                    sx={{ width: "100%" }}
                    options={brokerageOptions}
                    autoHighlight={false}
                    getOptionLabel={(option) => `${option.name}`}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        key={option.id}
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <div className="respDesc">{`${option.name}`}</div>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          borderRadius: "4px",
                        }}
                        className="w-100"
                        value={formik.values.brokerageName}
                        id="outlined-basic"
                        label={<span className="font">Brokerage Name</span>}
                        name="brokerageName"
                        variant="outlined"
                      />
                    )}
                    onChange={(event, newValue) => {
                      handleBrokerageChange(newValue);
                    }}
                  />
                  {isSubmitted ? (
                    <span style={{ color: "red" }}>
                      {formik.errors.brokerageName}
                    </span>
                  ) : null}
                </FormControl>
              )}

              {!isExistingBrokerage && (
                <FormControl className="mt-4 w-100">
                  <TextField
                    sx={{
                      borderRadius: "4px",
                    }}
                    size="small"
                    className=" w-100"
                    value={formik.values.brokerageName}
                    onChange={formik.handleChange}
                    name="brokerageName"
                    id="brokerageNameF"
                    label={<span className="font">Brokerage Name</span>}
                    variant="outlined"
                  />
                  {isSubmitted ? (
                    <span style={{ color: "red" }}>
                      {formik.errors.brokerageName}
                    </span>
                  ) : null}
                </FormControl>
              )}

              <FormControl className="mt-4 w-100">
                <Autocomplete
                  size="small"
                  id="brokerageAddress"
                  sx={{ width: "100%" }}
                  disabled={isExistingBrokerage}
                  options={addressOptions}
                  value={
                    addressOptions.find(
                      (option) =>
                        option.responseDesc === formik.values.brokerageAddress
                    ) || null
                  }
                  onInputChange={(event, value) => {
                    handleAddressChange(event, value);
                  }}
                  autoHighlight={false}
                  getOptionLabel={(option) => `${option.responseDesc}`}
                  isOptionEqualToValue={(option, value) =>
                    option.responseDesc === value.responseDesc
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      key={option.responseDesc}
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <div className="respDesc">{`${option.responseDesc}`}</div>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        borderRadius: "4px",
                      }}
                      className="w-100"
                      id="outlined-basic"
                      label={<span className="font">Brokerage Address</span>}
                      name="brokerageAddress"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "brokerageAddress",
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "brokerageAddress",
                      newValue ? newValue.responseDesc : ""
                    );
                  }}
                />
                {isSubmitted ? (
                  <span style={{ color: "red" }}>
                    {formik.errors.brokerageAddress}
                  </span>
                ) : null}
              </FormControl>

              <FormControl className="w-100 mt-4">
                <PhoneInput
                  size="small"
                  international
                  countryCallingCodeEditable={false}
                  disabled={isExistingBrokerage}
                  defaultCountry="CA"
                  value={formik.values.brokeragePhoneNumber}
                  onChange={(value) =>
                    handlePhoneInputChange("brokeragePhoneNumber", value)
                  }
                  name="brokeragePhoneNumber"
                  id="brokeragePhoneNumber"
                  className="Phone_input signUp_numberInput w-100"
                  placeholder="Brokerage Phone number"
                />
                {isSubmitted ? (
                  <span style={{ color: "red" }}>
                    {formik.errors.brokeragePhoneNumber}
                  </span>
                ) : null}
              </FormControl>

              <FormControl className="w-100 mt-4">
                <TextField
                  size="small"
                  name="brokerageEmail"
                  id="brokerageEmail"
                  variant="outlined"
                  value={formik.values.brokerageEmail}
                  onChange={formik.handleChange}
                  label={<span className="font">Brokerage email</span>}
                  disabled={isExistingBrokerage}
                  sx={{
                    borderRadius: "4px",
                  }}
                  className="w-100"
                />
                {isSubmitted ? (
                  <span style={{ color: "red" }}>
                    {formik.errors.brokerageEmail}
                  </span>
                ) : null}
              </FormControl>

              <button
                type="button"
                onClick={handleSelectBrokerageChange}
                className="CreateButton SignUp_submitBtn"
                style={{
                  background: "#0253A0",
                  padding: "9px 10px 10px 10px",
                  width: "fit-content",
                }}
              >
                {isExistingBrokerage
                  ? "Add New Brokerage"
                  : "Select Existing Brokerage"}
              </button>
            </>
          )}

          {currentStep === 3 && (
            <>
              <FormControl
                className="pass_input position-relative w-100 mt-4"
                variant="outlined"
              >
                <InputLabel size="small" htmlFor="outlined-adornment-password">
                  <span className="font">Enter password</span>
                </InputLabel>
                <OutlinedInput
                  size="small"
                  value={formik.values.password}
                  onChange={(e) => {
                    handlePasswordChange(e);
                    setIsPassChange(true);
                  }}
                  name="password"
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onBlur={() => setIsPassChange(false)}
                  endAdornment={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                  label={<span className="font">Enter Password</span>}
                />
                {isPassChange && formik.errors.password ? (
                  isPassChange &&
                  formik.errors.password !== "Password is required" ? (
                    <div className="pass_suggestion_box">
                      <h6>Password must contain the following:</h6>
                      <p
                        className={
                          passHasLowerCase ? "valid_passw" : "invalid_passw"
                        }
                      >
                        Lowercase and UpperCase
                      </p>
                      <p
                        className={
                          passHasNumber ? "valid_passw" : "invalid_passw"
                        }
                      >
                        A number
                      </p>
                      <p
                        className={
                          passHasCharacter ? "valid_passw" : "invalid_passw"
                        }
                      >
                        A special character (@, ! ,#)
                      </p>
                      <p
                        className={
                          passHasCompleteLength
                            ? "valid_passw"
                            : "invalid_passw"
                        }
                      >
                        Minimum 8 characters
                      </p>
                    </div>
                  ) : (
                    <span style={{ color: "red" }}>
                      {formik.errors.password}
                    </span>
                  )
                ) : null}
              </FormControl>

              <FormControl className="mt-4 w-100 pass_input" variant="outlined">
                <InputLabel size="small" htmlFor="outlined-adornment-password">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  size="small"
                  value={formik.values.cpassword}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setIsConPassChange(true);
                  }}
                  name="cpassword"
                  id="outlined-adornment-password5"
                  type={showCPassword ? "text" : "password"}
                  endAdornment={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showCPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                  label={<span className="font">Confirm Password</span>}
                />
                {isConPassChange ? (
                  <span style={{ color: "red" }}>
                    {formik.errors.cpassword}
                  </span>
                ) : null}
              </FormControl>

              <FormGroup className="mt-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.termsAndConditions}
                      name="termsAndConditions"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={
                    <span className="font">
                      I agree all{" "}
                      <NavLink to="/terms-and-conditions" className="terms">
                        Terms and Conditions
                      </NavLink>
                    </span>
                  }
                />
                {isSubmitted ? (
                  <span style={{ color: "red" }}>
                    {formik.errors.termsAndConditions}
                  </span>
                ) : null}
              </FormGroup>
            </>
          )}

          {isLoading && currentStep === 3 ? (
            <div className="aligncenter justifycenter mt-3">
              <Loader />
            </div>
          ) : (
            <div className="d-flex gap-3">
              {currentStep === 2 && (
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={() => {
                    setCurrentStep(1);
                    setIsSubmitted(false);
                  }}
                  className="CreateButton SignUp_submitBtn signUP_prevBtn w-100"
                >
                  Previous
                </button>
              )}
              <button
                type="submit"
                disabled={isLoading}
                onClick={() => {
                  setIsSubmitted(true);
                  if (currentStep === 3) {
                    setIsPassChange(true);
                    setIsConPassChange(true);
                  }
                  formik.handleSubmit();
                }}
                className="CreateButton SignUp_submitBtn w-100"
              >
                {isLoading
                  ? "Loading..."
                  : currentStep === 3
                  ? "Create Account"
                  : "Next"}
              </button>
            </div>
          )}
          <div className="d-flex justify-content-center pt-3">
            <div className="p-0">
              <p className=" font stflot1 m-0">
                Already have an Account?{" "}
                <NavLink
                  to="/login"
                  className="font stflot2 pl-2"
                  style={{ color: "rgb(2, 84, 158)", paddingLeft: "6px" }}
                >
                  <strong>Login</strong>
                </NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RealtorSignUp;
