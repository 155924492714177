import React, { useEffect, useState, memo } from "react";
import {
  CalendarIcon,
  DollarIcon,
  HomeValueIcon,
  QueryHelpIcon,
  RentalDomIcon,
  RentalYardIcon,
} from "../../../SvgIcons/Icons";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const HomeValue = ({ isMobileView }) => {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#fff",
      textShadow: "0px 8px 16px -2px rgba(27, 33, 44, 0.4)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      borderRadius: "var(--spacing-2, 8px)",
      background: "var(--White, #FFF)",
      boxShadow: "0px 8px 16px -2px rgba(27, 33, 44, 0.4)",
      color: "#000",
      textAlign: "center",
      fontFamily: "Open Sans",
      fontSize: "13px",
      fontWeight: "500",
      lineHeight: "140%",
    },
  }));

  const [expanded, setExpanded] = useState(isMobileView ? false : true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(isMobileView ? false : true);
  }, [isMobileView]);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion Property_simpleAccordion PropertyDetails_MobileAccordion py-2"
      sx={{ background: "#ffff" }}
    >
      <AccordionSummary
        expandIcon={
          expanded ? (
            <RemoveIcon className="Property_minusWebGreyIcon" />
          ) : (
            <AddIcon className="Property_plusWebGreyIcon" />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex align-items-center gap-2" : ""}>
          {isMobileView && <HomeValueIcon />}
          <h2 className="property_Details_heading">Home Value</h2>
          {!isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
              Current valuation for 5 Defries Waterfront Communities C8,
              Toronto. Listed for $1,035,000 on 2023-11-21
            </p>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isMobileView && (
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
            Current valuation for 5 Defries Waterfront Communities C8, Toronto.
            Listed for $1,035,000 on 2023-11-21
          </p>
        )}

        <div className="row mt-3 mx-0 w-100">
          <div className="col-md-4 col-6 py-2 ps-0 pe-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div className="propertyDetail_homeValue_icon">
                <DollarIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                  Estimate&nbsp;
                  <BootstrapTooltip
                    title="Uses AI to provide a home value estimate. Consult a REALTOR® for a more accurate property assessment."
                    arrow
                    placement="top"
                  >
                    <Button sx={{ padding: 0, minWidth: "auto" }}>
                      <QueryHelpIcon />
                    </Button>
                  </BootstrapTooltip>
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">
                  Insufficient data
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 py-2 px-2 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon PropertyDetails_Mbl_f14"
                style={{
                  borderColor: "#DFF4FF",
                  background: "var(--Light-blue, #BDDFFF)",
                }}
              >
                <CalendarIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                  {!isMobileView ? "Estimated" : "Est."} date&nbsp;
                  <BootstrapTooltip title="The most recent valuation of this property was conducted…" arrow placement="top">
                    <Button sx={{ padding: 0, minWidth: "auto" }}>
                      <QueryHelpIcon />
                    </Button>
                  </BootstrapTooltip>
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Undeclared</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 py-2 pe-0 ps-1 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{
                  borderColor: "#FFFCDF",
                  background: "var(--Light-yellow, #FFF7A0)",
                }}
              >
                <DollarIcon color={"#713F12"} />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                  {!isMobileView ? "Estimated" : "Est."} Rent&nbsp;
                  <BootstrapTooltip
                    title="Estimated Rent provides an estimated monthly rental cost using a proprietary formula. It’s a starting point for determining rent and should be independently verified."
                    arrow
                    placement="top"
                  >
                    <Button sx={{ padding: 0, minWidth: "auto" }}>
                      <QueryHelpIcon />
                    </Button>
                  </BootstrapTooltip>
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">Undeclared</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-6 py-2 pe-2 ps-0 market_activityMobileCard">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div
                className="propertyDetail_homeValue_icon"
                style={{ borderColor: "#F4ECFF", background: "#E4D2FF" }}
              >
                <RentalYardIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                  Rental Yield&nbsp;
                  <BootstrapTooltip
                    title="Rental Yield is calculated as: ((Estimated monthly rent * 12) - taxes - maintenance fees (if applicable) - (rental days on market / 30 * estimated rent)) / Estimated Sold Price."
                    arrow
                    placement="top"
                  >
                    <Button sx={{ padding: 0, minWidth: "auto" }}>
                      <QueryHelpIcon />
                    </Button>
                  </BootstrapTooltip>
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">2.99%</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 py-2 ps-1 pe-0 mbl_Xpadding0">
            <div className="propertyDetail_homeValueCard Prop_details_homeValueFlex">
              <div className="propertyDetail_homeValue_icon">
                <RentalDomIcon />
              </div>
              <div className="w-100 mt-2">
                <h6 className="mt-3 mbl_marginTop0 d-flex align-items-start PropertyDetails_Mbl_f18">
                  Rental Dom&nbsp;
                  <BootstrapTooltip
                    title="Estimated Rent DOM refers to the projected number of days this property will stay on the market before being leased."
                    arrow
                    placement="top"
                  >
                    <Button sx={{ padding: 0, minWidth: "auto" }}>
                      <QueryHelpIcon />
                    </Button>
                  </BootstrapTooltip>
                </h6>
                <p className="mt-2 mb-0 PropertyDetails_Mbl_f14">30 day(s)</p>
              </div>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(HomeValue);
