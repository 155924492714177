import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { RiHome5Line } from "react-icons/ri";
import { IoAdd } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

export default function Confirmation({ onPrevious, formData, updateFormData }) {
    const [isFormValid, setIsFormValid] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()

    const handleInputChange = (field, value) => {
        const updatedFormData = {
            ...formData,
            [field]: value,
        };
        updateFormData(updatedFormData); // Update the parent state
    };

    useEffect(() => {
        const validateConfirmationDetails = () => {
            const isNotEmpty = formData.cardexpiry.trim() !== '';

            // Check if the format is MM/YYYY using a regular expression
            // const isValidFormat = /^\d{2}\/\d{4}$/.test(formData.cardexpiry);

            const isFutureDate = (expiryDate) => {
                const today = new Date();
                const expiry = new Date(expiryDate);

                // Set the expiry to the last day of the month
                expiry.setMonth(expiry.getMonth() + 1, 0);

                return expiry > today;
            };

            const isValid = isNotEmpty  && isFutureDate(formData.cardexpiry) && formData.cardfirstName.trim() !== '' && formData.cardNumber.trim() !== '' && formData.cvcCode.trim() !== '';

            // Update the state based on the validity
            setIsFormValid(isValid);
        };

        validateConfirmationDetails();
    }, [formData]);

    return <>
        <Dialog

            open={showModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"

        >
            <DialogTitle id="alert-dialog-title" className='pt-5' >
                <div className='d-flex justify-content-center'>
                    <img className='imghw' src={require("../Images/SuccessfullyListedModal.png")} alt="SuccessFully Listed" loading="lazy"/>
                </div>
            </DialogTitle>
            <DialogContent className='pb-0' >
                <DialogContentText className='text-center'
                    id="alert-dialog-description">
                    <h2 className='text-shadow-black  mt-2 mb-0 font fw-bold' style={{ color: "#F6941C" }}> Congratulations! </h2>
                    <h4 className='text-shadow-black fs-5 font mt-2'>Your Property Listed Successfully!</h4>
                </DialogContentText>
            </DialogContent>
            <DialogActions className='pb-4 d-flex justify-content-evenly  px-5 pt-3'>
                <button onClick={() => navigate("/")} className='btn border fw-bold primary-color px-4 font '>
                    <span className='d-flex align-center'> <RiHome5Line className='me-1' />Home</span>
                </button>

                <button onClick={() => navigate("/sell")} className='btn btn-primary border primary-bg text-white font px-4'>
                    <span> <IoAdd /> Add another property</span>
                </button>
            </DialogActions>
        </Dialog>
        <div className='bg-white getquote_card_padding' style={{ borderRadius: "30px" }}>
            <div className='margin_bottom'>
                <h4 >
                    3. Complete Your Registration
                </h4>
            </div>
            <div>

                <Form className="mt-4 d-flex Mobilefont13 fullwidthcolumn ">
                    <Form.Group className=" w-100  mobileviewWidth FieldWidth">
                        <Form.Label className="fs-6 mb-0 fontmobile16 text-shadow-black">Card Number</Form.Label>
                        <Form.Control
                            size="small"
                            type="text"
                            className='fontmobile14'
                            value={formData.cardNumber}
                            onChange={(e) => handleInputChange('cardNumber', e.target.value)}
                        />
                    </Form.Group>
                </Form>

                <Form className="d-flex mt-3 fullwidthcolumn">
                    <Form.Group className=" w-100  mobileviewWidth FieldWidth">
                        <Form.Label className="fs-6 mb-0 fontmobile16 text-shadow-black">Name on Card</Form.Label>
                        <Form.Control
                            size="small"
                            className='fontmobile14'
                            type="text"
                            value={formData.cardfirstName}
                            onChange={(e) => handleInputChange('cardfirstName', e.target.value)}

                        />
                    </Form.Group>
                </Form>
                <Form className="d-flex mt-3 fullwidthcolumn">
                    <Form.Group className=" w-50 pe-3 mobileviewWidth FieldWidth">
                        <Form.Label className="fs-6 mb-0 fontmobile16 text-shadow-black">Expiry Date</Form.Label>
                        <Form.Control
                            size="small"
                            type="month"
                            className='fontmobile14'
                            placeholder="MM/YYYY"
                            value={formData?.cardexpiry}
                            onChange={(e) => handleInputChange('cardexpiry', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="w-50 ps-3 mobile_padding_input mobileviewWidth FieldWidth">
                        <Form.Label className="fs-6 mb-0 fontmobile16 text-shadow-black">CVC code</Form.Label>
                        <Form.Control
                            size="small"
                            type="text"
                            className='fontmobile14'
                            value={formData?.cvcCode}
                            onChange={(e) => handleInputChange('cvcCode', e.target.value)}

                        />
                    </Form.Group>
                </Form>


                <div className='d-flex my-5 justify-content-center aligncenter '>
                    <button
                        className='btn btn-primary px-4 py-2 fs-6 fontmobile16 primary-bg'
                        disabled={!isFormValid}
                        onClick={() => alert("Payment Done")}
                    >
                        Pay $10,949 Now
                    </button>
                </div>
            </div>
        </div>
        <div className='d-flex bottom' >
            <button
                style={{ position: 'absolute', bottom: '25px', left: '20px', border: "1px solid", padding: "10px 25px" }}
                className='btn btn-outline-primary fs-5 me-2 fontmobile16 primary-color bg-white font text-shadow-black'
                onClick={onPrevious}
            >
                &lt; Previous
            </button>
            <button
                style={{ position: 'absolute', bottom: '25px', right: '20px', border: "1px solid", padding: "10px 25px" }}
                className='btn btn-primary fs-5 fontmobile16 primary-bg font text-shadow-black'
                onClick={() => setShowModal(true)}
            >
                List Now
            </button>
        </div>
    </>;
}
