import { useNavigate } from "react-router-dom"
import baseURL from "../../../config"
import { Chip} from '@mui/material';


const OrderSummaryCard = ({data, status})=>{

    const navigate = useNavigate()

    const StatusChipStyle ={
        Active: { background: '#84FAB5', color: '#005624' },
        Pending: { background: '#F8EFA3', color: '#6F6300' },
        Suspended: { background: '#FF4D6D', color: '#fff' },
    }

    return(
        <div
        className="border w-100 border-1 shadow-sm mb-1 bg-white rounded px-2 py-3"
    >
        <div className="d-flex  justify-content-between w-100 px-3 aligncenter text-primary">
            <span className="fw-bold  d-flex  Mobilefont13">{data?.projectName} {" "}
                <Chip size="small" className=' ms-2 font10' label={data?.status} style={StatusChipStyle[data?.status] || {}} />
            </span>
        </div>
        <hr className="hr m-3 opacity-75" />
        <div className="d-flex flex-wrap justify-content-between w-100 px-3">
            <div className="d-flex aligncenter w-100">
                <img className="listingImg" src={data?.assignmentMedias[0]?.fileUrl ? `${baseURL}/${data?.assignmentMedias[0]?.fileUrl}` : require("../../../images/no-image.png")} loading="lazy" alt={data?.projectName} />
                <div className="lh-1 ps-3 mt-2  w-100">
                    <div className='tab_justifyContent_between_at_990 w-100'>
                        <div className='d-flex justify-content-between w-100'>
                            <p className="text-success fw-bold">{`$ ${new Intl.NumberFormat().format(data?.assignmentPrice)}`}</p>
                            <div className='FavAndView_mobNone orderSummary_favMobNone'>
                                <span>Messages: <strong>{data?.messages}</strong> </span>
                                <span className='ps-2'> |</span>
                                <span className="px-2">Favourite: <strong>{data?.favourites}</strong> </span>
                                <span className='ps-2'> |</span>
                                <span className='px-2'> Views: <strong>{data?.views}</strong> </span>
                            </div>
                        </div>
                    </div>
                    <p className="mobileresponsive font13">{data?.address}</p>
                </div>
            </div>

            <div className='mob_favAndViews mob_orderMassViewFav'>
                <span>Favourite: <strong>{data?.favourites}</strong> </span>
                <span className='ps-2'> |</span>
                <span className='px-2'> Views: <strong>{data?.views}</strong> </span>
            </div>

            <div className="mob_messageShow">
            <p className="m-0"> Messages: <strong style={{fontWeight: 'bold'}}>{data?.messages}</strong> </p>
            </div>

            <div className='d-flex ms-auto viewSummary_btn'>
                <div className="d-flex mt-2 flex-wrap listing_btns">
                    <button className="btn mobileresponsive ListingcardButton Mobilefont13" style={{background:'#000', color:'#fff'}} onClick={() => navigate(`/dashboard/order-summary/details/${data?.invoiceId}?status=${status}&projectName=${data?.projectName}`)}>View Summary</button>
                </div>
            </div>
        </div>
    </div>
    )
}


export default OrderSummaryCard