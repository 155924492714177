import { createSlice } from "@reduxjs/toolkit";

const refreshMail = createSlice({
    name: "refreshMail",
    initialState: 0,
    reducers: {
      mailRefresh: (state) => {
        return state + 1;
      },
      stopRefresh:() =>{
        return 0;
      }
    },
  });
export const { mailRefresh} = refreshMail.actions;
export default refreshMail.reducer;