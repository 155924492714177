import bannerImage from '../../images/ToolsToPromoteAds/PromoteAd-banner.png'
import TopHomePageGallery from '../../images/ToolsToPromoteAds/Home-page-gallery.png'
import HomePageTicker from '../../images/ToolsToPromoteAds/Home-page-ticker.png'
import HighlightAd from '../../images/ToolsToPromoteAds/Highlight-ad.png'
import HotDealTag from '../../images/ToolsToPromoteAds/Hot-deal-tag.png'
import Footer from '../Footer/Footer'
import './ToolsToPromoteAd.css'


const ToolsToPromoteAd = () => {
    return (
        <div className="tool_ad_container">
            <div className='tool_ad_banner_container d-flex align-items-center justify-content-center'>
                <div className='tool_ad_banner d-flex align-items-center justify-content-center'>
                    <div className='toolAdtext_container'>
                        <h2 className='tools_ad_heading'>Empower Your Listings,</h2>
                        <h2 className='tools_ad_heading'>Elevate Your Reach</h2>
                        <p className='tools_ad_para'>Unleash the power of seamless design and innovative features to transform your online presence. Our tool is your gateway to a hassle-free, visually stunning real estate showcase.</p>
                        <button className='tool_ad_btn'>Get now</button>
                    </div>
                    <div className='toolAdImage_container'>
                        <img src={bannerImage} alt='Promote ad banner' loading="lazy" />
                    </div>
                </div>
            </div>


            {/* <div className='d-flex align-items-center justify-content-center tools_grey_container'>
                <div className='toolTicker_container'>
                    <div className='toolAdtext_container'>
                        <h2 className='tools_ad_heading'>Top Home page gallery</h2>
                        <ul className='tools_ad_para tools_ad_list'>
                            <li>A blue background makes your ad pop for more views, responses and sales</li>
                            <li>Stand out in both search and category listings</li>
                        </ul>
                    </div>
                    <div className='toolAdImage_container'>
                        <img src={TopHomePageGallery} alt='Top home galley tool' loading="lazy"/>
                    </div>
                </div>
            </div>

            <div className='d-flex align-items-center justify-content-center'>
                <div className='toolTicker_container'>
                    <div className='toolAdtext_container'>
                        <h2 className='tools_ad_heading'>Home Page Ticker</h2>
                        <ul className='tools_ad_para tools_ad_list'>
                            <li>Boost your ad's visibility in search results, putting you ahead of other sellers.</li>
                            <li>Ads are reposted automatically so you don’t have to recreate your ad from scratch – that way you save time and don’t have to worry about a thing</li>
                            <li>Using Bump Up maintains your view counter, showcasing the level of interest in your listing.</li>
                        </ul>
                    </div>
                    <div className='toolAdImage_container'>
                        <img src={HomePageTicker} alt='Home page ticker tool' loading="lazy" />
                    </div>
                </div>
            </div> */}

            <div className='d-flex align-items-center justify-content-center tools_grey_container'>
                <div className='toolTicker_container'>
                    <div className='toolAdtext_container'>
                        <h2 className='tools_ad_heading'>Highlight Ad</h2>
                        <ul className='tools_ad_para tools_ad_list'>
                            <li>Boost your ad's visibility in search results, putting you ahead of other sellers.</li>
                            <li>Ads are reposted automatically so you don’t have to recreate your ad from scratch – that way you save time and don’t have to worry about a thing</li>
                            <li>Using Bump Up maintains your view counter, showcasing the level of interest in your listing.</li>
                        </ul>
                    </div>
                    <div className='toolAdImage_container'>
                        <img src={HighlightAd} alt='Highlight ad tool' loading="lazy"/>
                    </div>
                </div>
            </div>

            <div className='d-flex align-items-center justify-content-center'>
                <div className='toolTicker_container'>
                    <div className='toolAdtext_container'>
                        <h2 className='tools_ad_heading'>Hot Deal Tag</h2>
                        <ul className='tools_ad_para tools_ad_list'>
                            <li>Boost your ad's visibility in search results, putting you ahead of other sellers.</li>
                            <li>Ads are reposted automatically so you don’t have to recreate your ad from scratch – that way you save time and don’t have to worry about a thing</li>
                            <li>Using Bump Up maintains your view counter, showcasing the level of interest in your listing.</li>
                        </ul>
                    </div>
                    <div className='toolAdImage_container'>
                        <img src={HotDealTag} alt='Hot deal tag tool' loading="lazy"/>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default ToolsToPromoteAd