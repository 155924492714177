import React, { useState, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  HighlightsIcon,
  // CardOrCodeAccessIcon,
  // DiningAreaIcon,
  // GardenViewIcon,
  // PrivateLotIcon,
  // SunFocusingIcon,
  // WoodFlooringIcon,
} from "../../../SvgIcons/Icons";

const PropertyHighlights = ({ data, isMobileView, projectName }) => {
  const [showMore, setShowMore] = useState(true);
  const [expanded, setExpanded] = useState(true);

  const handleChange = () => {
    if (isMobileView) {
      setExpanded(!expanded);
    }
  };

  const toggleExpand = () => {
    setShowMore(false);
  };


  const description = data || "";
  const shouldShowButton = description.split(" ").length > 115;
  const displayedText = !showMore
    ? description
    : description.split(" ").slice(0, 115).join(" ");

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
    >
      <AccordionSummary
        expandIcon={
          isMobileView &&
          (expanded ? (
            <RemoveIcon className="Property_minusIcon" />
          ) : (
            <AddIcon className="Property_plusIcon" />
          ))
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={isMobileView ? "d-flex align-items-center gap-2" : ""}>
          {isMobileView && <HighlightsIcon />}
          <h2 className="property_Details_heading">
            {`${projectName ? projectName : "Property"} Highlights`}
          </h2>
          {!isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
             {` Explore the key features that make  ${projectName ? projectName : "this property"} a top
              choice.`}
            </p>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {isMobileView && (
          <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
               {` Explore the key features that make  ${projectName ? projectName : "this property"} a top
              choice.`}
          </p>
        )}
        {/* <div className="row w-100 mt-3">
          <div className="col-md-4 col-12 py-2">
            <p className="property_amenities d-flex align-items-center fw-bold">
              <WoodFlooringIcon />
              <span className="ps-3 PropertyDetails_Mbl_f14">
                Wood Flooring
              </span>
            </p>
          </div>
          <div className="col-md-4 col-12 py-2">
            <p className="property_amenities d-flex align-items-center fw-bold">
              <SunFocusingIcon />
              <span className="ps-3 PropertyDetails_Mbl_f14">Sun focusing</span>
            </p>
          </div>
          <div className="col-md-4 col-12 py-2">
            <p className="property_amenities d-flex align-items-center fw-bold">
              <CardOrCodeAccessIcon />
              <span className="ps-3 PropertyDetails_Mbl_f14">
                Card or Code access
              </span>
            </p>
          </div>
          <div className="col-md-4 col-12 py-2">
            <p className="property_amenities d-flex align-items-center fw-bold">
              <GardenViewIcon />
              <span className="ps-3 PropertyDetails_Mbl_f14">Garden view</span>
            </p>
          </div>
          <div className="col-md-4 col-12 py-2">
            <p className="property_amenities d-flex align-items-center fw-bold">
              <PrivateLotIcon />
              <span className="ps-3 PropertyDetails_Mbl_f14">Private lot</span>
            </p>
          </div>
          <div className="col-md-4 col-12 py-2">
            <p className="property_amenities d-flex align-items-center fw-bold">
              <DiningAreaIcon />
              <span className="ps-3 PropertyDetails_Mbl_f14">Dining area</span>
            </p>
          </div>
        </div> */}
        <div className="position-relative mt-2">
          <div
            className={
              showMore && shouldShowButton ? "PropDetails_HideDescription" : ""
            }
          >
            <p
              className={`PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 ${
                shouldShowButton && !showMore ? "mb-0" : ""
              }`}
            >
              {displayedText}
            </p>
          </div>
        </div>
        {shouldShowButton && showMore && (
          <button onClick={toggleExpand} className="Detail_showMoreBtn">
            Show all
            {/* <span className="ps-1 Property_detail_more_icon">
              <AddIcon sx={{ color: "#676767" }} />
            </span> */}
          </button>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(PropertyHighlights);
