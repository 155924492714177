import { createSlice } from "@reduxjs/toolkit";

const userProfileDataSlice = createSlice({
  name: "userProfileDataSlice",
  initialState: [],
  reducers: {
    addUserProfileData: (state, action) => {
          state.push(action.payload);
    },
    removeUserProfileData: () => {
      return [];
    },
  
  },
});
export const { addUserProfileData, removeUserProfileData} = userProfileDataSlice.actions;
export default userProfileDataSlice.reducer;