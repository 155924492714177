import React from "react";
import "./CategorySearching.css";

export default function CategorySearching({setCategoryLocation , setCategoryBType}) {

  const cities = ["Toronto", "Brampton", "Oakville", "Whitby"];
  const buildingTypes = ["Condo", "Condo Town", "TownHouse", "Semi-Detached", "Detached"];

  const searchCategoryClick = (city , Btype) => {
    const middleOfPage = (window.innerHeight / 2); 
    window.scrollTo({
      top: middleOfPage,
      behavior: 'smooth',
    });
    setCategoryLocation(city);
    setCategoryBType(Btype);
  };

  const categoryList = cities.map((city) => {
    return (
      <div className="col-md-3" key={city}>
        {buildingTypes.map((buildingType) => {
          return (
            <p
              className="coloringcatogory"
              onClick={() => searchCategoryClick(city, buildingType)}
              key={`${city}-${buildingType}`}
            >
              {buildingType} in {city}
            </p>
          );
        })}
      </div>
    );
  });

  return (
    <div className="container-fluid">
      <footer
        className="text-center text-lg-start greencolor text-white"
        style={{ backgroundColor: "white" }}
      >
        <section className="greencategory pt-4 pb-3">
          <div className="me-5">
            <h4 className="categorymargin pt-2">Search by Categories</h4>
          </div>
          <div className="text-left">
            <div className="row mx-5 mt-4">
            {categoryList}
            </div>
          </div>
        </section>
      </footer>
    </div>
  );
}
