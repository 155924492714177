import { memo } from "react";
import {
  AllPhotoIcons,
  BathIcon,
  BedIcon,
  BuildingIcon,
  EditIcon,
  PerSqftIcon,
  PropertyCommuteIcon,
  SqftIcon,
  StoreyIcon,
  WatchingNeighbourIcon,
  WatchSoldIcon,
} from "../../../SvgIcons/Icons";
import { Link } from "react-scroll";
import dayjs from "dayjs";
import baseURL, { NeedHomeBaseURL } from "../../../config";
import { useSelector } from "react-redux";

const PropertyInformation = ({
  data,
  handleWatchNeighborHoodClick,
  handleInfoModalClick,
  handleNotifyWhenSoldClick,
  handleCommuteModalClick,
  isNotifyLoading,
  setImageModalOpen,
}) => {
  const monthlyPayemnt = useSelector(
    (state) => state.MonthlyMortgagePaymentSlice
  );

  const openGoogleMaps = () => {
    const url = `https://www.google.com/maps?q=${data?.lat},${data?.lng}`;
    window.open(url, "_blank");
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format("MMM YYYY");
  };

  const primaryImage = data?.preMedias?.filter((item) => item?.isPrimary);
  const propertyLogo = data?.preMedias?.filter((item) => item?.isLogo);
  const propertyImages = data?.preMedias?.filter((item) => !item?.isLogo);

  const handleImageModalOpen = (sliderImg) => {
    setImageModalOpen(true);
  };

  return (
    <div>
      {data?.estimatedCompletion ? (
        <p className="my-0 text-end preconstruction_complete_time mb-1 PropDetailsMob_lg_hide">
          <span
            style={{ color: "#282828", fontWeight: "600" }}
            className="pe-3"
          >
            Preconstruction
          </span>
          EST Completion{" "}
          {data?.estimatedCompletion === "To be determined"
            ? "To be determined"
            : formatDate(data?.estimatedCompletion)}
        </p>
      ) : null}

      <div className="row PropDetails_ImageAndTextContainer">
        <div className="col-lg-6  col-12 d-flex flex-column justify-content-center property_details_main_information">
          <div className="d-flex align-items-center gap-2 justify-content-between flex-wrap">
            {data?.listingStatus ? (
              <div
                className="PropertyStatusBadge PropertyDetails_Mbl_f14"
                style={{
                  background:
                    data?.listingStatus === "Selling" ? "#D1FADF" : "#FFDFC3",
                  color:
                    data?.listingStatus === "Selling" ? "#008733" : "#BC300A",
                }}
              >
                <span
                  style={{
                    background:
                      data?.listingStatus === "Selling" ? "#008733" : "#BC300A",
                  }}
                ></span>
                {data?.listingStatus}
              </div>
            ) : null}
            {data?.estimatedCompletion ? (
              <div
                className="PropertyStatusBadge PropertyDetails_Mbl_f14 Property_detailsWeb_hide PropDetailsMob_lg_show"
                style={{
                  background: "#F4F4F5",
                  color: "#282828",
                }}
              >
                Est. Completion{" "}
                {data?.estimatedCompletion === "To be determined"
                  ? "To be determined"
                  : formatDate(data?.estimatedCompletion)}
              </div>
            ) : null}
          </div>
          {data?.projectTitle ? (
            <h1 className="mt-2">{data?.projectTitle}</h1>
          ) : null}
          <div className="mt-2 Property_detailsAddress">
            {data?.address ? (
              <h6 className="PropertyDetails_Mbl_f14">{data?.address}</h6>
            ) : null}
            {data?.state || data?.city ? (
              <h6 className="PropertyDetails_Mbl_f14">{`${data?.state} , ${data?.city}`}</h6>
            ) : null}
            {data?.phase ? (
              <h6 className="PropertyDetails_Mbl_f14">{data?.phase}</h6>
            ) : null}
          </div>

          <div className={`mt-4 d-flex flex-column PropertyDetiails_Icons`}>
            <div className="property_details_pricing">
              {data?.fromPrice || data?.toPrice ? (
                <div className="d-flex gap-2 flex-wrap align-items-center">
                  <h6 className=" m-0 PropertyDetails_Mbl_f14">
                    From:{" "}
                    <span
                      className="PropertyDetails_Mbl_f18"
                      style={{ color: "#282828" }}
                    >
                      {data?.fromPrice && data?.toPrice
                        ? `$${Math.ceil(data?.fromPrice).toLocaleString(
                            "en-US"
                          )} - $${Math.ceil(data?.toPrice).toLocaleString(
                            "en-US"
                          )}`
                        : data?.fromPrice
                        ? `$${Math.ceil(data?.fromPrice).toLocaleString(
                            "en-US"
                          )}`
                        : data?.toPrice
                        ? `$${Math.ceil(data?.toPrice).toLocaleString("en-US")}`
                        : null}{" "}
                    </span>
                  </h6>
                  <p
                    className="m-0 fw-bold d-flex align-items-center PropertyDetails_Mbl_f14"
                    style={{ color: "#676767" }}
                  >
                    Est. {monthlyPayemnt}/mo{" "}
                    <span style={{ cursor: "pointer" }}>
                      <Link
                        spy={true}
                        activeClass="active"
                        offset={-100}
                        smooth={true}
                        duration={500}
                        to={"calculator"}
                      >
                        <EditIcon size={"24"} color={"#282828"} />
                      </Link>
                    </span>
                  </p>
                </div>
              ) : null}

              {data?.saleStart ? (
                <h6 className="PropertyDetails_Mbl_f14">
                  Sale start:{" "}
                  <span
                    className="PropertyDetails_Mbl_f18"
                    style={{ color: "#282828" }}
                  >
                    {data?.saleStart}
                  </span>
                </h6>
              ) : null}
              {data?.saleClosing ? (
                <h6 className="PropertyDetails_Mbl_f14">
                  Sale closing:{" "}
                  <span
                    className="PropertyDetails_Mbl_f18"
                    style={{ color: "#282828" }}
                  >
                    {data?.saleClosing}
                  </span>
                </h6>
              ) : null}
              {data?.occupancy || data?.Clmonth ? (
                <h6 className="PropertyDetails_Mbl_f14">
                  Occupancy:{" "}
                  <span
                    className="PropertyDetails_Mbl_f18"
                    style={{ color: "#282828" }}
                  >
                    {data?.Clmonth ? data?.Clmonth : data?.occupancy}
                  </span>
                </h6>
              ) : null}
            </div>

            <div className="d-flex flex-wrap property_information_DetailsIcon mt-4">
              {data?.minBed || data?.maxBed ? (
                <p>
                  <BedIcon />{" "}
                  <span>
                    {data?.minBed && data?.maxBed
                      ? `${data.minBed} - ${data.maxBed}`
                      : data?.minBed
                      ? `${data.minBed}`
                      : data?.maxBed
                      ? `${data.maxBed}`
                      : null}{" "}
                    Bed
                  </span>
                </p>
              ) : null}

              {data?.minBath || data?.maxBath ? (
                <p>
                  <BathIcon color={"#282828"} />{" "}
                  <span>
                    {data?.minBath && data?.maxBath
                      ? `${data.minBath} - ${data.maxBath}`
                      : data?.minBath
                      ? `${data.minBath}`
                      : data?.maxBath
                      ? `${data.maxBath}`
                      : null}{" "}
                    Bath
                  </span>
                </p>
              ) : null}

              {data?.buildingType ? (
                <p>
                  <BuildingIcon /> <span>{data?.buildingType}</span>
                </p>
              ) : null}

              {data?.minSqft || data?.maxSqft ? (
                <p>
                  <SqftIcon color={"#282828"} />{" "}
                  <span>
                    {data?.minSqft && data?.maxSqft
                      ? `${data.minSqft?.toLocaleString(
                          "en-US"
                        )} - ${data.maxSqft?.toLocaleString("en-US")}`
                      : data?.minSqft
                      ? `${data.minSqft?.toLocaleString("en-US")}`
                      : data?.maxSqft
                      ? `${data.maxSqft?.toLocaleString("en-US")}`
                      : null}{" "}
                    SqFt
                  </span>
                </p>
              ) : null}

              {data?.story ? (
                <p>
                  <StoreyIcon /> <span>{data?.story} Storey</span>
                </p>
              ) : null}

              {data?.listingActivityDTO?.appsf ? (
                <p>
                  <PerSqftIcon />{" "}
                  <span>
                    {`$${Math.ceil(
                      data?.listingActivityDTO?.appsf
                    )?.toLocaleString("en-US")}/SqFt`}
                  </span>
                </p>
              ) : null}
            </div>
          </div>

          <p className="mt-4 mb-0 details_contactUs_des PropDetailsMob_lg_hide">
            Contact us for pricing info, floor plans, incentives, amenities, and
            more!
          </p>

          <button
            className="propertyDetails_moreInfo p-3 mt-3 PropDetailsMob_lg_hide PropDetails_gradientBtn"
            onClick={handleInfoModalClick}
          >
            Request for more info
          </button>

          <p
            className="mb-3 mt-4 prop_details_lgShow fw-bold mb-1 Property_detailsWeb_hide"
            style={{ color: "#282828", fontSize: "18px" }}
          >
            Preconstruction
          </p>

          <div className="Property_detailsWeb_hide prop_details_lgShow mx-0">
            <img
              src="/images/mapDirection.png"
              alt="MapLocationImg"
              loading="lazy"
              className="Find_nearByMobileMapImg mt-3"
              onClick={openGoogleMaps}
            />
            <div className="d-flex flex-wrap w-100 gap-2 mt-3">
              <button
                className="Mbl_notifyBtn  Mbl_notifyBtn_bluetbtn"
                onClick={handleCommuteModalClick}
              >
                <span
                  className="DetailsIcon_greyBg"
                  style={{ background: "var(--Primary-Natural-blue, #007BFF)" }}
                >
                  <PropertyCommuteIcon color={"#fff"} />
                </span>
                <span>Add a commute</span>
              </button>
              <button
                onClick={handleWatchNeighborHoodClick}
                className="Mbl_notifyBtn Propmbl_f11"
              >
                <span className={"DetailsIcon_greyBg"}>
                  <WatchingNeighbourIcon color={"#4A4A4A"} size={"24"} />
                </span>
                Watching Neighbourhood
              </button>
              <button
                disabled={isNotifyLoading}
                onClick={handleNotifyWhenSoldClick}
                className="Mbl_notifyBtn "
              >
                <span className={"DetailsIcon_greyBg DetailsIcon_LightBg"}>
                  <WatchSoldIcon color={"#007BFF"} size={"24"} />
                </span>
                Notify when Sold{" "}
              </button>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-12">
          <div className="property_details_mainImage">
            {data?.preMedias?.length > 0 ? (
              <img
                className="w-100 h-100"
                src={
                  primaryImage?.length > 0
                    ? `${NeedHomeBaseURL}/${primaryImage[0]?.fileUrl}`
                    : `${NeedHomeBaseURL}/${data?.preMedias[0]?.fileUrl}`
                }
                alt={data?.projectTitle}
                loading="lazy"
              />
            ) : (
              <img
                className="w-100 h-100"
                src={"/images/PropertyNoImage.png"}
                alt={data?.projectTitle}
              />
            )}
            {propertyLogo?.length > 0 && (
              <div className="DetailsBuilders_Box">
                <img
                  src={`${baseURL}/${propertyLogo[0]?.fileUrl}`}
                  alt="property-logo"
                  loading="lazy"
                  width="130px"
                  style={{ borderRadius: "0px" }}
                />
              </div>
            )}
            {data?.catFeatured && (
              <div className="Prop_featuredBadge">
                <span>{data?.catFeatured}</span>
              </div>
            )}
            {propertyImages?.length > 1 && (
              <button
                className="PropDetails_allImgBtn d-flex align-items-center"
                onClick={() => handleImageModalOpen(0)}
              >
                <AllPhotoIcons />{" "}
                <span>{`View all ${propertyImages?.length} Photos`}</span>
              </button>
            )}
          </div>
        </div>
      </div>
      <hr className="Property_detailsWeb_hide w-100 mt-5 mb-0 PropDetailsMob_lg_show PropDetailsMob_md_hide" />
    </div>
  );
};

export default memo(PropertyInformation);
