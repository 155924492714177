import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { Chip } from '@mui/material';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CustomSwitch from './Switch';


export function formatPhoneNumber(inputNumber) {
    const cleaned = ("" + inputNumber).replace(/\D/g, "").slice(0, 11);
    const match = cleaned.match(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})$/);
    let formattedNumber = "";
    if (match[1]) {
        formattedNumber += `+${match[1]}`;
    }
    if (match[2]) {
        formattedNumber += ` (${match[2]}`;
    }
    if (match[3]) {
        formattedNumber += `) ${match[3]}`;
    }
    if (match[4]) {
        formattedNumber += `-${match[4]}`;
    }
    return formattedNumber;
};


export const CustomBadgeFormatter = (column, row, type, chipStyle) => {
    return (
        <div >
            <Link to={`/dashboard/users?id=${row?.id}&type=${type}`} >
                <Chip size="small" className='rounded  font fw-bold' label={`${type} (${column})`} style={chipStyle} />
            </Link>
        </div>
    )
}

export function HeaderAlignCenter(column) {
    return (
        <div  >
            {column.text} {<UnfoldLessIcon />}
        </div>
    );
}

export function TextAlignStart(column) {
    return (
        <div className='text-start ps-2'>
            {column}
        </div>
    );
}

export function PriceFormatter(column) {
    return (
        <div className='text-start'>
            $ {new Intl.NumberFormat().format(column)}
        </div>
    )
}

export function IsFeaturedFormatter({ column, HandleIsFeaturedActions, row }) {
    return (
        <CustomSwitch
            switchState={column}
            onChange={(newState) => HandleIsFeaturedActions(newState, row)}
        />
    )
}

export function statusformatter({ column, HandleStatusChange, row }) {
    return (
        <Dropdown className='font fw-bold custom-cursor' >
            <Dropdown.Toggle size='sm'
                style={{
                    backgroundColor: column === "Pending" ? "#FFF8BC" : column === "Active" ? "#98FFC3" : column === "Suspended" ? "#FF4D6D" : "bg-success",
                    color: column === "Pending" ? "#CAB600" : column === "Active" ? "#02C252" : column === "Suspended" ? "white" : "bg-success",
                    borderRadius: "5px",
                    borderColor: "transparent",
                    fontWeight: "bold",
                    fontSize: "15px",
                    padding: "2px 10px"
                }}
            >
                {
                    column !== null ? column : "N/A"
                }
            </Dropdown.Toggle>
            {
                column && (
                    <Dropdown.Menu style={{ minWidth: '80%' }}>
                        {column !== 'Active' && (
                            <Dropdown.Item
                                onClick={() => HandleStatusChange(row?.uId, "Active")}
                            >Active</Dropdown.Item>
                        )}
                        {column !== 'Pending' && (
                            <Dropdown.Item
                                onClick={() => HandleStatusChange(row?.uId, "Pending")}
                            >Pending</Dropdown.Item>
                        )}
                        {column !== 'Suspended' && (
                            <Dropdown.Item
                                onClick={() => HandleStatusChange(row?.uId, "Suspended")}
                            >Suspend</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                )
            }
        </Dropdown >
    )
}

export function DealsStatus({ column, DealsStatusChange, row }) {
    return (
        <div>
            <Dropdown className='font' >

                <Dropdown.Toggle size='sm' className='ButtonRadius' style={{ minWidth: '85%', backgroundColor: "#BDE0FE", fontSize: "15px", color: "black", borderColor: "transparent", padding: "2px 10px" }}>
                    {row?.dailyUpdate !== "" ? row?.dailyUpdate : "N/A"}
                </Dropdown.Toggle>
                <Dropdown.Menu className='DropDownMenuFont' >

                    {row.dailyUpdate !== 'Hot Deals' && (
                        <Dropdown.Item onClick={() => DealsStatusChange(row, "Hot Deals")}>Hot Deals</Dropdown.Item>
                    )}
                    {row.dailyUpdate !== 'Just Reached' && (
                        <Dropdown.Item onClick={() => DealsStatusChange(row, "Just Reached")}>Just Reached</Dropdown.Item>
                    )}
                    {row.dailyUpdate !== 'Fast Moving' && (
                        <Dropdown.Item onClick={() => DealsStatusChange(row, "Fast Moving")}>Fast Moving</Dropdown.Item>
                    )}
                    {row.dailyUpdate !== 'Just Reduced' && (
                        <Dropdown.Item onClick={() => DealsStatusChange(row, "Just Reduced")}>Just Reduced</Dropdown.Item>
                    )}

                </Dropdown.Menu>

            </Dropdown >
        </div >
    )
}
