import { createSlice } from "@reduxjs/toolkit";

const profileDataSlice = createSlice({
  name: "ProfileDataSlice",
  initialState: [],
  reducers: {
    addProfileData: (state, action) => {
          state.push(action.payload);
    },
    removeProfileData: () => {
      return [];
    },
  
  },
});
export const { addProfileData, removeProfileData} = profileDataSlice.actions;
export default profileDataSlice.reducer;