import './SellProperty.css'
import PricingCards from '../Pricing/pricingCards'
import {
  FormControl,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  TextField
} from '@mui/material'
import Footer from '../Footer/Footer'
import DollarIcon from "../Images/ReceiveDollar.svg";
import { useNavigate } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { useFormik } from 'formik'
import baseURL from '../../config'
import { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import ScheduleScheme from './ScheduleScheme'
import axios from 'axios'
import FullWidthTabs from '../Pricing/Tabpanel/FullWidthTabs'
import Home_MarketingIcon from '../../images/Home_Marketing_Icon.png'
import Loader from '../../HomePage/SubComponents/MapandListing/Loader'
import PhoneInput from 'react-phone-number-input'
import InformationForSeller from "./SubComponents/InformationForSeller";
import FaqAccordion from './Faq'
import QueryImg from '../../images/QueryImg.png'
import LocalOffice from '../../images/FindLocalOffice.png'
import HelmetLayout from '../../Utils/HelmetLayout/Helmet';

const SellProperty = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmited, setIsSubmited] = useState(false)
  const [selectedValues, setSelectedValues] = useState([])

  const formInitialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    selectedCountry: 'CA',
    schedule: ''
  }

  const handlePhoneNumberChange = e => {
    formik.setFieldValue('phone', e)
  }

  const handleCheckboxChange = value => {
    setSelectedValues(prevValues => {
      if (prevValues.includes(value)) {
        return prevValues.filter(val => val !== value)
      } else {
        return [...prevValues, value]
      }
    })
  }

  const isCheckboxSelected = value => selectedValues.includes(value)

  // useEffect(() => {
  //   if (userData && userData?.firstName) {
  //     localStorage.setItem('fullName', userData?.firstName)
  //   }
  //   if (userData && userData.lastName) {
  //     localStorage.setItem('lastName', userData?.lastName)
  //   }
  //   if (userData && userData.email) {
  //     localStorage.setItem('email', userData?.email)
  //   }
  //   if (userData && userData.phoneNumber) {
  //     localStorage.setItem('phoneNumber', userData?.phoneNumber)
  //   }
  // }, [userData])

  useEffect(() => {
    formik.setFieldValue('firstName', localStorage?.getItem('fullName'))
    formik.setFieldValue('lastName', localStorage?.getItem('lastName'))
    formik.setFieldValue('email', localStorage?.getItem('email'))
    formik.setFieldValue('phone', localStorage?.getItem('phoneNumber'))
  }, [])

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: ScheduleScheme,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        FullName: values.firstName + values.lastName,
        Email: values.email,
        Message: ` ${values.firstName + values.lastName
          } wants to schedule a meeting with us at ${values.schedule} on ${values.phone
          }`
      }

      const jsonData = JSON.stringify(data)
      setIsLoading(true)
      try {
        const response = await axios.post(
          `${baseURL}/api/data/ContactEmail`,
          jsonData,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        if (response?.data?.result === 1) {
          setIsLoading(false)
          resetForm()
          setTimeout(() => {
            navigate('/contact-us/response')
          }, 1500)
        } else {
          toast.error(response?.data?.error)
        }
      } catch (error) {
        toast.error(error.message)
      } finally {
        setTimeout(() => {
          setIsLoading(false)
        }, 4000)
      }
    }
  })

  return (
    <div style={{ background: '#fafafa' }}>

      <HelmetLayout pageName={'Sell'}  canonicalLink={'Sell'}/>

      <ToastContainer />
      <div className=''>
        <img
          className=''
          src={Home_MarketingIcon}
          alt='Home marketing banner'
          style={{ width: '-webkit-fill-available' }}
          loading="lazy"
        />
      </div>

      <div className='container mob_tabcontainer p-0'>
        {/* How it works Section */}
        <div
          className='text-center mb-5 mt-5 coloring '
          style={{ background: '#fafafa' }}
        >
          <h2 className='diff'>
            Why advertise your community on <br />AssignmentSold?
          </h2>
          <p className='diff1'>
            "Connect with hundreds of thousands of new construction homebuyers every month.
            <br />Ensure your community gets the visibility it deserves!"
          </p>
        </div>
      </div>

      {/* Tab Panel Section */}
      <div className='d-block mt-5'>
        <FullWidthTabs />
      </div>

      <div>
        <InformationForSeller />
      </div>

      <div id='pricing' className='mobileContainer text-center plan-details mob_plan mt-5 mb-0'>
        <h2>
          <strong>Select the plan that works for you</strong>
        </h2>
        <h5 className='mob_head'>
          Wether you're looking for money in the bank or more control over the
        </h5>
        <h5 className='mob_head'>
          process- we're committed to helping you sell the way suits you best
        </h5>
      </div>

      <div className='mt-5 px-5 mob_pricing_cardNo_pad'>
        <h1 className='text-center choose_heading fw-bolder font'>
          Choose your package
        </h1>
        <PricingCards redirect={'submit-assignment'} />
      </div>
      <div className='d-flex flex-column aligncenter  lh-0 mb-3 mt-5'>
        <span className='fw-normal waste_time font fs-3'>
          Don't waste time. Get qualified
        </span>
        <span className='fw-normal waste_time font fs-3'>leads now.</span>

        <span className='font mt-3'>1,300 - 3,000+ monthly views* </span>
        <span className='font'>*Average, excludes ad impressions </span>
      </div>

      <div>
        <h2 className='text-center choose_heading mt-5 mb-4'>FAQ</h2>
        <div className='d-flex justify-content-center'>
          <FaqAccordion />
        </div>
      </div>

      <div className='text-center py-5 mobile_finance_option'>
        <img src={DollarIcon} alt='Dollar Icon' loading="lazy"/>
        <h3 className='my-3 financeOption_dollarIconHeading'>Financing options available</h3>
        <button className='SeeMore_sellPropBtn' onClick={() => navigate('/finance-details')}>
          See more about options
        </button>
      </div>


      {/* ------------LocalOffice Section----------- */}
      <div className='container py-5' id='findOffice'>
        <div className='row font'>
          <div className='col-md-6 col-12 d-flex justify-content-center'>
            <div className='LocalOffice_des'>
              <h3 className='WhoWillShowModalCheckBox_heading'>Find a Local Office</h3>
              <form >
                <div className='mt-3'>
                  <p className='WhoWillShowModalCheckBox_font p-0 text-center'>This is your gateway to connecting with the right experts in your area. Our platform is designed to help you find a knowledgeable and dedicated representative near you.</p>
                </div>

                <div className='mt-2'>
                  <TextField
                    sx={{
                      width: '100%',
                      borderRadius: '4px',
                    }}
                    id='outlined-basic '
                    variant='outlined'
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    className='WhoWillShowModalCheckBox_input'
                    placeholder='Enter your closest city, town or postal(Zip) code'
                  />
                </div>


              </form>
            </div>

          </div>
          <div className='col-md-6 col-12 WhoWillShowModalCheckBox_LastNameinput'>
            <img src={LocalOffice} alt='Listing Person Profile' className='w-100 offerNegotationsModalImg' loading="lazy"/>
          </div>
        </div>
      </div>


      {/* ------------contact Section----------- */}

      <div style={{ background: '#f5f5f5' }}>
        <div className='container py-5' id='contactSupport' style={{ background: '#f5f5f5' }}>
          <div className='d-flex marketing_form row justify-content-evenly'>
            <div className='d-flex flex-column mt-3 col-md-6 col-12'>
              <h3 className='fw-bolder font marketing_Asking'>
                Asking doesn't come with a cost.{' '}
              </h3>
              <span className='font asking_query_des'>
                Enter your information so we can call you, or call us at +1(655) 986-5960</span>
              <img src={QueryImg} alt='Query Agent' style={{ minHeight: '483px' }} className='w-100 mt-5 Query_contact_img' loading="lazy"/>
            </div>
            {formik && (
              <div className='col-md-6 col-12 d-flex justify-content-start'>
                <div className='adjustment query_contact_form'>
                  <div className='d-flex marketingform_inf w-100'>
                    <div className='w-50 pe-2 marketing_nameInput'>
                      <p className='font fieldhead m-0'>First Name</p>
                      <TextField
                        size='small'
                        sx={{ borderRadius: '4px', width: '100%' }}
                        className='bg-white'
                        id='outlined-basic'
                        name='firstName'
                        label={<span className='font'>Enter First Name</span>}
                        variant='outlined'
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                      />
                      {isSubmited ? (
                        <p style={{ color: 'red', marginBottom: '0' }}>
                          {formik.errors.firstName}
                        </p>
                      ) : null}
                    </div>

                    <div className='w-50 ps-2 marketing_nameInput'>
                      <p className='font fieldhead m-0'>Last Name</p>
                      <TextField
                        size='small'
                        name='lastName'
                        sx={{ borderRadius: '4px', width: '100%' }}
                        id='outlined-basic'
                        label={<span className='font'>Enter Last Name</span>}
                        variant='outlined'
                        className='bg-white'
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                      />
                      {isSubmited ? (
                        <p style={{ color: 'red', marginBottom: '0' }}>
                          {formik.errors.lastName}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className='w-100'>
                    <p className='font fieldhead m-0'>Email</p>
                    <TextField
                      size='small'
                      type='email'
                      name='email'
                      sx={{ borderRadius: '4px' }}
                      id='outlined-basic '
                      label={<span className='font'>Enter your Email</span>}
                      variant='outlined'
                      className='w-100 bg-white'
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    {isSubmited ? (
                      <p style={{ color: 'red', marginBottom: '0' }}>
                        {formik.errors.email}
                      </p>
                    ) : null}
                  </div>

                  <div className='w-100'>
                    <p className='font fieldhead m-0'>Phone Number</p>
                    <PhoneInput
                      size='small'
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry='CA'
                      value={formik.values.phone}
                      onChange={e => handlePhoneNumberChange(e)}
                      name='phoneNumber'
                      id='phoneNumber'
                      className='Phone_input w-100 bg-white Marketing_poneInput'
                      placeholder='Enter Your Phone Number'
                    />
                    {isSubmited ? (
                      <p style={{ color: 'red', marginBottom: '0' }}>
                        {formik.errors.phone}
                      </p>
                    ) : null}
                  </div>
                  <div className='w-100'>
                    <p className='font fieldhead m-0'>
                      Preferred time to call you
                    </p>
                    <FormControl
                      fullWidth
                      size='small'
                      className='mob_filedsettings bg-white'
                    >
                      <InputLabel id='demo-simple-select-label'>
                        <span className='font fieldhead'>
                          {' '}
                          <CalendarMonthIcon />
                        </span>
                      </InputLabel>
                      <Select
                        value={formik.values.schedule}
                        onChange={formik.handleChange}
                        IconComponent={KeyboardArrowDownIcon}
                        name='schedule'
                      >
                        <MenuItem value={'Weekday_mornings'}>
                          <span className='font '>Weekday mornings</span>
                        </MenuItem>
                        <MenuItem value={'Weekday_afternoons'}>
                          <span className='font'>Weekday afternoons</span>
                        </MenuItem>
                        <MenuItem value={'Weekday_evenings'}>
                          <span className='font '>Weekday evenings</span>
                        </MenuItem>
                        <MenuItem value={'Saturdays'}>
                          <span className='font'>Saturdays</span>
                        </MenuItem>
                        <MenuItem value={'Sundays'}>
                          <span className='font '>Sundays</span>
                        </MenuItem>
                        <MenuItem value={'Weekdays'}>
                          <span className='font'>Weekdays</span>
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {isSubmited ? (
                      <p style={{ color: 'red', marginBottom: '0' }}>
                        {formik.errors.schedule}
                      </p>
                    ) : null}
                  </div>
                  <p className='WhoWillShowModalCheckBox_font mt-3 p-0'>I would like more information on...</p>
                  <div className='d-flex flex-column' style={{ gap: '6px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckboxSelected('SellingProperty')}
                          onChange={event =>
                            handleCheckboxChange(event.target.value)
                          }
                          className='WhoWillShowModalCheckBox'
                        />
                      }
                      label={<span className='WhoWillShowModalCheckBox_font'>Selling a property</span>}
                      value='SellingProperty'
                      className='ps-2'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckboxSelected('BuyingProperty')}
                          onChange={event =>
                            handleCheckboxChange(event.target.value)
                          }
                          className='WhoWillShowModalCheckBox'
                        />
                      }
                      label={<span className='WhoWillShowModalCheckBox_font'>Buying a property</span>}
                      value='BuyingProperty'
                      className='ps-2'
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckboxSelected('GettingMortgage')}
                          onChange={event =>
                            handleCheckboxChange(event.target.value)
                          }
                          className='WhoWillShowModalCheckBox'
                        />
                      }
                      label={<span className='WhoWillShowModalCheckBox_font'>Getting a mortgage</span>}
                      value='GettingMortgage'
                      className='ps-2'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckboxSelected('FranchiseOpportunities')}
                          onChange={event =>
                            handleCheckboxChange(event.target.value)
                          }
                          className='WhoWillShowModalCheckBox'
                        />
                      }
                      label={<span className='WhoWillShowModalCheckBox_font'>Franchise opportunities</span>}
                      value='FranchiseOpportunities'
                      className='ps-2'
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckboxSelected('HomeEstimate')}
                          onChange={event =>
                            handleCheckboxChange(event.target.value)
                          }
                          className='WhoWillShowModalCheckBox'
                        />
                      }
                      label={<span className='WhoWillShowModalCheckBox_font'>Home Estimate</span>}
                      value='HomeEstimate'
                      className='ps-2'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCheckboxSelected('other')}
                          onChange={event =>
                            handleCheckboxChange(event.target.value)
                          }
                          className='WhoWillShowModalCheckBox'
                        />
                      }
                      label={<span className='WhoWillShowModalCheckBox_font'>Other</span>}
                      value='other'
                      className='ps-2'
                    />
                  </div>
                  {isLoading ? (
                    <div className='d-flex justify-content-center align-items-center mt-2'>
                      <Loader size={80} />
                    </div>
                  ) : (
                    <div className='w-100 mt-2'>
                      <button
                        type='submit'
                        style={{ borderRadius: '20px' }}
                        className='Submitbutton mt-3 w-100'
                        onClick={() => {
                          formik.handleSubmit()
                          setIsSubmited(true)
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='mt-5'>
        <Footer />
      </div>
    </div>
  )
}

export default SellProperty
