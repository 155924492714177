import * as Yup from "yup";

export const SubmissionSchema = Yup.object({
  agentName: Yup.string().min(1).required("This field is required"),
  assigntype: Yup.string().min(3).required("This field is required"),
  PropStatus: Yup.string().min(1).required("This field is required"),
  listPrice: Yup.number()
  .min(1, "Value must be at least 1")
  .max(999999999, "Price must be less than 1,000,000,000")
  .required("This field is required"),
  exactSize: Yup.number().min(3).required("This field is required"),
  noOfBeds: Yup.string().min(1).required("This field is required"),
  noOfWash: Yup.number().min(1).required("This field is required"),
  ParkingType: Yup.string().min(1).required("This field is required"),
  parking: Yup.string().test('ParkingType', 'This field is required', function (value) {
    const parkingType = this.parent.ParkingType;
    if (parkingType === 'None') {
      return true; 
    }
    return Yup.string().min(1).required("This field is required").isValidSync(value);
  }),
  Locker: Yup.number()
  .min(0, "Locker value must be greater than or equal to 0")
  .required("This field is required"),
  OccupanycDate: Yup.string().min(1).required("This field is required"),
  // Highlight: Yup.string().min(1).required("This field is required"),
  // MainIntersection: Yup.string().min(1).required("This field is required"),
  // originalPrice: Yup.number()
  //   .min(1, "Value must be at least 1")
  //   .max(999999999, "Price must be less than 1,000,000,000")
  //   .required("This field is required"),
  // originalDeposit: Yup.number().min(1).required("This field is required"),
  // styl: Yup.string().min(3).required("This field is required"),
  // floor: Yup.string().min(3).required("This field is required"),
  // exposure: Yup.string().min(1).required("This field is required"),
  // Garage: Yup.string().min(1).required("This field is required"),
  // assignStatus: Yup.string().min(3),
  // TotalToSeller: Yup.number()
  //   .min(1, "Value must be at least 1")
  //   .max(999999999, "Price must be less than 1,000,000,000")
  //   .required("This field is required"),
  //-----------------------------------------------
  // UpgradeBalance: Yup.string().min(1).required("This field is required"),
  // UpgradeDepositPaid: Yup.number()
  // .min(1, "Value must be at least 1")
  // .max(999999999, "Price must be less than 1,000,000,000")
  // .required("This field is required"),
  // OccupancyDue: Yup.number()
  // .min(1, "Value must be at least 1")
  // .max(999999999, "Price must be less than 1,000,000,000")
  // .required("This field is required"),
  // CooperatingCommission: Yup.number().min(1).required("This field is required"),

  // LotWidth: Yup.string().min(1).required("This field is required"),
  // BrokerageName: Yup.string().min(1).required("This field is required"),
  // Developer:Yup.string().min(1).required("This field is required"),
  // occupancyDate:Yup.string().required("This field is required"),
  // levyCharges:Yup.number().min(1).required("This field is required"),
  // upgradeCharges:Yup.number().min(1).required("This field is required"),
  // inputValue:Yup.string().required("This field is required"),
  // balcony: Yup.number().min(1).required("This field is required"),

  // LevyFee: Yup.number().min(1).required("This field is required"),
  // MaintainenceFee: Yup.number().min(1).required("This field is required"),
  // unitNo:Yup.string().min(1).required("This field is required"),
  // Location:Yup.string().min(0).required("This field is required"),
  // ProjectName: Yup.string().required('This field is required'),

});
