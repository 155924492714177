import React from "react";
import "./HowItWorks.css";
import Footer from "../Footer/Footer";
import HowItWorksLine from '../../images/HowItWorksLine.png'
import ToolsLine from '../../images/ToolsLine.png'
import BrowseListing from '../../images/HowItWorksBrowseListing.png'
import ScheduleViewing from '../../images/HowItWorksScheduleViewing.png'
import MakeOffer from '../../images/HowItWorksMakeOffer.png'
import ClosingDeal from '../../images/HowItWorksClosingDeals.png'
import ListProperty from '../../images/HowItWorksListProperty.png'
import PromoteListing from '../../images/HowItWorksPromoteListing.png'
import ManageInquiries from '../../images/HowItWorksManageInquiries.png'
import CloseSale from '../../images/HowItWorksCloseSale.png'
import BoostListing from '../../images/HowItWorksBoostListing.png'
import TrackPerforming from '../../images/HowItWorksTrackPerforming.png'
import HelmetLayout from "../../Utils/HelmetLayout/Helmet";

export default function HowItWorks() {

  return (
    <>

      <HelmetLayout pageName={'how-it-works'}  canonicalLink={'how-it-works'}/>

      <div className="container HowItWorks_Container">

        <h1>How it Works</h1>

        <div className="HowItWorks_Process">
          <h4>Buying Process</h4>
          <div className='HowItWorksLineFrame'>
            <img src={HowItWorksLine} alt='HowItWorksLineFrame' loading="lazy"/>
          </div>

          <div className="py-5 mobile_itWorks_padding">
            <div className="row d-flex align-items-center justify-content-center py-5 howItWorks_mobPadding">
              <div className='col-md-6 col-12 text-end HowitWorks_MobImg'>
                <div className="sellerAdv_cardIndex itworks_desktop">
                  <p>1</p>
                </div>
                <img src={BrowseListing} alt='Browse Listing' loading="lazy"/>
              </div>
              <div className="HowItWorksSteps_content col-md-6 col-12 text-start">
                <div className="sellerAdv_cardIndex itWorks_index">
                  <p>1</p>
                </div>
                <h3>Browse Listing</h3>
                <p className="HowItWorks_Des">Explore our extensive database of properties for sale. Filter by location, price, size, and other criteria to find your dream home.</p>
              </div>
            </div>

            <div className="row d-flex align-items-center justify-content-center py-5 howItWorks_mobPadding itWorks_column_rver">
              <div className="HowItWorksSteps_content HowitWorks_MobLeftText col-md-6 col-12 text-end d-flex align-items-end flex-column">
                <div className="sellerAdv_cardIndex itWorks_index">
                  <p>2</p>
                </div>
                <h3>Schedule Viewings</h3>
                <p className="HowItWorks_Des HowitWorks_MobImg">Once you've found a property you're interested in, schedule viewings directly through our platform. Visit the property in person or take virtual tours from the comfort of your home.</p>
              </div>
              <div className='col-md-6 col-12 text-start'>
                <div className="sellerAdv_cardIndex itworks_desktop">
                  <p>2</p>
                </div>
                <img src={ScheduleViewing} alt='Schedule Viewing' loading="lazy"/>
              </div>
            </div>

            <div className="row d-flex align-items-center justify-content-center py-5 howItWorks_mobPadding">
              <div className='col-md-6 col-12 text-end HowitWorks_MobImg'>
                <div className="sellerAdv_cardIndex itworks_desktop">
                  <p>3</p>
                </div>
                <img src={MakeOffer} alt='Make an offer' loading="lazy"/>
              </div>
              <div className="HowItWorksSteps_content col-md-6 col-12 text-start">
                <div className="sellerAdv_cardIndex itWorks_index">
                  <p>3</p>
                </div>
                <h3>Make an Offer</h3>
                <p className="HowItWorks_Des">When you've found the perfect property, submit your offer through our platform. Our team will assist you throughout the negotiation process.</p>
              </div>
            </div>

            <div className="row d-flex align-items-center justify-content-center py-5 howItWorks_mobPadding itWorks_column_rver">
              <div className="HowItWorksSteps_content col-md-6 col-12 text-end HowitWorks_MobLeftText d-flex align-items-end flex-column">
                <div className="sellerAdv_cardIndex itWorks_index">
                  <p>4</p>
                </div>
                <h3>Closing the Deal</h3>
                <p className="HowItWorks_Des HowitWorks_MobImg">Once your offer is accepted, our experts will guide you through the closing process, ensuring a smooth and hassle-free transaction.</p>
              </div>
              <div className='col-md-6 col-12 text-start'>
                <div className="sellerAdv_cardIndex itworks_desktop">
                  <p>4</p>
                </div>
                <img src={ClosingDeal} alt='Cosing Deal' loading="lazy"/>
              </div>
            </div>
          </div>
        </div>


        <div className="HowItWorks_Process">
          <h4>Selling Process</h4>
          <div className='HowItWorksLineFrame'>
            <img src={HowItWorksLine} alt='HowItWorksLineFrame' loading="lazy"/>
          </div>

          <div className="py-5 mobile_itWorks_padding">
            <div className="row d-flex align-items-center justify-content-center py-5 howItWorks_mobPadding">
              <div className='col-md-6 col-12 text-end HowitWorks_MobImg'>
                <div className="sellerAdv_cardIndex itworks_desktop">
                  <p>1</p>
                </div>
                <img src={ListProperty} alt='List Property' loading="lazy"/>
              </div>
              <div className="HowItWorksSteps_content col-md-6 col-12 text-start">
                <div className="sellerAdv_cardIndex itWorks_index">
                  <p>1</p>
                </div>
                <h3>List Your Property</h3>
                <p className="HowItWorks_Des">List your property for sale on our platform in just a few easy steps. Provide details, upload photos, and set your desired price.</p>
              </div>
            </div>

            <div className="row d-flex align-items-center justify-content-center py-5 howItWorks_mobPadding itWorks_column_rver">
              <div className="HowItWorksSteps_content HowitWorks_MobLeftText col-md-6 col-12 text-end d-flex align-items-end flex-column">
                <div className="sellerAdv_cardIndex itWorks_index">
                  <p>2</p>
                </div>
                <h3>Promote Your Listing</h3>
                <p className="HowItWorks_Des HowitWorks_MobImg">Use our tools and resources to promote your listing and attract potential buyers. Enhance your listing with premium features for increased visibility.</p>
              </div>
              <div className='col-md-6 col-12 text-start'>
                <div className="sellerAdv_cardIndex itworks_desktop">
                  <p>2</p>
                </div>
                <img src={PromoteListing} alt='Promote Listing' loading="lazy"/>
              </div>
            </div>

            <div className="row d-flex align-items-center justify-content-center py-5 howItWorks_mobPadding">
              <div className='col-md-6 col-12 text-end HowitWorks_MobImg'>
                <div className="sellerAdv_cardIndex itworks_desktop">
                  <p>3</p>
                </div>
                <img src={ManageInquiries} alt='Manage Inquiries' loading="lazy"/>
              </div>
              <div className="HowItWorksSteps_content col-md-6 col-12 text-start">
                <div className="sellerAdv_cardIndex itWorks_index">
                  <p>3</p>
                </div>
                <h3>Manage Inquiries</h3>
                <p className="HowItWorks_Des">Receive inquiries from interested buyers through our platform. Our team can assist you in managing inquiries and scheduling viewings..</p>
              </div>
            </div>

            <div className="row d-flex align-items-center justify-content-center py-5 howItWorks_mobPadding itWorks_column_rver">
              <div className="HowItWorksSteps_content col-md-6 col-12 text-end HowitWorks_MobLeftText d-flex align-items-end flex-column">
                <div className="sellerAdv_cardIndex itWorks_index">
                  <p>4</p>
                </div>
                <h3>Close the Sale</h3>
                <p className="HowItWorks_Des HowitWorks_MobImg">Once you've found a buyer, our experts will guide you through the closing process, handling paperwork and negotiations to ensure a successful sale.</p>
              </div>
              <div className='col-md-6 col-12 text-start'>
                <div className="sellerAdv_cardIndex itworks_desktop">
                  <p>4</p>
                </div>
                <img src={CloseSale} alt='Close Sale' loading="lazy"/>
              </div>
            </div>
          </div>
        </div>

        <div className="HowItWorks_Process worksTools_container">
          <h4>Promotion Tools</h4>
          <div className='HowItWorksLineFrame tools_line'>
            <img src={ToolsLine} alt='ToolsLineFrame' loading="lazy"/>
          </div>

          <div className="py-5 mobile_itWorks_padding">
            <div className="row d-flex align-items-center justify-content-center py-5 howItWorks_mobPadding">
              <div className='col-md-6 col-12 text-end HowitWorks_MobImg'>
                <div className="sellerAdv_cardIndex itworks_desktop">
                  <p>1</p>
                </div>
                <img src={BoostListing} alt='Boost Listing' loading="lazy"/>
              </div>
              <div className="HowItWorksSteps_content col-md-6 col-12 text-start">
                <div className="sellerAdv_cardIndex itWorks_index">
                  <p>1</p>
                </div>
                <h3>Boost Your Listing</h3>
                <p className="HowItWorks_Des">Utilize our promotional tools to increase the visibility of your property listings. Choose from featured listings, social media promotion, and targeted advertising options.</p>
              </div>
            </div>

            <div className="row d-flex align-items-center justify-content-center py-5 howItWorks_mobPadding itWorks_column_rver">
              <div className="HowItWorksSteps_content HowitWorks_MobLeftText col-md-6 col-12 text-end d-flex align-items-end flex-column">
                <div className="sellerAdv_cardIndex itWorks_index">
                  <p>2</p>
                </div>
                <h3>Track Performance</h3>
                <p className="HowItWorks_Des HowitWorks_MobImg">Monitor the performance of your listings and promotional campaigns through our analytics dashboard. Gain insights into views, inquiries, and other key metrics.</p>
              </div>
              <div className='col-md-6 col-12 text-start'>
                <div className="sellerAdv_cardIndex itworks_desktop">
                  <p>2</p>
                </div>
                <img src={TrackPerforming} alt='Track Performing' loading="lazy"/>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="mt-5">
        <Footer />
      </div>
    </>
  );
}
