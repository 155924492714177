


const SellingAdvantageCard = ({data , cardIndex}) => {
    return (
        <div className="SellingAdv_card">
            <div className="sellerAdv_cardIndex">
                <p>{cardIndex+1}</p>
            </div>
            <h3 className="sellerAdv_cardHeading">{data?.heading}</h3>
            <p className="sellerAdv_cardDes">{data?.description}</p>
        </div>
    )
}

export default SellingAdvantageCard;