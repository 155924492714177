import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';

const formatNumber = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const PriceSlider = styled(Slider)({
  color: '#F6941C',
  height: 8,
  padding:0,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#F6941C',
    border: '2px solid white',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
   display:'none',
    
  },
});

export default function SQFTRangeSlider(props) {
  const { value1, value2, onChange } = props;

  const handleSqChange = (event, newValue) => {
    onChange(newValue[0], newValue[1]);
  };

  const formatValue = (value) => {
    if (value > 4500) {
      return "Unlimited";
    }
    return formatNumber(value) + " Sqft";
  };

  const valueLabelFormat = (value) => {
    if (value > 4500) {
      return "Unlimited";
    }
    return formatNumber(value);
  };

  const min = 0;
  const max = value2 > 4500 ? value2 : 5000; // Set max to Unlimited if value2 > 4500
  const incrementValue = 500;

  return (
    <Box sx={{ width: "100%" }}>
      <PriceSlider
        getAriaLabel={() => "Temperature range"}
        value={[value1, value2]}
        onChange={handleSqChange}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        min={min}
        max={max}
        step={incrementValue}
        sx={{ color: "#blue" }}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="caption" id="input-slider" sx={{ color: "#F6941C", fontSize: '0.7em' , fontWeight:'bold' }}>
          {formatValue(value1)}
        </Typography>
        <Typography variant="caption" id="input-slider" sx={{ color: "#F6941C",fontSize: '0.7em' , fontWeight:'bold'  }}>
          {formatValue(value2)}
        </Typography>
      </Box>
    </Box>
  );
}
