import AdvertiseWithUsBanner from '../../images/AdvertiseWithUsBanner.png';
import AdvertiseAgent from '../../images/AdvertiseAgent.png';
import AdvertisePropManger from '../../images/AdvertisePropManager.png';
import AdvertiseLandLord from '../../images/AdvertiseLandLord.png';
import AdvertiseLoanOfficer from '../../images/AdvertiseLoanOfficer.png';
import AdvertiseBuilder from '../../images/AdvertiseBuilder.png';
import AdvertiseLocalAdvertiser from '../../images/AdvertiseLocalAdvertiser.png';
import Footer from '../Footer/Footer';
import HelmetLayout from '../../Utils/HelmetLayout/Helmet';

const AdvertiseWithUS = () => {
    return (
        <>

            <HelmetLayout pageName={'advertise-with-us'} canonicalLink={'advertise-with-us'} />


            <div className='AboutUsMain_container'>
                <div className="MortgaegMain_image AboutUsMain_image">
                    <div className="image_color">
                        <img src={AdvertiseWithUsBanner} alt="AdvertiseWithUsBanner" loading="lazy"/>
                    </div>
                    <div className="AboutUs_HeadingContainer">
                        <h3 className='AboutUsDes_heading mt-2' style={{ maxWidth: '800px' }}>
                            When you move, it isn’t just your address that changes
                        </h3>
                        <h5 className='AboutUs_des mt-4'>
                            ASSIGNMENTSOLD.CA connects with consumers as they're growing their families, increasing their assets, and embracing new routines. Reach qualified consumers who are ready for what's next.
                        </h5>
                    </div>
                </div>

                <div className='d-flex align-items-center mt-5 justify-content-center  tools_grey_container'>
                    <div className='toolTicker_container'>
                        <div className='toolAdtext_container'>
                            <h2 className='tools_ad_heading'>I’m agent or broker</h2>
                            <p className='SellerInf_DesSelling'>Real estate agents and brokers expertly navigate property transactions, offering market insights and negotiation skills to connect buyers and sellers. They ensure smooth dealings, backed by extensive knowledge and professional licensing.</p>
                        </div>
                        <div className='toolAdImage_container'>
                            <img src={AdvertiseAgent} alt='AgentOrBroker' loading="lazy"/>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-center '>
                    <div className='toolTicker_container selling_value_mobile_view'>
                        <div className='toolAdImage_container'>
                            <img src={AdvertisePropManger} alt='PropertyManager' loading="lazy"/>
                        </div>
                        <div className='toolAdtext_container'>
                            <h2 className='tools_ad_heading'>I’m a property manager</h2>
                            <p className='SellerInf_DesSelling'>A property manager oversees the daily operations of real estate properties, handling tasks like tenant relations, lease management, maintenance, and rent collection. They ensure properties are well-maintained and financially profitable, serving as a vital link between property owners and tenants.</p>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center mt-5 justify-content-center  tools_grey_container'>
                    <div className='toolTicker_container'>
                        <div className='toolAdtext_container'>
                            <h2 className='tools_ad_heading'>I’m a landlord</h2>
                            <p className='SellerInf_DesSelling'>A landlord owns and rents out real estate properties, such as apartments, houses, or commercial spaces, to tenants. They are responsible for maintaining the property, collecting rent, and adhering to local landlord-tenant laws, ensuring the property is a safe and livable environment for their tenants.</p>
                        </div>
                        <div className='toolAdImage_container'>
                            <img src={AdvertiseLandLord} alt='Landlord' loading="lazy"/>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-center '>
                    <div className='toolTicker_container selling_value_mobile_view'>
                        <div className='toolAdImage_container'>
                            <img src={AdvertiseLoanOfficer} alt='LenderOrLoanOfficer' loading="lazy"/>
                        </div>
                        <div className='toolAdtext_container'>
                            <h2 className='tools_ad_heading'>I’m a lender or loan officer</h2>
                            <p className='SellerInf_DesSelling'>A lender or loan officer is a professional who evaluates, authorizes, or recommends the approval of loan applications for individuals and businesses. Working for banks, credit unions, or other financial institutions, they assess the creditworthiness of applicants and the viability of loans, guiding clients through the process of securing mortgages, personal loans, or business financing.</p>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center mt-5 justify-content-center  tools_grey_container'>
                    <div className='toolTicker_container'>
                        <div className='toolAdtext_container'>
                            <h2 className='tools_ad_heading'>I’m a builder</h2>
                            <p className='SellerInf_DesSelling'>A builder is a professional or company that specializes in constructing buildings, homes, or other structures from the ground up. They manage all aspects of the construction process, including design, planning, procurement of materials, and the actual building work, ensuring projects meet quality standards, regulations, and client expectations.</p>
                        </div>
                        <div className='toolAdImage_container'>
                            <img src={AdvertiseBuilder} alt='Builder' loading="lazy"/>
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-center '>
                    <div className='toolTicker_container selling_value_mobile_view'>
                        <div className='toolAdImage_container'>
                            <img src={AdvertiseLocalAdvertiser} alt='BrandOrLocalAdvertiser' loading="lazy"/>
                        </div>
                        <div className='toolAdtext_container'>
                            <h2 className='tools_ad_heading'>I’m a brand or local advertiser</h2>
                            <p className='SellerInf_DesSelling'>A brand or local advertiser is a business or organization that promotes its products, services, or identity within a specific geographical area. They utilize various marketing strategies and channels, such as social media, print ads, and community events, to build recognition and loyalty among local consumers, aiming to differentiate themselves in the marketplace and drive sales or engagement within the community.</p>
                        </div>
                    </div>
                </div>


                <div className='container my-5'>
                    <h3 className='aboutStatisticsHeading' style={{ maxWidth: '800px' }}>Millions of homebuyers have already started their search on assignmentsold.ca</h3>
                    <div className='row py-5'>
                        <div className='col-md-3 aboutTotalStatistics'>
                            <h4>78M+</h4>
                            <h6 className='fw-bold'>Unique visitors</h6>
                        </div>
                        <div className='col-md-3 aboutTotalStatistics'>
                            <h4>567%</h4>
                            <h6 className='fw-bold'>Traffic Growth</h6>
                        </div>
                        <div className='col-md-3 aboutTotalStatistics'>
                            <h4>2,328+</h4>
                            <h6 className='fw-bold'>Homebuyers per Minute</h6>
                        </div>
                        <div className='col-md-3 aboutTotalStatistics'>
                            <h4>3000+</h4>
                            <h6 className='fw-bold'>Registered Agents</h6>
                        </div>
                    </div>
                </div>

                <div>
                    <Footer />
                </div>
            </div >
        </>
    )
}

export default AdvertiseWithUS