import React, { useState, useEffect, memo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { KeyFactsIcon } from "../../../SvgIcons/Icons";

const KeyFacts = ({ isMobileView, data }) => {

  const keyMapping = [
    { key: "mainIntersection", label: "Main Intersection" },
    { key: "exteriorSize", label: "Exterior Size" },
    { key: "brokerageName", label: "Brokerage Name" },
    { key: "exposure", label: "Exposure" },
    { key: "style", label: "Style" },
    { key: "floorRange", label: "Floor Range" },
    { key: "parking", label: "Parking" },
    { key: "parkingType", label: "Parking Type" },
    { key: "garage", label: "Garage" },
    { key: "locker", label: "Locker" },
    { key: "colorselectionchosen", label: "Color Selection" },
    { key: "maintainenceFee", label: "Maintenance Fee" },
    { key: "lotWidth", label: "Lot Width" },
    { key: "leaseDuringOccupancy", label: "Lease During Occupancy" },
  ];

    // Filter out keys that don't have data
    const KeysHavingData = keyMapping.filter(({ key }) => data?.[key]);

  const [expanded, setExpanded] = useState(isMobileView ? false : true);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(isMobileView ? false : true);
  }, [isMobileView]);

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        className="Property_details_Web_Accordion DetailsAccordion_withborder PropertyDetails_MobileAccordion py-2"
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              <RemoveIcon className="Property_minusWebGreyIcon" />
            ) : (
              <AddIcon className="Property_plusWebGreyIcon" />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            className={isMobileView ? "d-flex align-items-center gap-2" : ""}
          >
            {isMobileView && <KeyFactsIcon />}
            <h2 className="property_Details_heading">Key Facts</h2>
            {!isMobileView && (
              <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start m-0 mt-1">
                Essential insights to make informed decisions—everything you
                need to know in one place.
              </p>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isMobileView && (
            <p className="PropertyDetails_Buy_reason PropertyDetails_Mbl_f14 text-start mt-1">
              Essential insights to make informed decisions—everything you need
              to know in one place.
            </p>
          )}
          <div className="w-100 keyFacs_Container">
            <div className="w-100 row mx-0 d-flex mt-3 key_factsTableHeading">
              <div className="col-lg-6 col-12">
                <div className="d-flex align-items-start w-100">
                  <h3 className="w-50">Name</h3>
                  <h3 className="w-50">Value</h3>
                </div>
              </div>
              <div className="col-lg-6 col-12 PropDetailsMob_lg_hide">
                <div className="d-flex align-items-start Mobile_col_border">
                  <h3 className="w-50">Name</h3>
                  <h3 className="w-50">Value</h3>
                </div>
              </div>
            </div>
            <div className="w-100 row mx-0 d-flex align-items-center KeyFacts_tableContainer">
              {KeysHavingData.map(({ key, label }, index) => (
                <div
                  key={key}
                  className="col-lg-6 col-12 KeyFacts_border_bottom"
                >
                  <div className="d-flex align-items-start">
                    <h5 className="w-50">{label}</h5>
                    <h6 className="w-50">{data[key]}</h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(KeyFacts);
