import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import ReviewsRating from "../PropertyNotes/RatingReviews";
import axios from "axios";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import baseURL from "../../../../config";
import { useDispatch, useSelector } from "react-redux";
import { updateRatingData } from "../../../../ReduxStore/Slices/RatingDataSlice";

const RatingValueDialog = ({ data, index, isUserlogin, open, handleClose }) => {
  const jwt_token = isUserlogin;
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userProfileDataSlice);
  const notesData = useSelector((state) => state.RatingDataSlice?.ratingData);
  const ratingData = notesData?.filter(
    (item) => item.content !== "___description"
  );

  const [ratingValue, setRatingValue] = useState(
    ratingData[index]?.values ? ratingData[index]?.values : 0
  );
  const [contentValue, setContentValue] = useState(
    ratingData[index]?.content ? ratingData[index]?.content : ""
  );
  const [contentError, setContentError] = useState(false);
  const [ratingError, setRatingError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRating = (e) => {
    setRatingValue(e);
    setRatingError(false);
  };

  const handleUpdateValue = async () => {
    let error = false;
    if (ratingValue === 0) {
      setRatingError(true);
      error = true;
    }
    if (contentValue.length <= 0) {
      setContentError(true);
      error = true;
    }

    if (!error && isUserlogin && userData) {
      setIsLoading(true);
      const headers = {
        Authorization: `Bearer ${jwt_token}`,
      };
      const formData = {
        Id: ratingData[index]?.id ? ratingData[index]?.id : 0,
        ProId: data?.uId,
        UserId: "1",
        Category: "Assignment",
        Content: contentValue,
        Values: ratingValue,
      };
      try {
        const response = await axios.post(
          `${baseURL}/api/AuthData/AddUpdateRateProperty`,
          formData,
          {
            headers,
          }
        );
        if (response?.data?.result === 1) {
          toast.success("Value saved Successfully");
          dispatch(
            updateRatingData({
              id: response?.data?.data,
              content: contentValue,
              values: ratingValue,
            })
          );

          handleClose();
          setIsLoading(false);
        } else {
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="Prop_detail_infoModal Prop_detailsValueModal">
        <div className="w-100 Prop_detailMortgage_calc">
          <h6>Change Value</h6>
          <p style={{ fontSize: "16px" }}>
            Please update the value you wish to rate by simply entering your
            desired value below.
          </p>
          <input
            type="text"
            placeholder="Enter new value"
            value={contentValue}
            onChange={(e) => {
              setContentValue(e.target.value.trimStart());
              setContentError(false);
            }}
          />
          {contentError ? (
            <span style={{ color: "red", fontSize: "12px" }}>
              This Field is Required!
            </span>
          ) : null}
          <div className="d-flex mt-3 align-items-center justify-content-between">
            <p className="m-0" style={{ fontSize: "16px", color: "#282828" }}>
              <strong>Rate</strong>
            </p>
            <ReviewsRating value={ratingValue} ratingChanged={handleRating} />
          </div>
          {ratingError ? (
            <span style={{ color: "red", fontSize: "12px" }}>
              Kindly Select Rating!
            </span>
          ) : null}
          <div className="d-flex align-items-center gap-2 w-100 mt-3">
            <button
              className="propertyDetails_moreInfo PropCancelNotes_btn"
              style={{ width: "calc(50% - 4px)" }}
              onClick={handleClose}
            >
              Cancel
            </button>
            {isLoading ? (
              <div
                className="d-flex w-50 justify-content-center"
                style={{ width: "calc(50% - 4px)" }}
              >
                <RotatingLines width={30} strokeColor="grey" />
              </div>
            ) : (
              <button
                type="submit"
                className="propertyDetails_moreInfo"
                style={{ width: "calc(50% - 4px)" }}
                onClick={handleUpdateValue}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default RatingValueDialog;
