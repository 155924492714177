import React from "react";
import "../../App.css";
import "@fontsource/montserrat";
import DashboardLayout from "../../Dashboard/Dashboard";
import './SubComponents/FavCards.css'
import FavouriteCards from "./SubComponents/FavouriteCards";

export default function FavouritePage() {
  // handle card heart

  return (
    <DashboardLayout>
      <div className='page-content fav_marginTop'>
        <FavouriteCards />
      </div>
    </DashboardLayout>
  );
}
