import React from 'react'

const footer = () => {
    return (

        <div>
            {/* <AppBar position="sticky" style={{ bottom: 0, top: "auto" }}>
                <center>  Copyright 2021-2022 Assignmentsold.ca All Rights Reserved</center>
            </AppBar> */}

        </div>

    )
}

export default footer;