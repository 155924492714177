import { createSlice } from "@reduxjs/toolkit";

const actStatus = createSlice({
    name: "actStatus",
    initialState: 0,
    reducers: {
        showStatus: () => {
        return 1;
      },
        hideStatus: () => {
        return 0;
      },
    },
  });
export const { showStatus, hideStatus } = actStatus.actions;
export default actStatus.reducer;