import React from "react";
import { ArticleNextReadIcon } from "../../../../SvgIcons/Icons";

const ArticleCard = ({isMainCard}) => {
  return (
    <div className="w-100 realtor_articleCard">
      <img
        src="https://config.assignmentsold.ca/Attachments/Assignment/00168611-Screenshot%202024-10-22%20174649.png"
        alt="article-card"
        loading="lazy"
        className="w-100"
        style={{ height: isMainCard ? '400px' : '200px'}}
      />
      <p className="postDate">Umar Khan 20 Jan 2022</p>
      <div className="d-flex justify-content-between align-items-start gap-2">
        <h4 className="title">Looking To Buy? Don’t mistake These Common Homebuying Mistake!</h4>
       <span>
       <ArticleNextReadIcon />
       </span>
      </div>
      <p className="description">
        Embarking on the journey of buying your first home is an exhilarating
        experience, but it comes with its fair share of challenges. To ensure a
        smooth and successful{" "}
      </p>
      <div className="d-flex flex-wrap gap-3">
        <p className="chip">Blog</p>
        <p className="chip category_chip">Article</p>
        <p className="chip read_chip">5 min read</p>
      </div>
    </div>
  );
};

export default ArticleCard;
