import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import baseURL from '../../config'
import axios from 'axios'

const HelmetLayout = ({ pageName, canonicalLink }) => {
  const [mainTitle, setMainTitle] = useState('AssignmentSold.ca')
  const [title, setTitle] = useState('AssignmentSold.ca')
  const [description, setDescription] = useState( 'AssignmentSold.ca is aiming to build a realtor community for assignment transactions, i.e. condos, townhomes, detached  in Toronto, GTA and Ontario. Hundreds of very attractive deals and thousands of professional agents are in this community right now. We are looking to help more agents to achieve more sales.')
  const [keywords, setKeywords] = useState('assignment sold, assignment sale, condo, townhouse')
  let imageUrl =
    'https://assignmentsold.ca/static/media/no-image.67f41c49aba3e3ff671c.png'
  let canonical = canonicalLink
    ? `https://assignmentsold.ca/${canonicalLink}`
    : 'https://assignmentsold.ca'

  const [loading, setLoading] = useState(true)

  const fetchSeoData = async () => {
    try {
      const resData = await axios.get(
        `${baseURL}/api/data/GetSeo/?page=${pageName}`
      )
      if (resData?.data?.result === 1) {
        setLoading(false)
        const mainTitleObject = resData?.data?.data?.metaSeos?.find(
          metaSeo => metaSeo?.seoTag?.typevalue === 'main_Title'
        )
        setMainTitle(
          mainTitleObject ? mainTitleObject?.content : 'AssignmentSold.ca'
        )
        const titleObject = resData?.data?.data?.metaSeos?.find(
          metaSeo => metaSeo.seoTag.typevalue === 'title'
        )
        setTitle(titleObject ? titleObject?.content : 'AssignmentSold.ca')
        const descriptionObject = resData?.data?.data?.metaSeos?.find(
          metaSeo => metaSeo.seoTag.typevalue === 'description'
        )
        setDescription(
          descriptionObject
            ? descriptionObject?.content
            : 'AssignmentSold.ca is aiming to build a realtor community for assignment transactions, i.e. condos, townhomes, detached  in Toronto, GTA and Ontario. Hundreds of very attractive deals and thousands of professional agents are in this community right now. We are looking to help more agents to achieve more sales.'
        )
        const KeywordObject = resData?.data?.data?.metaSeos?.find(
          metaSeo => metaSeo.seoTag.typevalue === 'keywords'
        )
        setKeywords(
          KeywordObject
            ? KeywordObject?.content
            : 'assignment sold, assignment sale, condo, townhouse'
        )
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchSeoData()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {!loading && (
        <Helmet>
          <title>{mainTitle}</title>
          <link rel='canonical' href={canonical} />
          <meta name='title' content={title} />
          <meta name='description' content={description} />
          <meta name='keywords' content={keywords} />

          {/* Og Meta Tags */}
          <meta property='og:url' content={canonical} />
          <meta property='og:title' content={title} />
          <meta property='og:description' content={description} />
          <meta property='og:image' content={imageUrl} />

          {/* Twitter meta Tags */}
          <meta property='twitter:title' content={title} />
          <meta property='twitter:description' content={description} />
          <meta property='twitter:image' content={imageUrl} />
          <meta property='twitter:url' content={canonical} />
        </Helmet>
      )}
    </>
  )
}

export default HelmetLayout
