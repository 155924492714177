import React, { useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';


const formatNumber = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
const RangeSliders = styled(Slider)({
  color: '#F6941C',
  height: 8,
  padding:0,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#F6941C',
    border: '2px solid white',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
   display:'none',
  
  },
});
export default function RangeSlider(props) {
  const { value1, value2, onChange } = props;
  const [incrementValue, setIncrementValue] = useState(25000);

  const handleChange = (event, newValue) => {
    let newIncrementValue = 25000;

    if (newValue[0] > 500000 && newValue[0] < 2000000) {
      newIncrementValue = 50000;
    } else if (newValue[0] >= 2000000) {
      newIncrementValue = 1000000;
    }

    setIncrementValue(newIncrementValue);
    onChange(newValue[0], newValue[1]);
  };

  const formatValue = (value) => {
    if (value > 4500000) {
      return "Unlimited";
    }
    return "$" + formatNumber(value);
  };
  
  const valueLabelFormat = (value) => {
    if (value > 4500000) {
      return "Unlimited";
    }
    return formatNumber(value);
  };
  const min = 0;
  const max = value2 > 4500000 ? value2 : 4600000; // Set max to Unlimited if value2 > 4500

  // const max = 4500000;

  return (
    <Box sx={{ width: "100%" }}>
      <RangeSliders
        className="slidercolor"
        getAriaLabel={() => "Temperature range"}
        value={[value1, value2]}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        min={min}
        max={max}
        step={incrementValue}
      />
      <Box sx={{padding:0, display: "flex", justifyContent: "space-between" }}>
        <Typography variant="caption" id="input-slider" sx={{ color: "#F6941C" ,fontSize: '.7em', fontWeight:'bold'}}>
          {formatValue(value1)}
        </Typography>
        <Typography variant="caption" id="input-slider" sx={{ color: "#F6941C", fontSize: '.7em', fontWeight:'bold' }}>
          {formatValue(value2)}
        </Typography>
      </Box>
    </Box>
  );
}

