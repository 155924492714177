import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from './CustomHook/UseMediaQuery';
import jwtDecode from 'jwt-decode';

import Sidebar from './Sidebar/Sidebar';
import Navbar from './Navbar/Navbar';
import MobileNavbar from './Navbar/MobileNavbar';
import Cookies from 'universal-cookie';

function DashboardLayout({ children }) {
    const navigate = useNavigate();
    const isPhoneScreen = useMediaQuery('(max-width: 786px)');
    const cookies = new Cookies();
    const jwt_token = cookies.get("jwt_authorization");
    // const jwtCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('jwt_authorization='));
    const isLogedIn = localStorage.getItem('anonymous');

    let emailConfirmed = false;
    if (jwt_token) {
        const decoded = jwtDecode(jwt_token);
        const emailConfirmedStr = decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous'];
        emailConfirmed = emailConfirmedStr === "True";
    }
    // Check if jwt authorization cookie exists and the user is logged in
    const isUserLoggedIn = jwt_token && isLogedIn === '1';

    useEffect(() => {
        // If the user is not logged in or the jwt authorization cookie doesn't exist, redirect to the home page
        if (!isUserLoggedIn) {
            navigate('/login', { state: { returnUrl: window.location.pathname } })
        }
        else if (!emailConfirmed) {
            navigate('/email-confirmation?isEmailConfirmed=false');
        }
    }, [isUserLoggedIn, navigate, emailConfirmed]);

    return (
        <div>
            {isUserLoggedIn ? renderDeviceSizeText(isPhoneScreen) : null}
            {isUserLoggedIn ? children : null}
        </div>
    );
}

function renderDeviceSizeText(isPhoneScreen) {
    return isPhoneScreen ? <MobileNavbar /> : (
        <div>
            <Sidebar />
            <Navbar />
        </div>
    );
}

export default DashboardLayout;
