import { useNavigate } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import baseURL from "../../../../config";

const BlogCard = ({ item, loading }) => {

  const navigate = useNavigate()

  const handledetailsNavigate = () => {
    if (!loading) {
      navigate(`/blogs/details/${item?.slug}`)
    }
  }


  return (

    <>
      <div className='ArticleCard w-100'>
        <div className='ArticleCard_Image'>
          {loading ?
            <Skeleton variant="rounded" animation="wave" style={{ width: '100%', height: '100%' }} /> :
            <>
              {item?.blogMedias?.length > 0 ?
                <img src={`${baseURL}/${item?.blogMedias[0]?.fileUrl}`}  alt={item?.title} style={{ cursor: 'pointer' }} onClick={handledetailsNavigate} loading="lazy"/>
                :
                <img
                  src={require("../../../../images/no-image.png")}
                  alt={item?.title}
                  onClick={handledetailsNavigate}
                  loading="lazy"
                />
              }
            </>
          }
        </div>

        {!loading && (
          <div className='categoryAndRead mt-2'>
            <div className='ArticleCard_category'>
              <h6>{item?.blogCategory?.name}</h6>
            </div>
            <div className='ArticleCard_ReadTime'>
              <h6>{item?.readtime} min read</h6>
            </div>
          </div>
        )}

        <div className='ArticleCardTitleDes'>
          {loading ?
            <>
              <Skeleton variant="text" animation="wave" sx={{ fontSize: '1.5rem' }} />
              <Skeleton variant="text" animation="wave" sx={{ fontSize: '0.6rem' }} />
              <Skeleton variant="text" animation="wave" sx={{ fontSize: '0.6rem' }} />
            </> :
            <>
              <h3 onClick={handledetailsNavigate}>{item?.title?.length > 50 ? `${item?.title.slice(0, 50)}...` : item?.title}</h3>
              <p>
                {`${item?.des?.replace(/<[^>]*>/g, '').slice(0, 150)}...`}
              </p>
            </>
          }
        </div>
      </div>


      {/* Mobile View Card */}
      <div className="mbl_articleCard" style={{ cursor: 'pointer' }} onClick={handledetailsNavigate}>
        <div className="mbl_articleCard_content">
          {loading ?
            <div className="py-3">
              <Skeleton variant="text" animation="wave" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" animation="wave" sx={{ fontSize: '0.5rem' }} />
              <Skeleton variant="text" animation="wave" sx={{ fontSize: '0.5rem' }} />
            </div> :
            <>
              <h3>{item?.title?.length > 60 ? `${item?.title.slice(0, 60)}...` : item?.title}</h3>
              <p>
                {`${item?.des?.replace(/<[^>]*>/g, '').slice(0, 60)}...`}
              </p>
              <span className="articlembl_readTime">
                {item?.readtime} min read
              </span>
            </>
          }
        </div>

        <div className='ArticleMblCard_Image'>
          {loading ?
            <Skeleton variant="rounded" animation="wave" style={{ width: '100%', height: '100%' }} /> :
            <>
              {item?.blogMedias?.length > 0 ?
                <img src={`${baseURL}/${item?.blogMedias[0]?.fileUrl}`}  alt={item?.title} loading="lazy"/>
                :
                <img
                  src={require("../../../../images/no-image.png")}
                  alt={item?.title}
                  loading="lazy"
                />
              }
            </>
          }
        </div>
      </div>
    </>
  );
};

export default BlogCard;
