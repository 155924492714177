import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addItem } from "../../ReduxStore/Slices/LoginSlice";
import { addProfileData } from "../../ReduxStore/Slices/ProfileDataSlice";
import { addToken } from "../../ReduxStore/Slices/TokenTimeSlice";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import "./LoginPage.css";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../HomePage/SubComponents/MapandListing/Loader";
import "react-toastify/dist/ReactToastify.css";
import { LoginSchema } from "./LoginSchema";
import axios from "axios";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import baseURL from "../../config";
import Cookies from "universal-cookie";
import jwt from "jwt-decode";
import { useEffect } from "react";
// import GoogleLoginButton from "./googleLogin";
// import { GoogleOAuthProvider } from "@react-oauth/google";
import HelmetLayout from "../../Utils/HelmetLayout/Helmet";

export default function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const jwtCookie = document.cookie
    .split(";")
    .find((cookie) => cookie.trim().startsWith("jwt_authorization="));
  const isLogedIn = localStorage.getItem("anonymous");
  const isUserLoggedIn = jwtCookie && isLogedIn === "1";
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (isUserLoggedIn) {
      navigate("/");
    }
  }, [isUserLoggedIn, navigate]);

  const formInitialValues = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      const data = {
        Email: values.email,
        Password: values.password,
      };

      // const trackVariable = localStorage.getItem('trackVariable') || '0';
      const jsonData = JSON.stringify(data); // Convert data to JSON string

      try {
        const response = await axios.post(
          `${baseURL}/api/Userlogin`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json", // Set the content type to JSON
            },
          }
        );
        if (response.data.result === 1) {
          toast.success("Login Successful");
          dispatch(addProfileData(response.data));
          const jwt_token = response.data.data.refreshToken;
          const exp_time = response.data.data.refreshTokenExpiryTime;
          localStorage.setItem("trackVariable", "0");
          localStorage.setItem("anonymous", 1);
          localStorage.setItem("fullName", response?.data?.data?.firstName);
          localStorage.setItem("lastName", response?.data?.data?.lastName);
          localStorage.setItem("email", response?.data?.data?.email);
          localStorage.setItem(
            "phoneNumber",
            response?.data?.data?.phoneNumber
          );
          localStorage.setItem("UserId", response?.data?.data?.id);
          cookies.remove("LoginObject");
          dispatch(addToken(exp_time));
          // eslint-disable-next-line
          const decoded = jwt(jwt_token);
          const emailConfirmedStr =
            decoded[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/anonymous"
            ];
          const emailConfirmed = emailConfirmedStr === "True";
          const refreshTokenExpiry = new Date(exp_time);
          cookies.set("jwt_authorization", jwt_token, {
            expires: refreshTokenExpiry,
            path: "/",
          });
          dispatch(addItem(1));
          resetForm();
          if (emailConfirmed) {
            // After login success
            const returnUrl = location.state?.returnUrl;
            navigate(returnUrl);
          } else {
            navigate("/email-confirmation?isEmailConfirmed=false");
          }
        } else {
          toast.error(response.data.error);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setTimeout(() => {
          setIsLoading(false);
          setIsSubmitted(false);
        }, 4000);
      }
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const cookieObject = cookies.get("LoginObject");
    if (cookieObject) {
      formik.setValues({
        email: cookieObject.mail || "",
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    // <GoogleOAuthProvider client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <>
      <HelmetLayout pageName={"login"} canonicalLink={"login"} />
      <div className="container-fluid">
        <div className="row py-3 mx-0">
          <div className="col-lg-6  col-12 mt-5 textalignment">
            <div className="adjustment Login_form">
              <div className="imagecentered">
                <NavLink to="/">
                  <img
                    className="imgclass1"
                    src={require("../../images/New__LOGO.png")}
                    alt="AssignmentSold Logo"
                    loading="lazy"
                  />
                </NavLink>
              </div>
              <h2 className="font contactheading pb-0">
                Login{" "}
                <span className="span-for-hide">
                  {" "}
                  to Assignment<span className="textgreen">Sold</span>{" "}
                </span>
              </h2>

              <p className="mb-4 login_supportingLine">Welcome back! Please enter your details.</p>

              <TextField
                size="small"
                sx={{
                  width: "100%",
                  borderRadius: "4px",
                }}
                value={formik.values.email}
                onChange={formik.handleChange}
                name="email"
                id="outlined-basic"
                label={<span className="font">Enter your email</span>}
                variant="outlined"
              />
              {isSubmitted ? (
                <span style={{ color: "red" }}>{formik.errors.email}</span>
              ) : null}

              <FormControl
                size="small"
                className="mt-4"
                sx={{
                  width: "100%",
                  borderRadius: "4px",
                }}
                variant="outlined"
              >
                <InputLabel size="small" htmlFor="outlined-adornment-password">
                  <span className="font">Enter your password</span>
                </InputLabel>
                <OutlinedInput
                  size="small"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      {showPassword ? (
                        <VisibilityOutlined
                          className="pointer-on-hover"
                          aria-label="Hide password"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        />
                      ) : (
                        <VisibilityOffOutlined
                          className="pointer-on-hover"
                          aria-label="Show password"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        />
                      )}
                    </InputAdornment>
                  }
                  label={<span className="font">Enter your password</span>}
                  name="password"
                />
                {isSubmitted ? (
                  <span style={{ color: "red" }}>{formik.errors.password}</span>
                ) : null}
              </FormControl>
              <div className="mt-3 Remme">
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label={
                        <span className="font remember_me">Remember me</span>
                      }
                    />
                  </FormGroup>
                </div>
                <div className="font fpass">
                  <NavLink to="/reset-password">Forgot Password</NavLink>
                </div>
              </div>

              {isLoading ? (
                <div className="aligncenter justifycenter">
                  <Loader />
                </div>
              ) : (
                <button
                  type="submit"
                  onClick={() => {
                    formik.handleSubmit();
                    setIsSubmitted(true);
                  }}
                  className="CreateButton SignUp_submitBtn w-100 mt-2"
                >
                  Sign in
                </button>
              )}

              {/* <div className="row">
                <div className="font  col-12 ">
                  <p className="m-0 justify">OR</p>
                </div>
              </div> */}

              {/* <GoogleLoginButton setIsLoading={setIsLoading} /> */}

              <p className=" font stflot1 m-0 justify-content-center pt-4">
                <span className="dhac">Don't have an Account?</span>
                <NavLink
                  to="/signup"
                  style={{ color: "#02549e" }}
                  className="font stflot2 p-0 ps-1"
                >
                  <strong> Create an Account</strong>
                </NavLink>
              </p>
            </div>
          </div>
          <div className=" LoginImage col-lg-6 col-12 ">
            <img
              className="Contactimage"
              src={require("../../images/home1.png")}
              alt="Login Cover"
              loading="lazy"
            />
          </div>
        </div>
        <ToastContainer />
      </div>
      {/* </GoogleOAuthProvider> */}
    </>
  );
}
