import Cookies from 'universal-cookie';
import WithNavbarLayout from './WithNavbarLayout';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ Component }) => {
    const cookies = new Cookies();
    const jwt_token = cookies.get('jwt_authorization');
    const isAuthenticated = !!jwt_token;
    return isAuthenticated ? (
        <WithNavbarLayout>
            <Component />
        </WithNavbarLayout>
    ) : (
        <Navigate to="/login" state={{ returnUrl: window.location.pathname }} replace />
    );
};

export default PrivateRoute;
